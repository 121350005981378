import React, {useEffect, useState} from 'react'
import {signinStyles} from '../../../assets/jss/signin'
import Hide from '../CustomExpansion/Hide'

export default ({
  label,
  cmpValue,
  setValid,
  isValid,
  ...rest
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (value === cmpValue)
      setValid(true);
    else
      setValid(false);
  }, [value, cmpValue, setValid]);

  const classes = signinStyles();

  return (
    <Hide open={!isValid}>
      <div className={classes.inputBox} {...rest}>
        <input
          autoComplete="off"
          type="text"
          name={label.split(' ').join('-')}
          required=""
          className={cmpValue === value ? classes.validCmpInput : classes.invalidCmpInput}
          value={value}
          onChange={event=>setValue(event.target.value)}
        />
        <label className={cmpValue === value ? classes.validCmpLabel : classes.invalidCmpLabel}>{label}</label>
      </div>
    </Hide>
  );
};