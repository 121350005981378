import React, {useContext, useEffect, useState} from "react";
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Tooltip from '../../../Tooltip';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import EditLicense from './EditLicense'
import type {License} from '../../../../types'
import {useCreateDriverLicense} from '../../../../api/auth/drivers'
import state from "../../../../state";
import useEdit from "../../../../hooks/useEdit";

const create_default_license = ():License => ({
  backside_link: '',
  frontside_link: '',
  issued: null,
  expiration: null,
  license_number: '',
  manual: false,
  limitations: [],
  permits: {},
  practical_experience: 0,
  seniority: 0,
  type: '',
  vehicle_type: '',
  weight: null,
  temporary: false,
});

export default ({template, last_license, licenses, set_licenses}) => {
  const create_license = useCreateDriverLicense();
  const {set_backdrop} = useContext(state.backdrop);

  const {edit, edit_open, set_edit_open} = useEdit(last_license === null, 500);

  const add_license = async (_, license:License) => {
    if (!license.type)
      return;

    const update = {...licenses};
    // update[license.type] = license;
    // set_licenses(update);

    set_backdrop(true);
    const res = await create_license({
      driver_id: template._id,
      license: license,
    });
    if (!!res) {
      update[license.type] = res;
      set_licenses(update);
      set_edit_open(false);
    }
    set_backdrop(false);
  };

  useEffect(() => {
    if (last_license === null) {
      set_edit_open(true);
    }
  }, [last_license]);

  return edit
    ?
    <EditLicense
      open={edit_open}
      license={last_license  !== null ? last_license : create_default_license()}
      onCancel={()=>set_edit_open(false)}
      onSave={add_license}
    />
    :
    <Grow in={true} timeout={700}>
      <Grid container alignItems='center' onClick={()=>set_edit_open(true)}>
        <Tooltip title={'Add new locations to where you want to operate'}>
          <Fab size="small" color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        </Tooltip>
        <Button color="primary">
          Add license
        </Button>
      </Grid>
    </Grow>;
};