import type {PostType, Snackbar, CategoryTab, ScrollType, TutorialMode} from '../types'
import {category_tabs, search_categories} from '../variables'
import {do_nothing_function} from './do_nothing_function'

export const default_snack = ():Snackbar => ({
  anchorOrigin: {vertical: 'top', horizontal: 'right'},
  autoHideDuration: 0,
  open: false,
  message: "",
  variant: "info",
});

export const default_sign_in = () => ({
  open: false,
  msg: undefined,
  response: undefined
});

export const default_scroll_options = ():ScrollType => ({
  activeClass: "active",
  to: "",
  spy: true,
  smooth: true,
  hashSpy: true,
  offset: -70,
  duration: 800,
  delay: 200,
  isDynamic: true,
  ignoreCancelEvents: false,
});

export const default_category_tab = ():CategoryTab => category_tabs[0];

export const default_search_dropdown = () => ({
  caret: true,
  dropup: false,
  hoverColor: "info",
  width: 352,
  maxHeight: 400,
  buttonText: undefined,
  buttonIcon: undefined,
  dropdownList: [],
  buttonProps: undefined,
  dropdownHeader: undefined,
  dropPlacement: undefined,
  rtlActive: undefined,
  noLiPadding: undefined,
  innerDropDown: undefined,
  navDropdown: undefined,
  onClick: undefined,
  open: false,
  setOpen: do_nothing_function,
  anchorEl: null,
  loading: true,
  root_style: {},
});
