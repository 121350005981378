import React, {useContext} from 'react'
import state from '../../state'
import Snackbar from '../utils/Snackbar'

export default () => {
  const {snackbar, set_snackbar} = useContext(state.snackbar);

  return (
    <Snackbar
      {...snackbar}
      setOpen={open => {
        const state = {...snackbar};
        state.open = open;
        set_snackbar(state)
      }}
    />
  )
}
