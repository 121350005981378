import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';


export default ({children, style={}, title, ...props}) => {
  const Tip = withStyles({
    tooltip: {
      fontSize: 15,
      textAlign: 'center',
      ...style,
    },
  })(Tooltip);
  return title ? <Tip children={children} title={title} {...props}/> : children
}