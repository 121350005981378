const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const email_valid = 'zxcvbnmasdfghjklqwertyuiopZXCVBNMASDFGHJKLQWERTYUIOP1234567890!#$%&*+-/=?^_{|}~.@';
const numberRex = new RegExp("^[0-9]+$");
const usernameRex = "zxcvbnmasdfghjklqwertyuiopZXCVBNMASDFGHJKLQWERTYUIOP._1234567890";
const passwordRex = "zxcvbnmasdfghjklqwertyuiopZXCVBNMASDFGHJKLQWERTYUIOP1234567890 ,/<>?[]{}\\~!@#$%^&*()-_=+";
const letters = "zxcvbnmasdfghjklqwertyuiopZXCVBNMASDFGHJKLQWERTYUIOP ";
const companyRex = "zxcvbnmasdfghjklqwertyuiopZXCVBNMASDFGHJKLQWERTYUIOP .-_1234567890";
const tokenRex = "zxcvbnm./asdfghjkl\\qwertyuiop[]ZXCVBNM<>?ASDFGHJKL|QWERTYUIOP{}1234567890-=~!@#$%^&*()_+";
const textRex = "zxcvbnmasdfghjklqwertyuiopZXCVBNMASDFGHJKLQWERTYUIOP1234567890 .,/|<>?[]{}\\~!@#$%^&*()-_=+\"'`";


export function verifyText(value:string) {
  for (let i = 0; i < value.length; i++)
    if (textRex.indexOf(value.charAt(i)) === -1)
      return false;
  return true
}

export function verifyEmail(value:string){
  if (emailRex.test(value)) {
    for (let i = 0; i < value.length; i++) {
      if (email_valid.indexOf(value.charAt(i)) === -1) {
        return false
      }
    } return true
  } return false
}

export function verifyPassword(value:string) {
  for (let i = 0; i < value.length; i++)
    if (passwordRex.indexOf(value.charAt(i)) === -1)
      return false;
  return true
}

export function verifyToken(value:string) {
  for (let i = 0; i < value.length; i++)
    if (tokenRex.indexOf(value.charAt(i)) === -1)
      return false;
  return true
}

export function verifyUsername(value:string) {
  for (let i = 0; i < value.length; i++)
    if (usernameRex.indexOf(value.charAt(i)) === -1)
      return false;
  return true
}

export function verifyCompany(value:string) {
  for (let i = 0; i < value.length; i++)
    if (companyRex.indexOf(value.charAt(i)) === -1)
      return false;
  return true
}

export function verifyLetters(value:string) {
  for (let i = 0; i < value.length; i++)
    if (letters.indexOf(value.charAt(i)) === -1)
      return false;
  return true
}

export function verifySpace(value:string){
  return !(value[0] === " " || value[value.length-1] === " ");
}

export function verifyLength(value, min, max){
  return min <= value.length && value.length <= max;
}

export function compare(string1, string2){
  return string1 === string2;
}

export function verifyNumber(value){
  return numberRex.test(value);
}

export function validChoices(value, choices){
  return choices.indexOf(value) !== -1;
}

export function contains(value, contained){
  for (let i = 0; i < value.length; i++)
    if (contained.indexOf(value.charAt(i)) === -1)
      return false;
  return true
}

export function verifyUrl(value){
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
}
