import {base_headers, auth_headers, refresh_headers} from "./headers";

export const base_config = {
  headers: base_headers,
};

export const auth_config = {
  headers: auth_headers,
};

export const refresh_config = {
  headers: refresh_headers,
};