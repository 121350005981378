import React, {useState, useEffect} from 'react';
import SearchResult from '../../components/search_results/SearchResult';
import {get_search_results} from '../../api/get_search_results';
import Navbar from '../../components/navbar/Navbar';
import NavPlaceholder from '../../components/navbar/NavPlaceholder';

const mock_search_parameters = {
  sla: null,
  two_way_trip: false,
  collaborative_transport: false,
  locations: ["", ""],
  dates: [{from:"", to:""}],
  repeat_transport_cycle: {
    repeat: 0, // -1 for always
    timedelta: null,
  },
  transport_tasks: [],
  cargo_types: [],
  size_weight: null, //{amount:4000, unit:'kg'}, // server receive kg || cube
  packing_type: null, // string
  procedures: [],
  vehicles: []
};

const styles = {
  background: props => {
    const style = {
    minHeight: '90vh',
    height:'100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '10px',
    };
    if (props.color === 'red')
      style.backgroundImage = 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)';
    else if (props.color === 'blue')
      style.backgroundImage = 'linear-gradient(45deg, #7B97DE 30%, rgba(116, 169, 192, 0.39) 90%)';
    else
      style.backgroundColor = "grey";
    return style;
  }
};

export default ({match}) => {
  useEffect(()=>{fetchResults()},[]);
  const [items, setItems] = useState([]);

  const search_parameters = mock_search_parameters;
  const fetchResults = async () => {
    const response = await get_search_results(search_parameters);
    if (response.status_code !== 200) {

    };
    // const items = await response.data;
    const items = [0,0,0,0,0,0];
    setItems(items);
  };
  return [
    <NavPlaceholder key={0}/>,
    <div style={styles.background({color:'blue'})} key={1}>
      {items.map((item, index) => <div key={index} style={{margin: '10px'}}><SearchResult key={index} data={item}/></div>)}
    </div>,
    <Navbar  key={2}/>,
  ]
};