import request from "axios";
import {url} from "../utils/urls";
import {base_config} from "../utils/configs";

export const get_user_drivers = async (
  owner_id: string,
  skip: number,
  limit: number
) => {
  try {
    const res = await request.post(
      `${url}/user/${owner_id}/drivers/get`,
      {skip, limit}, base_config
    );
    return {
      drivers: res.data.results,
      skip: res.data.next,
    };
  }
  catch (e) {
    console.log(e);
    return false
  }
};

export const get_user_drivers_page = async (
  owner_id: string,
  page_number: number,
  items_per_page: number,
) => {
  const res = await get_user_drivers(
    owner_id,
    (page_number-1)*items_per_page,
    items_per_page,
  );
  return res === false ? [] : res.drivers
};

export const count_user_drivers = async (owner_id: string) => {
  try {
    const res = await request.get(`${url}/user/${owner_id}/drivers/count`, base_config);
    return res.data.count;
  }
  catch (e) {
    console.log(e);
    return false
  }
};