import {useEffect, useState} from "react";
import sleep from "../utils/sleep";

export default (initialState:boolean, delay:number) => {
  const [edit, set_edit] = useState(initialState);
  const [edit_open, set_edit_open] = useState(initialState);

  useEffect(()=>{(async ()=>{
    if (edit_open === true)
      set_edit(true);
    else {
      await sleep(delay);
      set_edit(false);
    }
  })()}, [edit_open]);

  return {
    edit, set_edit,
    edit_open, set_edit_open,
  }
};