const remove_3power_of_10 = (n:number) => {
  let reduced = 0;
  let left_over = 0;

  if (n >= 1000)
    while (n >= 1000) {
      let s = n.toString();
      n = parseInt(s.substring(0, s.length-3));
      left_over = parseInt(s.substring(1, 2));
      reduced++;
      if (reduced > 3)
        break
    }
  else if (n <= -1000)
    while (n <= -1000) {
      let s = n.toString();
      n = parseInt(s.substring(0, s.length-3));
      left_over = parseInt(s.substring(1, 2));
      reduced++;
      if (reduced > 3)
        break
    }
  return {n, reduced, left_over:left_over>0?'.'+left_over:''}
};

export function bigNumber(n:number) {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default (n:number) => {
  const r = remove_3power_of_10(Math.floor(n));
  if (r.reduced===0) r.reduced = ''; else
  if (r.reduced===1) r.reduced = 'K'; else
  if (r.reduced===2) r.reduced = 'M'; else
  if (r.reduced===3) r.reduced = 'B';
  else r.reduced = 'T';

  return `${r.n}${r.left_over}${r.reduced}`
}