import useSize from '../../hooks/useSize'
import useIsLogged from '../../hooks/useIsLogged'
import useNotificationsInterval from '../../hooks/useNotificationsInterval'
import useMessagesInterval from '../../hooks/useMessagesInterval'
import useScrollTransition from '../../hooks/useScrollTransition'
import useRefresh from '../../hooks/useRefresh'

export default () => {
  useSize();
  useIsLogged();
  useNotificationsInterval();
  useMessagesInterval();
  useScrollTransition();
  useRefresh();
  return null
}
