import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const addStopPointsStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(0.5),
    },
}));

function AddStopPoints() {
    const classes = addStopPointsStyles();
    return <Grid container >
        <Grid item xs={12}>
            <Button className={classes.margin}>Add Stop Points</Button>
        </Grid>
        <Grid item xs={12}>
            <Fab size="medium" color="primary" aria-label="add" className={classes.margin}>
                <AddIcon />
            </Fab>
        </Grid>
    </Grid>;
}

function TwoWayTrip() {
    const [value, setValue] = React.useState('female');

    const handleChange = event => {
        setValue(event.target.value);
    };

    return (
        <FormControl component="fieldset">
            <FormGroup aria-label="position" name="position" value={value} onChange={handleChange} row>
                <FormControlLabel
                    value="2 way trip"
                    control={<Checkbox color="primary" />}
                    label="2 way trip"
                    labelPlacement="top"
                />
            </FormGroup>
        </FormControl>
    );
}

function CollaborativeTransport() {
    const [value, setValue] = React.useState('female');

    const handleChange = event => {
        setValue(event.target.value);
    };

    return (
        <FormControl component="fieldset">
            <FormGroup aria-label="position" name="position" value={value} onChange={handleChange} row>
                <FormControlLabel
                    value="get discounted collaborative transport"
                    control={<Checkbox color="secondary" />}
                    label="get discounted collaborative transport"
                    labelPlacement="top"
                />
            </FormGroup>
        </FormControl>
    );
}

const formattedInputsStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: "80px"
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix=""
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

function FormattedInputs() {
    const classes = formattedInputsStyles();
    const [values, setValues] = React.useState({
        numberformat: '1',
    });

    const handleChange = name => event => {
        setValues({
            ...values,
            [name]: event.target.value,
        });
    };

    return (
        <div className={classes.container}>
            <TextField
                className={classes.formControl}
                value={values.numberformat}
                onChange={handleChange('numberformat')}
                id="formatted-numberformat-input"
                InputProps={{
                    inputComponent: NumberFormatCustom,
                }}
            />
        </div>
    );
}

const textStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const radioStyle = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
});

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = radioStyle();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

function CustomizedRadios() {
    const [value, setValue] = React.useState('none');

    const handleChange = event => {
        setValue(event.target.value);
    };
    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">SLA Hours</FormLabel>
            <RadioGroup value={value} aria-label="gender" name="customized-radios" onChange={handleChange}>
                <FormControlLabel value="none" control={<StyledRadio />} label="none" />
                <FormControlLabel value="2" control={<StyledRadio />} label="2" />
                <FormControlLabel value="4" control={<StyledRadio />} label="4" />
                <FormControlLabel value="8" control={<StyledRadio />} label="8" />
                <FormControlLabel
                    value="other"
                    control={<StyledRadio />}
                    label="other"
                />
                {value === "other" ? <FormattedInputs/>:null}
            </RadioGroup>
        </FormControl>
    );
}

export default function TextFields() {
    const classes = textStyles();
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [values, setValues] = React.useState({
        From: '',
        To: '',
    });

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    return (
        <form className={classes.container} noValidate autoComplete="off">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container >
                    <Grid item sm={12} md={2}>
                        <Grid container >
                            <Grid item xs={12}>
                                <div style={{marginBottom:'60px'}}>
                                    <CustomizedRadios/>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <TwoWayTrip/>
                            </Grid>
                            <Grid item xs={12}>
                                <CollaborativeTransport/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={10}>
                        <Grid container >
                            <Grid item xs={12} sm={6} lg={4}>
                                <TextField
                                    id="standard-name"
                                    label="from location"
                                    // className={classes.textField}
                                    value={values.name}
                                    onChange={handleChange('From')}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <AddStopPoints/>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                                <TextField
                                    id="standard-name"
                                    label="to location"
                                    // className={classes.textField}
                                    value={values.name}
                                    onChange={handleChange('To')}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="from date"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="to date"
                                    format="MM/dd/yyyy"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="from time"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="until time"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </form>
    );
}