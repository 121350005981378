import React, {useContext} from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import state from '../../../../state'
import {user_logout} from "../../../../api/public/logout";
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles({
  paper: {
    marginTop: 10,
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles({
  root: {
    fontSize: 14,
    width: 160,
  },
})(MenuItem);

export default ({
  mobileMoreAnchorEl,
  isMobileMenuOpen,
  handleMenuClose,
  handleProfileMenuOpen,
}) => {
  const links = useContext(state.links);
  const { set_user } = useContext(state.user);
  // const { notifications } = useContext(state.notifications);
  // const { messages } = useContext(state.messages);

  const onPageClick = () => {
    handleMenuClose();
    links.go_to_my_profile();
  };

  const onAccountClick = () => {
    handleMenuClose();
    links.go_to_my_account();
  };

  const onAssetsClick = () => {
    handleMenuClose();
    links.go_to_my_assets();
  };

  const onLogoutClick = () => {
    handleMenuClose();
    user_logout(set_user)
  };

  const onAboutClick = () => {
    handleMenuClose();
    links.go_to_about();
  };

  const onTermsClick = () => {
    handleMenuClose();
    links.go_to_terms();
  };

  const onPrivacyClick = () => {
    handleMenuClose();
    links.go_to_privacy();
  };

  const onContactClick = () => {
    handleMenuClose();
    links.go_to_contact();
  };

  return (
    <StyledMenu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMenuClose}
    >
      <StyledMenuItem onClick={onPageClick}>
        My Profile
      </StyledMenuItem>
      <StyledMenuItem onClick={onAccountClick}>
        My Account
      </StyledMenuItem>
      <StyledMenuItem onClick={onAssetsClick}>
        My Assets
      </StyledMenuItem>
      <StyledMenuItem onClick={onLogoutClick}>
        Logout
      </StyledMenuItem>

      <Divider style={{margin:10}}/>

      <StyledMenuItem onClick={onAboutClick}>
        About
      </StyledMenuItem>
      <StyledMenuItem onClick={onTermsClick}>
        Terms of use
      </StyledMenuItem>
      <StyledMenuItem onClick={onPrivacyClick}>
        Privacy Policy
      </StyledMenuItem>
      <StyledMenuItem onClick={onContactClick}>
        Contact
      </StyledMenuItem>
    </StyledMenu>
  );
}