import React, {useContext} from 'react'
import Grid from '@material-ui/core/Grid'
import city_driver from '../../assets/images/home_page/city_driver.svg'
import Container_ship from '../../assets/images/home_page/Container_ship.svg'
import fast_car from '../../assets/images/home_page/fast_car.svg'
import off_road from '../../assets/images/home_page/off_road.svg'
import order_ride from '../../assets/images/home_page/order_ride.svg'
import FrontalTruck from '../../assets/images/home_page/frontal_truck'
import Button from '@material-ui/core/Button';
import state from '../../state'
import Slide from '@material-ui/core/Slide'
import Fade from '@material-ui/core/Fade'
import Grow from '@material-ui/core/Grow'
import Zoom from '@material-ui/core/Zoom'
import VisibilityTrigger from '../../components/VisibilityTrigger'
import {mobile_size, small_mobile_size} from '../../variables'
import {headerStyles} from '../../assets/jss/textStyle'
import type {User} from "../../types";


export default ({title, text}) => {
  const classes = headerStyles();
  const links  = useContext(state.links);
  const entered_page: boolean = useContext(state.entered_page).entered_page;
  const width: number = useContext(state.size).size.width;
  const user: User = useContext(state.user).user;
  const {signin, set_signin} = useContext(state.signin);

  const collapse = 917;
  const sh1 = {fontSize:44};
  const s1 = {maxWidth:500, padding:10};
  const s2 = {width:360, padding:10, height:350};
  const section_style = {margin:'auto', width:'100%', maxWidth:1100, marginBottom: 120};

  const openSignin = () => set_signin({...signin, open: true});

  if (width >= collapse) {
    s2.marginTop = 20;
  } else {
    section_style.marginBottom = 50;
    s1.marginTop = 20;
    s2.marginTop = 20;

    if (width <= small_mobile_size) {
      s1.marginTop = 0;
      sh1.fontSize = 28;
      sh1.marginTop = 0;
    } else if (width <= mobile_size) {
      sh1.fontSize = 32;
    } else {
      sh1.fontSize = 36;
    }
  }

  return (
    <VisibilityTrigger>{({triggered}) =>
      <Grid container justify="space-evenly" alignItems="center" style={section_style}>
        <Grid container direction="column" justify="center" alignItems="center" style={s1}>
          <div>
            <Slide direction="right" in={triggered && entered_page} mountOnEnter unmountOnExit timeout={800}>
              <div>
                <Fade in={triggered && entered_page} timeout={1500}>
                  <h1 className={classes.Header} style={sh1}>
                    {title}
                  </h1>
                </Fade>
              </div>
            </Slide>
            <Slide direction="up" in={triggered && entered_page} mountOnEnter unmountOnExit timeout={1300}>
              <div>
                <Fade in={triggered && entered_page} timeout={2300}>
                  <p className={classes.Paragraph}>
                    {text}
                  </p>
                </Fade>
              </div>
            </Slide>
            {user === null
              ?
              <Grow in={triggered && entered_page} timeout={2600}>
                {width < collapse
                  ?
                  <Grid container justify='center' style={{marginTop: 30, marginBottom: 30}}>
                    <Button variant="outlined" color="primary" onClick={openSignin}>
                      Join Now
                    </Button>
                  </Grid>
                  :
                  <Button variant="outlined" color="primary" onClick={openSignin}>
                    Join Now
                  </Button>
                }
              </Grow>
              : user.drivers + user.vehicles === 0 &&
              <Grow in={triggered && entered_page} timeout={2600}>
                {width < collapse
                  ?
                  <Grid container justify='center' style={{marginTop: 30, marginBottom: 30}}>
                    <Button variant="outlined" color="primary" onClick={links.go_to_my_assets}>
                      Become a GOtracker
                    </Button>
                  </Grid>
                  :
                  <Button variant="outlined" color="primary" onClick={links.go_to_my_assets}>
                    Become a GOtracker
                  </Button>
                }
              </Grow>
            }
          </div>
        </Grid>

        <div style={s2}>
          <div style={{position:'relative'}}>

            <Slide direction="left" in={triggered && entered_page} mountOnEnter unmountOnExit timeout={6000}>
              <div>
                <Fade in={triggered && entered_page} timeout={6000}>
                  <img src={Container_ship} alt="" style={{position:'absolute', top: 50, left: 0, width:200}}/>
                </Fade>
              </div>
            </Slide>

            <Grow in={triggered && entered_page} timeout={3000}>
              <img src={city_driver} alt="" style={{position:'absolute', top: 180, left: 30, width:60}}/>
            </Grow>

            <Slide direction="left" in={triggered && entered_page} mountOnEnter unmountOnExit timeout={600}>
              <img src={fast_car} alt="" style={{position:'absolute', top: 190, left: 170, width:50}}/>
            </Slide>

            <Slide direction="left" in={triggered && entered_page} mountOnEnter unmountOnExit timeout={1500}>
              <img src={off_road} alt="" style={{position:'absolute', top: 170, left: 100, width:60}}/>
            </Slide>

            <Zoom in={triggered && entered_page} timeout={2500}>
              <img src={order_ride} alt="" style={{position:'absolute', top: 200, left: 0, width:200}}/>
            </Zoom>
            <Zoom in={triggered && entered_page} timeout={500}>
              <FrontalTruck fill='#2b3135' style={{position:'absolute', top: 240, left: 212, width:25, height: 25}}/>
            </Zoom>
            <Zoom in={triggered && entered_page} timeout={1500}>
              <FrontalTruck fill='#2b3135' style={{position:'absolute', top: 232, left: 220, width:40, height: 40}}/>
            </Zoom>
            <Zoom in={triggered && entered_page} timeout={2500}>
              <FrontalTruck fill='#2b3135' style={{position:'absolute', top: 220, left: 230, width:80, height: 80}}/>
            </Zoom>
          </div>
        </div>
      </Grid>
    }</VisibilityTrigger>
  )
}

