import React, {useContext} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import state from '../../../../state'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import VisibilityTrigger from "../../../VisibilityTrigger";
import Fade from "@material-ui/core/Fade/Fade";

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default () => {
  const classes = useStyles();
  const {set_sidebar} = useContext(state.sidebar);

  return (
    <VisibilityTrigger delay={2000}>
      {({triggered}) =>
        <Fade in={triggered} timeout={2000}>
          <div>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={()=>{set_sidebar(true)}}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Fade>
      }
    </VisibilityTrigger>
  );
}