import React, {useEffect, useState} from "react";
import Slide from '@material-ui/core/Slide';
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails} from '../licences/Expansion'
import DocumentPhoto from '../../../custom/CustomUpload/DocumentPhoto'
import {validSizeMB, validPhotoExtension} from '../../../../validations/file_validations'
import Grid from "@material-ui/core/Grid";
import Tooltip from "../../../Tooltip";
import license_icon from "../../../../assets/images/icons/license_icon.svg";
import TextField from "@material-ui/core/TextField";
import type {FileChangeProps} from '../../../../types'

export default ({open, extras, set_extras}) => {
  const [is_open, set_is_open] = useState(false);
  const [permit_list, set_permit_list] = useState([]);

  useEffect(() => set_is_open(open), [open]);

  return (
    <Slide direction="right" timeout={1100} in={is_open} mountOnEnter unmountOnExit>
      <div style={{marginBottom: 15}}>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="extra-price-options"
          >
            <Typography variant="h6" gutterBottom style={{fontWeight:400}}>Personal ID</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{marginLeft: 5, marginBottom:25}}>
              <div style={{padding:15, maxWidth:280}}>
                <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                  <Grid item>
                    <Tooltip title={'type in your personal identification number'}>
                      <img src={license_icon} alt={''} style={{width:32, height: 32, position:'relative', top:5}}/>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{flexGrow: 1}}>
                    <TextField
                      label={'personal ID'}
                      name={'personal-id'}
                      type='text'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      placeholder={'203555666'}
                      value={'new_license.personal_id'}
                      onChange={event=>{}}
                    />
                  </Grid>
                </Grid>

                <div style={{padding:0, paddingTop:15}}>
                  <legend>ID Front Side</legend>
                  <DocumentPhoto
                    style={{marginBottom:15}}
                    disableName={true}
                    addButtonProps={{color: "rose", round: true}}
                    changeButtonProps={{color: "rose", round: true}}
                    removeButtonProps={{color: "danger", round: true}}
                    validate={(file:File) => {
                      return validSizeMB(file, 2)
                        && validPhotoExtension(file);
                    }}
                    onChange={(props:FileChangeProps) => {

                    }}
                  />

                  <legend>ID Back Side</legend>
                  <DocumentPhoto
                    disableName={true}
                    addButtonProps={{color: "rose", round: true}}
                    changeButtonProps={{color: "rose", round: true}}
                    removeButtonProps={{color: "danger", round: true}}
                    validate={(file:File) => {
                      return validSizeMB(file, 2)
                        && validPhotoExtension(file);
                    }}
                    onChange={(props:FileChangeProps) => {

                    }}
                  />

                  <legend>ID Attachment</legend>
                  <DocumentPhoto
                    disableName={true}
                    addButtonProps={{color: "rose", round: true}}
                    changeButtonProps={{color: "rose", round: true}}
                    removeButtonProps={{color: "danger", round: true}}
                    validate={(file:File) => {
                      return validSizeMB(file, 2)
                        && validPhotoExtension(file);
                    }}
                    onChange={(props:FileChangeProps) => {

                    }}
                  />
                </div>
              </div>

            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

      </div>
    </Slide>
  )
};
