import Tooltip from "./Tooltip";
import React from "react";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import {text3} from "../assets/jss/textStyle";
import cancelIcon from "../assets/images/icons/cancel-svgrepo-com.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {useTheme} from "@material-ui/core";

export default (
  {
    open=true,
    timeout=600,
    tip,
    tipProps={},
    onClick,
    containerProps={style:{}},
    wrapperProps={style:{}},
    btnProps={style:{}},
    iconProps={style:{}},
  }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const containerStyle = containerProps.style;
  const wrapperStyle = wrapperProps.style;
  const btnStyle = btnProps.style;
  const iconStyle = iconProps.style;

  delete containerProps.style;
  delete wrapperProps.style;
  delete btnProps.style;
  delete iconProps.style;

  return (
    <div style={{...containerStyle}} {...containerProps}>
      <Zoom in={open} timeout={timeout}>
        <div style={{...wrapperStyle}} {...wrapperProps}>
          <Tooltip title={tip} {...tipProps}>
            <IconButton
              onClick={onClick}
              style={{
                ...text3,
                marginTop:0,
                marginBottom: 2,
                width: 48,
                height: 48,
                fontSize: mobile ? 13 : 18,
                color: '#0073b1',
                margin:0,
                padding:0,
                ...btnStyle,
              }}
              {...btnProps}
            >
              <img
                src={cancelIcon}
                alt='save'
                style={{
                  width: 36,
                  height: 36,
                  color: '#0073b1',
                  ...iconStyle,
                }}
                {...iconProps}
              />
            </IconButton>
          </Tooltip>
        </div>
      </Zoom>
    </div>
  )
}