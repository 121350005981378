import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TimeAndPlace from '../../components/search_question_forms/TimeAndPlace';
import TransportType from '../../components/search_question_forms/TransportType';
import FreightDetails from '../../components/search_question_forms/FreightDetails';
import match_maker from '../../components/search_question_forms/match_maker';
import Navbar from '../../components/navbar/Navbar';
import NavPlaceholder from '../../components/navbar/NavPlaceholder';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom'

const GObuttonStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
}));

function GObutton() {
    const history = useHistory();
    const classes = GObuttonStyles();

    return (
      <Button
        onClick={()=>history.push(`/search_transport/${match_maker.match.params.id}/results`)}
        variant="contained"
        color="primary"
        className={classes.button}
        // endIcon={<Icon>send</Icon>} /* This Button uses a Font Icon, see the installation instructions in the Icon component docs. */
      >
          Find
      </Button>
    );
}

function Backbutton({handleChangeIndex}) {
    const classes = GObuttonStyles();

    return (
      <Button
        onClick={handleChangeIndex}
        variant="contained"
        color="primary"
        className={classes.button}
      >
          Back
      </Button>
    );
}

function Nextbutton({handleChangeIndex}) {
    const classes = GObuttonStyles();

    return (
      <Button
        onClick={handleChangeIndex}
        variant="contained"
        color="primary"
        className={classes.button}
      >
          Next
      </Button>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        // minHeight: '90vh',
        backgroundColor: theme.palette.background.paper,
    },
}));


export default function CenteredTabs({match}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    match_maker.match = match;
    const theme = useTheme();


    const handleChangeIndex = index => {
        setValue(index);
    };

    return [
        <NavPlaceholder key={0}/>,
        <div className={classes.root} key={1}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
              aria-label="scrollable auto tabs"
              centered
            >
                <Tab label="Time & Place" {...a11yProps(0)} />
                <Tab label="Transport Type" {...a11yProps(1)} />
                <Tab label="Freight Details" {...a11yProps(2)} />
            </Tabs>

            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <TimeAndPlace/>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <TransportType/>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <FreightDetails/>
                </TabPanel>
            </SwipeableViews>
            <div style={{
                // bottom : "50px",
                // right : "100px",
                // position: "fixed",
            }}>
                {value > 0
                  ?<Backbutton handleChangeIndex={()=>{handleChangeIndex(value-1)}}/>
                  :null
                }
                {value < 2
                  ?<Nextbutton handleChangeIndex={()=>{handleChangeIndex(value+1)}}/>
                  :<GObutton/>
                }
            </div>
        </div>,
        <Navbar key={2}/>,
    ];
}