import { makeStyles } from '@material-ui/core/styles'

export const signinStyles = makeStyles({
  wrapper: {
    width: 320,
    height: 440,
    // padding: 20,
  },

  box: {
    position:'absolute',
    top:'50%',
    left:'50%',
    transform:'translate(-50%, -50%)',
    width: 360,
    // height: 40,
    padding: 40,
    paddingTop: 0,
    paddingBottom: 0,
    // background: 'rgba(0,0,0,0.8)',
    boxSizing: 'border-box',
    // boxShadow: '0 15px 25px rgba(0,0,0,0.8)'
    // borderRadius: 10,
  },

  inputBox: {
    position: 'relative',
    marginBottom: 40,
  },

  inputPassword: {
    paddingRight: 45,
  },

  InputAdornment: {
    top: 20,
    position: 'absolute',
    right: 10,
  },

  inputBoxInput: {
    width: '100%',
    padding: '10px 0',
    fontSize: 16,
    color: '#a8a8a8',
    letterSpacing: 1,
    border: '1px solid #b2b2b2',
    borderRadius: 2,
    outline: 'none',
    background: '#fafafa',
    textIndent: 10,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textShadow: 'none',
    // backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR…5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC)',
    // backgroundRepeat: 'no-repeat',
    // backgroundAttachment: 'scroll',
    // backgroundSize: '16px 18px',
    // backgroundPosition: '98% 50%',
    cursor: 'auto',
    '&:focus ~ label, &:valid ~ label': {
      top: -22,
      left: 0,
      color: '#636363',
      fontSize: 16,
    },
  },

  inputBoxLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
    pasdding: '10px 0',
    fontSize: 20,
    color: '#a8a8a8',
    pointerEvents: 'none',
    transition: '0.5s',
  },

  invalidCmpInput: {
    width: '100%',
    padding: '10px 0',
    fontSize: 16,
    color: '#eb968b',
    letterSpacing: 1,
    border: '1px solid #f89a84',
    borderRadius: 2,
    outline: 'none',
    background: '#ffffff',
    textIndent: 10,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textShadow: 'none',
    cursor: 'auto',
    '&:focus ~ label, &:valid ~ label': {
      top: -22,
      left: 0,
      color: '#e79070',
      fontSize: 16,
    },
  },

  invalidCmpLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
    pasdding: '10px 0',
    fontSize: 20,
    color: '#f9775d',
    pointerEvents: 'none',
    transition: '0.5s',
  },

  validCmpInput: {
    width: '100%',
    padding: '10px 0',
    fontSize: 16,
    color: '#6fda55',
    letterSpacing: 1,
    border: '1px solid #74b255',
    borderRadius: 2,
    outline: 'none',
    background: '#eaffe5',
    textIndent: 10,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textShadow: 'none',
    cursor: 'auto',
    '&:focus ~ label, &:valid ~ label': {
      top: -22,
      left: 0,
      color: '#6bbf4c',
      fontSize: 16,
    },
  },

  validCmpLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
    pasdding: '10px 0',
    fontSize: 20,
    color: '#84ee5f',
    pointerEvents: 'none',
    transition: '0.5s',
  },

  buttons: {
    background: '#03a9f4',
    border: 'none',
    outline: 'none',
    color: '#fafafa',
    width: '100%',
    cursor: 'pointer',
    borderRadius: 5,
    padding: 8,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
  forgot_password: {
    marginTop: 3,
    cursor: 'pointer',
    '&:hover': {
      color: '#03a9f4',
    }
  },
  signup_msg: {
    marginTop: 0,
    marginBottom:10,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: '#03a9f4',
    }
  },
  signup_msg_highlight: {
    marginTop: 0,
    marginBottom:10,
    textAlign: 'center',
    cursor: 'pointer',
    color: '#03a9f4',
    transition: '0.5s',
    '&:hover': {
      color: '#1d91e7',
      fontSize:15,
    }
  },
  signup_header_msg: {
    WebkitBoxDirection: 'normal',
    padding: 0,
    border: 0,
    font: 'inherit',
    verticalAlign: 'baseline',
    color: '#999',
    textAlign: 'center',
    lineHeight: '20px',
    fontSize: '17px',
    margin: '0 40px 10px',
  }
});
