import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Order from '@material-ui/icons/AddShoppingCart';
import uuid from 'uuid/v4';
import Swal from 'sweetalert2'

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345,
        boxShadow: '0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025)',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    left: {marginLeft: 'auto', marginRight: 'auto'},
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

const pics = [
    'https://cdn.vox-cdn.com/thumbor/wnTN-QCFVqYVeCPaztGkCj7UpiY=/0x0:4243x3079/1200x800/filters:focal(1783x1201:2461x1879)/cdn.vox-cdn.com/uploads/chorus_image/image/65022936/TuSimple_Self_Drving_Truck_4_copy.0.jpg',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRFpX5wubBxl2RH0-xOeZt-c6gb8vdOhqJCfO0pasixH4ZwZC1vA&s',
    'https://www.joc.com/sites/default/files/field_feature_image/Truck%20new%20highway.jpg%202.jpg',
];

const titles = [
  'Containers Transport',
  'Equipment Transport',
  'Dirt & Concrete Transport',
];

const companies = [
  'הובלות Ask5',
  'traffilog',
  'Ashdod Port',
  'ש.ה.מ חברה להובלות בע"מ',
  'Total Care Logistics',
];

const companies_description = [
  'שירות שילוח מטענים בהוד השרון, ישראל',
  'שירות שילוח מטענים בישראל',
  'שירות הובלה ואחסון בראשון לציון, ישראל',
  'שירות דואר באשדוד, ישראל',
  'חברת משאיות להובלה ברמת גן, ישראל',
  'שירות מוניות בגבעתי, ישראל',
  'נמל תעופה בישראל',
];

const learn_more = [
  'כתובת: מזא"ה 24, תל אביב יפו\n' +
  'טלפון: 052-859-5599',
  'כתובת: איזור התעשיה בני דרום 6, אשדוד, 7714001\n' +
  'שעות פתיחה: \n' +
  'סגור ⋅ ייפתח ביום יום ג׳ בשעה 8:00\n' +
  '\n' +
  '\n' +
  'טלפון: 08-611-1222',
  'בתוך: קניון עופר לב אשדוד\n' +
  'כתובת: יהודה הלוי 14, אשדוד',
  'כתובת: חיים לסקוב, אשדוד, 7719100\n' +
  'טלפון: 08-851-7770',
  'נמל התעופה הבינלאומי בן-גוריון הוא נמל התעופה הבינלאומי הגדול בישראל, ומשמש כשער הכניסה הראשי למדינה. בעת הקמתו על ידי רשויות המנדט הבריטי, בשנת 1936, לפני קום המדינה, כונה שדה התעופה בשם "שדה התעופה וילהלמה" על שם המושבה הטמפלרית וילהלמה הסמוכה, אשר על אדמותיה הוקם.כתובת: 7015001\n' +
  'קוד IATA‏: TLV\n' +
  'גובה: 135 רגל\n' +
  'טלפון: 03-972-3333\n' +
  'נפה: תל אביב יפו\n' +
  'אזורי שירות: גוש דן, ירושלים',
];

const avatars = [
 'R',
 'B',
 'S',
 'A',
 'Z',
 'O',
];

function randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

export default function RecipeReviewCard() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        {avatars[Math.floor(Math.random() * avatars.length)]}
                    </Avatar>
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
                title={titles[Math.floor(Math.random() * titles.length)]}
                subheader={`${randomDate(new Date(2016, 0, 1), new Date()).toUTCString()}`}
            />
            <CardMedia
                className={classes.media}
                image={pics[Math.floor(Math.random() * pics.length)]}
                title="Paella dish"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    {companies[Math.floor(Math.random() * companies.length)]}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {companies_description[Math.floor(Math.random() * companies_description.length)]}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <ThumbDown/>
                </IconButton>
                <IconButton aria-label="share">
                    <ThumbUp/>
                </IconButton>
                <IconButton
                    aria-label="Order Now"
                    className={classes.left}
                    onClick={()=>{
                        Swal.fire(
                            `Order: ${uuid().slice(0, 8)}`,
                            'Your Transport is on It\'s way',
                            'success'
                        )
                    }}
                >
                    <Order/>
                </IconButton>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography paragraph>Learn More:</Typography>
                    <Typography paragraph>
                        {learn_more[Math.floor(Math.random() * learn_more.length)]}
                    </Typography>
                    <Typography paragraph>
                        Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high
                        heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly
                        browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken
                        and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
                        pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add
                        saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
                    </Typography>
                    <Typography paragraph>
                        Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook
                        without stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to
                        medium-low, add reserved shrimp and mussels, tucking them down into the rice, and cook
                        again without stirring, until mussels have opened and rice is just tender, 5 to 7
                        minutes more. (Discard any mussels that don’t open.)
                    </Typography>
                    <Typography>
                        Set aside off of the heat to let rest for 10 minutes, and then serve.
                    </Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
}