import request from 'axios'
import {auth_url} from '../utils/urls'
import {auth_config} from '../utils/configs'
import {auth_call, silent_auth_call} from '../utils/auth_calls'
import sleep from '../../utils/sleep'

export const account_setup = auth_call(async ({
  username,
  full_name,
  country,
  region,
  city,
  phone_number,
  company,
}) => {
  const data = {
    username,
    full_name,
    country: country ? country : null,
    region: region ? region : null,
    city: city ? city : null,
    phone_number: phone_number ? phone_number : null,
    company: company ? company : null,
  };
  await request.post(auth_url+'/account/setup', data, auth_config);
  return data
});

export const edit_description = silent_auth_call(async ({description}) => {
  await request.post(auth_url+'/account/edit/description', {description}, auth_config);
  return true;
});

export const edit_photo = silent_auth_call(async ({set_user, user, file}) => {
  const data = new FormData();
  data.append('file', file);
  const res = await request.post(auth_url+'/account/edit/photo', data, auth_config);
  // set_user({...user, ...res.data});
  return true;
});

export const edit_account_info = auth_call(async (
  {
    full_name,
    country,
    region,
    city,
    phone_number,
    company,
    job,
  }
  ) => {
  const data = {
    full_name: full_name ? full_name : null,
    country: country ? country : null,
    region: region ? region : null,
    city: city ? city : null,
    phone_number: phone_number ? phone_number : null,
    company: company ? company : null,
    job: job ? job : null,
  };
  await request.post(auth_url+'/account/edit/info', data, auth_config);
  return data
});

export const update_email = auth_call(async (
  {
    email,
    news_letter,
    password,
  }
) => {
  const data = {
    email,
    news_letter,
    password,
  };
  await request.post(auth_url+'/account/edit/email', data, auth_config);
  return true
});

export const has_password = auth_call(async () => {
  const res = await request.get(auth_url+'/account/has_password', auth_config);
  return {has_password: res.data.password};
});

export const change_password = auth_call(async (
  {
    old_password,
    new_password,
    email,
  }
) => {
  await request.post(auth_url+'/account/edit/password', {
    old_password,
    new_password,
    email,
  }, auth_config);
  return true
});
