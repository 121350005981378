import React, {useContext, useEffect} from "react";
import state from "../state";
import LoadPage from "../views/OtherPages/LoadPage";
import NoMatch from "../views/OtherPages/NoMatch";
import routes from '../routes'
import {useHistory} from 'react-router-dom'
import AppBar from '../components/navbars/AppBar'
import Footer from '../components/footers/Footer'
import PageLayout from '../components/layout/PageLayout'

export const PublicView = ({children}) => {
  const {page_loading} = useContext(state.page_loading);

  if (page_loading)
    return <LoadPage/>;
  return children;
};

export const UserView = ({children}) => {
  const {user} = useContext(state.user);
  const {page_loading} = useContext(state.page_loading);

  if (page_loading)
    return <LoadPage/>;
  if (user === null)
    return <NoMatch/>;
  return children;
};

export const RestrictView = ({children}) => {
  const {user} = useContext(state.user);
  const {page_loading} = useContext(state.page_loading);
  const history = useHistory();

  useEffect(() => user === null && history.push(routes.home), [page_loading]);

  if (page_loading)
    return <LoadPage/>;
  if (user === null) {
    return null;
  }
  return children;
};

export const Page = ({children=null, ...props}) => {
  return (
    <PageLayout {...props}>
      {children}
      <div style={{flexGrow: 1}}/>
      <Footer/>
    </PageLayout>
  )
};