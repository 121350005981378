import React from 'react'
import useStyles from '../../assets/jss/termStyles'
import {company, website, app_name} from "../../variables";


export default function () {
  const classes = useStyles();
  return (
      <div className={classes.termContainer}>
        <div className={classes.termBox}>
          <h1 className={classes.termTitle}>Contact Us</h1>
          <div style={{width: 200, margin:'auto'}}>
            <p>
              Company: {company}
            </p>
            <p>
              website: {website}
            </p>
            <p>
              email: contact@gotrack.com
            </p>
            <p>
              phone: +972 52-920-3695
            </p>
            <p>
              location: Israel, Nahariya
            </p>
          </div>
        </div>
      </div>
  );
}