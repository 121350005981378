import React, {useState, useEffect} from 'react'
import {password_recovery_1} from '../../api/public/change_password'
import CircularProgress from '@material-ui/core/CircularProgress';
import Countdown from '../counters/Countdown'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '../custom/CustomExpansion/ExpansionPanel'
import {
  email_validation,
  set_error
} from '../../validations/signin_validations'

const forgotPasswordsStyles = makeStyles({
  reSend: {
    color: '#7da5cc',
    cursor: 'pointer',
    '&:hover': {
      color: '#03a9f4',
    },
  },
  tokenExpired: {
    color: '#888888',
  },
  counterText: {
    color: '#777777',
  },
  counter: {
    color: '#03a9f4',
  },
  goStep2: {
    border: '1px solid rgba(0, 0, 0, 0.46)',
    padding: 2,
    fontSize: 14,
    borderLeft: 'none',
    cursor: 'pointer',
    transition: '.4s',
    '&:hover, &:focus': {
      backgroundColor: '#000',
      color: '#fff'
    }
  },
  tokenInput: {
    width:'100%',
    border: '1px solid rgba(0, 0, 0, 0.46)',
    padding: 2,
    fontSize: 14,
  }
});

export default ({
  classes,
  openPasswordRecovery,
  setOpenPasswordRecovery,
  email,
  set_snackbar,
}) => {

  const [ask_token, set_ask_token] = useState(true);
  const [token_expiration, set_token_expiration] = useState(new Date());

  const handleChange = () => setOpenPasswordRecovery(!openPasswordRecovery);

  useEffect(() => {
    const onForgotPassword = async () => {
      if (openPasswordRecovery) {
        const date = new Date();

        if (date < token_expiration)
          return;

        date.setSeconds(date.getSeconds() + 10 * 60);

        if (ask_token) {
          try {
            email_validation(email);
          } catch (e) {
            setOpenPasswordRecovery(false);
            return set_snackbar(set_error(e.message))
          }
          if (!await password_recovery_1(email)) {
            return setOpenPasswordRecovery(false);
          }
          set_token_expiration(date);
          set_ask_token(false)
        }
      }
    };
    onForgotPassword();
  }, [openPasswordRecovery]);

  const forgotPasswordsStyle = forgotPasswordsStyles();
  return (
    <ExpansionPanel
      open={openPasswordRecovery}
      onChange={handleChange}
      panel={
        <div className={classes.forgot_password} onClick={handleChange}>
          {openPasswordRecovery?'never-mind I remember':'forgot password?'}
        </div>
      }
      details={[
        ask_token && <Typography key={0}>We're sending validation token to your email!</Typography>,
        ask_token && <CircularProgress key={2} color="primary"/>,
        !ask_token &&
          <Countdown
            key={3}
            date={token_expiration}
            onComplete={()=>
              <span>
                  <span className={forgotPasswordsStyle.tokenExpired}>
                    token expired
                  </span>
                {" → "}
                <span className={forgotPasswordsStyle.reSend} onClick={()=>set_ask_token(true)}>
                    re-send
                  </span>
              </span>
            }
            onCountDown={({counter})=>
              <span>
                <span className={forgotPasswordsStyle.counterText}>
                  token expires in:
                </span>
                <span className={forgotPasswordsStyle.counter}>
                  {" "}{counter}s{" "}
                </span>
                <span className={forgotPasswordsStyle.counterText} style={{display:'flex'}}>
                  check your e-mail to complete your password recovery process
                </span>
              </span>
            }
          />,
      ]}
    />
  );
}
