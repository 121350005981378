import React, {useState, useCallback, useRef, useContext, useEffect} from "react"
import {GoogleMap, Marker, InfoWindow} from "@react-google-maps/api"
import {getGeocode, getLatLng} from "use-places-autocomplete"
// import usePlacesAutocomplete from "use-places-autocomplete"
// import {
//   Combobox,
//   ComboboxInput,
//   ComboboxPopover,
//   ComboboxList,
//   ComboboxOption,
// } from "@reach/combobox"
import { formatRelative } from "date-fns"
// import "@reach/combobox/styles.css"
import mapStyles from "./mapStyles"
import {makeStyles} from "@material-ui/core/styles"
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import state from '../../state'
import GoogleMapsInput from '../custom/CustomInput/GoogleMapsInput'
import Swal from 'sweetalert2'

// https://www.youtube.com/watch?v=WZcxJGmLbSo

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },

  header: {
    // position: 'absolute',
    // top: '1rem',
    // left: '1rem',
    minWidth: 100,
    color: '#281414',
    zIndex: 1500,
    margin: 0,
    padding: 0,
  },

  search: {
    position: 'absolute',
    top: '1rem',
    left: '1rem',
    // left: '50%',
    // transform: 'translateX(-50%)',
    width: '100%',
    maxWidth: '400px',
    zIndex: 1500,
    '& input': {
      // padding: '0.5rem',
      // fontSize: '1.5rem',
      width: '100%',
    }
  },

  searchContainer: {
    marginBottom: 30,
  },

  locate: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    background: 'none',
    border: 'none',
    zIndex: 10,
    '& img': {
      width: 30,
      cursor: 'pointer',
    }
  },
});

const mapContainerStyle = {
  height: "100%",
  width: "100%",
  position:'relative',
};

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

const center = {
  lat: 31.046051,
  lng: 34.851612,
};

function Search({ onSelect, panTo, title }) {
  const classes = useStyles();
  // const {
  //   ready,
  //   value,
  //   suggestions: { status, data },
  //   setValue,
  //   clearSuggestions,
  // } = usePlacesAutocomplete({
  //   requestOptions: {
  //     location: { lat: () => 43.6532, lng: () => -79.3832 },
  //     radius: 1000 * 1000,
  //   },
  // });
  //
  // // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  //
  // const handleInput = (e) => {
  //   setValue(e.target.value);
  // };

  const handleSelect = async (address) => {
    // setValue(address, false);
    // clearSuggestions();
    try {
      const results = await getGeocode({ address: address.description });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
      onSelect({address: address.description, lat, lng})
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: 'failed to set location',
        text: `${error}`,
        confirmButtonText: 'OK',
        timer: 8000,
      });
    }
  };

  return (
    <div className={classes.searchContainer}>
      <GoogleMapsInput onSelect={handleSelect} label={title}/>
    </div>
  );
}

function useLocate(panTo, loaded_google_maps, ready) {
  useEffect(()=>{
    if (loaded_google_maps && ready) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          panTo({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => null
      );
    }
  }, [loaded_google_maps, ready]);
}

export default ({onSelectFromAddress, onSelectToAddress}) => {
  const classes = useStyles();
  const loaded_google_maps = useContext(state.loaded_google_maps);
  const [markers, setMarkers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [ready, set_ready] = useState(false);

  const onMapClick = useCallback((e) => {
    setMarkers((current) => [
      ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
      },
    ]);
  }, []);

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    set_ready(true);
  }, []);

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(13);
  }, []);

  useLocate(panTo, loaded_google_maps, ready);

  if (loaded_google_maps === null)
    return (
      <Grid container justify='center' alignItems='center' className={classes.root}>
        <CircularProgress/>
      </Grid>
    );

  if (loaded_google_maps === false)
    return (
      <Grid container justify='center' alignItems='center' className={classes.root}>
        <h1 className={classes.header}>Failed to Load Google Maps Scripts</h1>
      </Grid>
    );

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <Search panTo={panTo} title={'from location'} onSelect={onSelectFromAddress}/>
        <Search panTo={panTo} title={'to location'} onSelect={onSelectToAddress}/>
      </div>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={8}
        center={center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {markers.map((marker) => (
          <Marker
            key={`${marker.lat}-${marker.lng}`}
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={() => {
              setSelected(marker);
            }}
            // icon={{
            //   url: `/bear.svg`,
            //   origin: new window.google.maps.Point(0, 0),
            //   anchor: new window.google.maps.Point(15, 15),
            //   scaledSize: new window.google.maps.Size(30, 30),
            // }}
          />
        ))}

        {selected ? (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.lng }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div>
              <h2>
                <span role="img" aria-label="bear">
                  🐻
                </span>{" "}
                Alert
              </h2>
              <p>Spotted {formatRelative(selected.time, new Date())}</p>
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>
  );
}
