import React from 'react';
import 'typeface-roboto';
import './assets/scss/material-dashboard-pro-react.scss';
import './assets/scss/styles.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import route from './routes';

import SignIn from './components/signin/SignIn'
import BackDrop from './components/loaders/BackDrop'
import GloablState from './components/global/GlobalState'
import GlobalSnackbar from './components/global/GlobalSnackbar'
import GlobalScroller from './components/global/GlobalScroller'
import GlobalHooks from './components/global/GlobalHooks'
import AppBar from "./components/navbars/AppBar";
import IncompleteProfileDialog from './components/dialogs/IncompleteProfileDialog'

import SearchQuestionnaire from './views/Search/SearchQuestionnaire';
import GetSearchResults from './views/Search/GetSearchResults';
import OnRedirect from './views/Auth/OnRedirect'

import MyAccount from './views/UserPages/MyAccount'
import MyAccountSetup from './views/UserPages/MyAccountSetup'
import MyAccountEdit from './views/UserPages/MyAccountEdit'
import MyAssets from './views/UserPages/MyAssets'

import NewOrders from './views/Orders/NewOrders'

import Home from './views/Gotrack/Home';
import About from './views/Gotrack/About'
import Contact from './views/Gotrack/Contact'
import Terms from './views/Gotrack/Terms'
import Privacy from './views/Gotrack/Privacy'
import OurTeam from './views/Gotrack/OurTeam'
import InvestorsRelations from './views/Gotrack/InvestorsRelations'
import Press from './views/Gotrack/Press'
import TrustAndSafety from './views/Gotrack/TrustAndSafety'
import Help from './views/Gotrack/Help'

import GotrackersCarriers from './views/Gotrackers/GotrackersCarriers'
import GotrackersDriverWithoutVehicle from './views/Gotrackers/GotrackersDriverWithoutVehicle'
import GotrackersDriverWithVehicle from './views/Gotrackers/GotrackersDriverWithVehicle'
import GotrackersVehicleOwner from './views/Gotrackers/GotrackersVehicleOwner'
import GotrackersListYourself from './views/Gotrackers/GotrackersListYourself'
import GotrackersHowItWorks from './views/Gotrackers/GotrackersHowItWorks'
import GotrackersQuestions from './views/Gotrackers/GotrackersQuestions'
import GotrackersHowToEarnMoney from './views/Gotrackers/GotrackersHowToEarnMoney'
import GotrackersInsuranceAndProtection from './views/Gotrackers/GotrackersInsuranceAndProtection'
import GotrackersGoldTrackerBenefits from './views/Gotrackers/GotrackersGoldTrackerBenefits'
import GotrackersCareerAndPlacement from './views/Gotrackers/GotrackersCareerAndPlacement'

import ShippersHowItWorks from './views/Shippers/ShippersHowItWorks'
import ShippersQuestions from './views/Shippers/ShippersQuestions'
import ShippersInsuranceAndProtection from './views/Shippers/ShippersInsuranceAndProtection'

import NoMatch from './views/OtherPages/NoMatch';

import ValidateNewPassword from './views/Auth/ValidateNewPassword'
import ValidateUser from './views/Auth/ValidateUser'
import withTracker from './wrappers/withTracker'


export default () =>
  <Router basename={process.env.PUBLIC_URL}>
    <GloablState>
      <GlobalHooks/>
      <GlobalScroller/>
      <AppBar/>
      <Switch>
        <Route path={route.home} exact component={withTracker(Home)}/>
        <Route path={route.my_account} exact component={withTracker(MyAccount)}/>
        <Route path={route.my_account_setup} exact component={withTracker(MyAccountSetup)}/>
        <Route path={route.my_account_edit} exact component={withTracker(MyAccountEdit)}/>
        <Route path={route.my_assets} exact component={withTracker(MyAssets)}/>

        <Route path={route.new_orders} exact component={withTracker(NewOrders)}/>

        <Route path={route.terms} exact component={withTracker(Terms)}/>
        <Route path={route.privacy} exact component={withTracker(Privacy)}/>
        <Route path={route.about} exact component={withTracker(About)}/>
        <Route path={route.contact} exact component={withTracker(Contact)}/>
        <Route path={route.validate_new_password} exact component={withTracker(ValidateNewPassword)}/>
        <Route path={route.validate_register} exact component={withTracker(ValidateUser)}/>
        <Route path={route.redirect_oauth} component={withTracker(OnRedirect)}/>
        <Route path={route.search_transport} exact component={withTracker(SearchQuestionnaire)}/>
        <Route path={route.search_transport_results} exact component={withTracker(GetSearchResults)}/>
        <Route path={route.team} component={withTracker(OurTeam)}/>
        <Route path={route.investors_relations} component={withTracker(InvestorsRelations)}/>
        <Route path={route.press} component={withTracker(Press)}/>
        <Route path={route.trust_and_safety} component={withTracker(TrustAndSafety)}/>
        <Route path={route.help} component={withTracker(Help)}/>
        <Route path={route.gotrackers_carriers} component={withTracker(GotrackersCarriers)}/>
        <Route path={route.gotrackers_driver_without_vehicle} component={withTracker(GotrackersDriverWithoutVehicle)}/>
        <Route path={route.gotrackers_driver_with_vehicle} component={withTracker(GotrackersDriverWithVehicle)}/>
        <Route path={route.gotrackers_vehicle_owner} component={withTracker(GotrackersVehicleOwner)}/>
        <Route path={route.gotrackers_list_yourself} component={withTracker(GotrackersListYourself)}/>
        <Route path={route.gotrackers_how_it_works} component={withTracker(GotrackersHowItWorks)}/>
        <Route path={route.gotrackers_questions} component={withTracker(GotrackersQuestions)}/>
        <Route path={route.gotrackers_how_to_earn_money} component={withTracker(GotrackersHowToEarnMoney)}/>
        <Route path={route.gotrackers_insurance_and_protection} component={withTracker(GotrackersInsuranceAndProtection)}/>
        <Route path={route.gotrackers_list_yourself} component={withTracker(GotrackersGoldTrackerBenefits)}/>
        <Route path={route.gotrackers_career_and_placement} component={withTracker(GotrackersCareerAndPlacement)}/>
        <Route path={route.shippers_how_it_works} component={withTracker(ShippersHowItWorks)}/>
        <Route path={route.shippers_questions} component={withTracker(ShippersQuestions)}/>
        <Route path={route.shippers_insurance_and_protection} component={withTracker(ShippersInsuranceAndProtection)}/>
        <Route component={NoMatch}/>
      </Switch>
      <GlobalSnackbar/>
      <IncompleteProfileDialog/>
      <SignIn/>
      <BackDrop/>
    </GloablState>
  </Router>
