import {useState, useEffect, useContext} from 'react'
import {refresh} from '../api/public/refresh'
import {get_auth_from_local_storage} from '../api/utils/auth_handler'
import useInterval from './useInterval'
import state from '../state'
import {AuthType} from '../types'
import Toast from "../components/global/Toast";

const block = {
  until: new Date()
};

export default () => {
  const {user, set_user} = useContext(state.user);
  const {signin, setSignin} = useContext(state.signin);
  const [delay, set_delay] = useState(false);
  const [auth, set_auth] = useState(false);

  const logout = () => {
    set_user(null);
    Toast('warning', 'session timed out');
    setSignin({...signin, open: false});
  };

  useEffect(() => {
    let token:AuthType = false;

    if (user) {
      token = get_auth_from_local_storage();
      if (token) {
        set_auth(token);
        set_delay(token.access_expiration * 1000);
        block.until = token.access_expiration_date;
      }
    }
    if (!token) {
      set_auth(false);
      set_delay(false);
      block.until = new Date();
    }
  }, [user]);

  useInterval(() => {
    const update_auth = async () => {
      const tmp_block = new Date();

      let token:AuthType = false;

      set_delay(false);
      set_auth(false);

      if (tmp_block > auth.refresh_expiration_date) {
        logout();
      }
      else {
        try {
          tmp_block.setSeconds(tmp_block.getSeconds()+60);
          block.until = tmp_block;

          token = await refresh();
          if (!token)
            logout();

          set_auth(token);
        } catch (e) {}
      }
      set_delay(token ? token.access_expiration * 1000 : false);
      block.until = token ? token.access_expiration_date : tmp_block;
    };

    if (user)
      if (delay)
        if (auth)
          if (block.until >= auth.access_expiration_date)
            update_auth();
  }, delay);
}