import React, {useContext} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import Add from '@material-ui/icons/Add'
import MailIcon from '@material-ui/icons/Mail'
import state from '../../../state'
import SignIN from './tools/SignIn'
import ids from '../../../ids'
import {AppBarAvatar} from '../../icons/Avatars'
import Notification from './tools/Notification'

const useStyles = makeStyles(theme => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

export default ({handleProfileMenuOpen}) => {
  const links = useContext(state.links);
  const classes = useStyles();

  const {user} = useContext(state.user);
  // const {messages} = useContext(state.messages);

  return (
    <div className={classes.sectionDesktop}>
      <IconButton
        aria-label="New Post"
        color="inherit"
        onClick={links.go_to_upload_post}
      >
        <Add />
      </IconButton>
      {!user && <SignIN/>}
      {user && null
        //<IconButton aria-label="show 4 new mails" color="inherit">
        //  <Badge badgeContent={messages.length} color="secondary">
        //    <MailIcon />
        //  </Badge>
        //</IconButton>
      }
      {user && <Notification/>}
      {user &&
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls={ids.account_menu}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        style={{width:48,height:48, padding: 0}}
      >
        <AppBarAvatar gravatar_link={user.image_url}/>
      </IconButton>
      }
    </div>
  );
}