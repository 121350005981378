import request from 'axios'
import {auth_url} from '../utils/urls'
import {auth_config} from '../utils/configs'
import {silent_auth_call} from '../utils/auth_calls'

export const count_post_promoters = silent_auth_call(async ({post_id}) => {
  const res = await request.get( `${auth_url}/promoting/post/${post_id}/count/promoters`, auth_config);
  return res.data.promoters;
});

export const list_post_promoters = silent_auth_call(async ({skip=0, limit=30, post_id}) => {
  const res = await request.post(
    `${auth_url}/promoting/post/${post_id}/list/promoters`,
    {
      skip,
      limit,
    }, auth_config);
  return {
    promoters: res.data.promoters,
    skip: res.data.next,
  };
});