import React, {useContext, useState, useEffect} from 'react'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import DraftsIcon from '@material-ui/icons/Drafts'
import SendIcon from '@material-ui/icons/Send'
import IconButton from "@material-ui/core/IconButton"
import Badge from "@material-ui/core/Badge/Badge"
import NotificationsIcon from "@material-ui/icons/Notifications"
import state from "../../../../state"
import style_vars from '../../../../assets/jss/style_vars'
import notificationStyles from '../../../../assets/jss/notificationStyles'
// import {delete_notifications, get_points_notification} from '../../../../api/auth/messaging'
import { count_post_promoters, list_post_promoters } from '../../../../api/auth/promoting'
import CloseIcon from '@material-ui/icons/Close'
import useInterval from "../../../../hooks/useInterval"
import beautifulNumber from '../../../../utils/beautifulNumber'
import CircularIndeterminate from '../../../loaders/CircularIndeterminate'
import { notification_vars } from '../../../../variables'
import type { User, Function } from '../../../../types'
import Follow from '../../../custom/CustomButtons/Follow'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: style_vars.colors.border,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: style_vars.colors.small_text,
      },
    },
  },
}))(MenuItem);


const Title = ({...props}) =>
  <li style={{
    padding:'10px 20px',
    fontSize: 16,
    color: style_vars.colors.small_text,
    borderBottom: style_vars.borders.default,
    // background: style_vars.colors.background,
    // width: '100%',
    marginBottom:5,
  }} {...props}>
    <b>
      Promoters
    </b>
  </li>;

const update_notifications = async (
  user,
  set_user,
  total_notifications_count,
  set_total_notifications_count,
  set_spring_toast,
  next_points_calculation,
  set_next_points_calculation,
  ) => {
  if (user) {
    const count = await count_post_promoters({set_user, post_id: user.last_post_id});
    if (user) {
    // wait 1/5 for next calc
    if (count) {
      set_total_notifications_count(count);
      if (count > total_notifications_count) {
        set_spring_toast('Congratulations! you got promoted!');
        if (next_points_calculation/5 > notification_vars.min_delay_next_call)
          set_next_points_calculation(next_points_calculation/5);
        else set_next_points_calculation(notification_vars.min_delay_next_call);
      }
    }
    // wait x1.02 for next calc
    else {
      if (next_points_calculation*1.02 < notification_vars.max_delay_next_call)
        set_next_points_calculation(next_points_calculation*1.02);
      else set_next_points_calculation(notification_vars.max_delay_next_call);
    }
    }
  }
};

export default function CustomizedMenus() {
  const links = useContext(state.links);
  const { set_spring_toast } = useContext(state.spring_toast);
  // const { notifications, set_notifications } = useContext(state.notifications);
  const { width, height } = useContext(state.size).size;
  const set_user: Function = useContext(state.user).set_user;
  const user: User = useContext(state.user).user;

  const [promoters, set_promoters] = useState([]);
  const [total_notifications_count, set_total_notifications_count] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [to_be_deleted, set_to_be_deleted] = useState([]);
  const [next_points_calculation, set_next_points_calculation] = useState(notification_vars.min_delay_next_call);
  const [loading, set_loading] = useState(false);
  const [skip, set_skip] = useState(0);

  // useEffect(() => {
  //   update_notifications(
  //     user,
  //     set_user,
  //     total_notifications_count,
  //     set_total_notifications_count,
  //     set_spring_toast,
  //     next_points_calculation,
  //     set_next_points_calculation,
  //   )
  // }, []);

    ///////////////////////////
   // notification listener //
  ///////////////////////////
  // useInterval(() => {
  //   update_notifications(
  //     user,
  //     set_user,
  //     total_notifications_count,
  //     set_total_notifications_count,
  //     set_spring_toast,
  //     next_points_calculation,
  //     set_next_points_calculation,
  //   )
  // }, user ? next_points_calculation : null);
  //
  const handleClick = event => {
    if (total_notifications_count) {
      if (promoters.length===0)
        load_more();
      setAnchorEl(event.currentTarget);
      // delete_notifications({set_user, ids: Array.from(notifications.filter(n=>n._id).map(n=>n._id))})
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    set_promoters([]);
    set_skip(0);
    // set_notifications([...Array.from(notifications.filter(n=>!to_be_deleted.includes(n)))]);
  };

  const handleScroll = event => {
    if (total_notifications_count > promoters.length) {
      const element = event.target;
      if (element.scrollHeight - element.scrollTop > element.clientHeight - 600) {
        load_more()
      }
    }
  };

  const load_more = async () => {
    set_loading(true);
    if (total_notifications_count > promoters.length) {
      if (user && user.last_post_id) {
        const res = await list_post_promoters({set_user, skip, limit:30, post_id:user.last_post_id});
        if (res) {
          set_skip(res.skip);
          set_promoters([
            ...promoters,
            ...res.promoters,
          ])
        }
      }
    }
    set_loading(false)
  };


  const PaperProps = {style: {maxHeight: height/2, width: width-30, maxWidth: 600}};
  // const classes = notificationStyles();

  return [
     <IconButton
       key={0}
       aria-label="show new notifications"
       aria-haspopup="true"
       aria-controls="notification-menu"
       onClick={handleClick}
       color="inherit"
     >
       <Badge badgeContent={total_notifications_count?beautifulNumber(total_notifications_count):undefined} color="secondary">
         <NotificationsIcon/>
       </Badge>
     </IconButton>,

    <StyledMenu
      key={1}
      id="notification-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={PaperProps}
      onScroll={handleScroll}
    >
      <div>
        <Title/>
        {/*{notifications.map((notification, index) =>*/}
        {promoters.map((promoter, index) => {
          return (
            <StyledMenuItem
              key={index}
              // onClick={()=>set_to_be_deleted([...to_be_deleted,notification])}
            >
              {/*<ListItemIcon>*/}
              {/*  <DraftsIcon fontSize="small" />*/}
              {/*</ListItemIcon>*/}
              {/*<div>*/}
              {/*  <ListItemText className={classes.text} primary={notification.notification}/>*/}
              {/*  <ListItemText className={classes.date}><div>{notification.created}</div></ListItemText>*/}
              {/*</div>*/}

              {/*<IconButton*/}
              {/*  size="small"*/}
              {/*  style={{marginLeft: 10, position: 'relative', top: -8}}*/}
              {/*  // onClick={()=>set_notifications([...Array.from(notifications.filter(n=>n!==notification))])}*/}
              {/*  color="inherit"*/}
              {/*>*/}
              {/*  <CloseIcon/>*/}
              {/*</IconButton>*/}
              <img
                src={promoter.image_url}
                style={{width:33, height:33, marginRight:15, borderRadius:'50%'}}
                onClick={()=>links.go_to_profile(promoter.username)}
                alt=""
              />
              <div onClick={()=>links.go_to_profile(promoter.username)}>
                <div>{promoter.username}</div>
                <div style={{fontSize:10}}>{promoter.created}</div>
              </div>
              <div onClick={()=>links.go_to_profile(promoter.username)} style={{flexGrow:1, height:39}}/>
              <Follow style={{height:32}} btnProps={{style:{height: 28, fontSize: 14}}} profile={promoter} isfollowing={promoter.is_following}/>
            </StyledMenuItem>
          )
        })}
        {total_notifications_count > promoters.length &&
        <StyledMenuItem>
          {loading &&<CircularIndeterminate color='default'/>}
        </StyledMenuItem>
        }
      </div>
    </StyledMenu>,
  ];
}
