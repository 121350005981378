import Swal from 'sweetalert2'
import {SweetAlertOptions} from 'sweetalert2'


export default (icon, title, options:SweetAlertOptions) => {
  // usage example from https://sweetalert2.github.io/#icons
  Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
    ...options,
  }).fire({
    icon: icon,
    title: title
  });
  return null
};
