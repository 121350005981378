import request, {AxiosResponse as Response} from 'axios'
import conf from '../../conf'
// import {get_csrf_refresh_token} from './get_cookies'
import {remove_auth_from_local_storage, get_auth_from_local_storage, save_auth_in_local_storage} from "../utils/auth_handler";
import {refresh_headers} from '../utils/headers'
import type {AuthType} from "../../types";
import Toast from '../../components/global/Toast'
import open_signin from '../../utils/open_signin'

const url = conf.backend;

// TODO: when we use cookies this will make sense
const get_config = () => {
  const config = {
    headers: refresh_headers,
  };
  // get_csrf_refresh_token(config.headers);
  return config
};


export const refresh = async ():AuthType => {
  try {
    const response = await request.post(url+'/token/refresh', {}, get_config());
    return save_auth_in_local_storage(response);
  } catch (e) {
    remove_auth_from_local_storage();
    console.log(e);
    return false
  }
};

export const handle_refresh = async (response: Response) => {
  try {
    if (!response)
      response = await request.post(url+'/token/refresh', {}, get_config());
    const auth:AuthType = save_auth_in_local_storage(response);
    // setInterval(handle_refresh, auth.access_expiration*1000);
    return true
  }
  catch (e) {
    Toast('warning', 'session timed out');
    remove_auth_from_local_storage();
    open_signin.reset_user();
    open_signin.open();
    console.log(e);
    return false
  }
};

// use if is_logged is true
export const handle_refresh_on_load = async () => {
  const current_date = new Date();
  const auth = get_auth_from_local_storage();

  if (!auth) {
    return false;
  }

  if (current_date > auth.refresh_expiration_date) {
    return false;
  }

  if (current_date > auth.access_expiration_date) {
    return await refresh();
  }
  return true;
};