import React, {useContext, useEffect} from 'react'
import state from "../../state"
// import style_vars from '../assets/jss/style_vars'
import Grid from '@material-ui/core/Grid';
// import { Paragraph, LearnMoreText } from '../components/custom/Text/Text'
// import {SmallRedButton} from '../components/custom/CustomButtons/Buttons'
// import section1img1 from '../assets/images/members_page/section1img1.jpg'
// import section2img1 from '../assets/images/members_page/section2img1.jpg'
// import section3img1 from '../assets/images/members_page/section3img1.jpg'
// import section4img1 from '../assets/images/members_page/section4img1.jpg'
// import section4img2 from '../assets/images/members_page/section4img2.jpg'
import {Header3,
  // Header2
} from '../../components/custom/Text/Headers'
import ids from '../../ids'
// import links from "../links";
import {mobile_size} from '../../variables'
// import {Link} from 'react-router-dom'
// import routes from '../routes.js'

// const PromoteHeader = () => <Header3 style={{width:'100%', marginBottom:18}}>start blogging and<br/>get promoted</Header3>;

// const StartBlogging = () => {
//   // const { scrollTo, set_scrollTo } = useContext(state.scrollTo);
//   const { tutorial_mode, set_tutorial_mode } = useContext(state.tutorial_mode);
//
//   const onClick = () => {
//     links.go_to_my_daily_post();
//     // set_scrollTo({...scrollTo, to: ids.daily_post});
//     set_tutorial_mode({
//       ...tutorial_mode,
//       tip_influencers_go_to_my_daily_blog: false,
//     });
//   };
//
//   return (
//     <Grid container justify="center">
//       {/*<SmallRedButton onClick={onClick} style={{margin:10, width:135}}>*/}
//       {/*  Start Blogging*/}
//       {/*</SmallRedButton>*/}
//     </Grid>
//   )
// };

// const SectionEnd = ({onLearnMore, ...props}) => {
//   return (
//     <div {...props}>
//       {/*<PromoteHeader/>*/}
//       {/*<StartBlogging/>*/}
//       {/*<Grid container justify="center">*/}
//       {/*  <LearnMoreText onClick={onLearnMore} style={{margin:10}}/>*/}
//       {/*</Grid>*/}
//     </div>
//   )
// };

const MembersHeader = () => {
  const { scrollTo, set_scrollTo } = useContext(state.scrollTo);
  const width = useContext(state.size).size.width;

  const onLearnMore = () => set_scrollTo({
    ...scrollTo,
    to: ids.members_section_2
  });

  return (
    <div>
      {/*<Paragraph style={{fontSize: width > mobile_size?20:16}}>*/}
      {/*  GOtrack is a Transportation Marketplace*/}
      {/*</Paragraph>*/}

      {/*<Grid container justify="center" style={{marginBottom:31}}>*/}
      {/*  <img src={section1img1} alt=""/>*/}
      {/*</Grid>*/}

      {/*<Grid container justify="center">*/}
      {/*  <Paragraph style={{fontSize: width > mobile_size?20:16, maxWidth: 520, marginBottom:40}}>*/}
      {/*    See your followers drastically climbing in no-time and create your online boutique*/}
      {/*  </Paragraph>*/}
      {/*</Grid>*/}

      {/*<StartBlogging/>*/}
      {/*<Grid container justify="center">*/}
      {/*  <LearnMoreText onClick={onLearnMore} style={{margin:10}}/>*/}
      {/*</Grid>*/}
    </div>
  )
};

const Section2 = () => {
  const { scrollTo, set_scrollTo } = useContext(state.scrollTo);
  const width = useContext(state.size).size.width;

  const onLearnMore = () => set_scrollTo({
    ...scrollTo,
    to: ids.members_section_3,
  });

  // const Img = () => <img src={section2img1} alt="" style={{margin:'auto', borderRadius:45, boxShadow: '0 1px 3px rgba(0, 0, 0, 0.5)'}}/>;
  // const Txt = ({learnMore}) =>
  //  {/*<Grid container justify='center' style={{width: '100%', maxWidth: 400, paddingTop:20}}>*/}
  //  {/*  /!*<Header2 id={learnMore?undefined:ids.members_section_2} style={learnMore?{marginBottom:20}:{marginBottom:20, fontSize: width > mobile_size?17:15}}>GOTRACK WEBSITE<br/></Header2>*!/*/}
  //
  //  {/*  /!*<Paragraph style={{width:314, margin:'auto', fontSize:16, marginBottom:50}}>*!/*/}
  //  {/*  /!*  Your activity on GOtrack will give you massive exposure and new opportunities*!/*/}
  //  {/*  /!*</Paragraph>*!/*/}
  //  {/*  /!*{learnMore && <SectionEnd onLearnMore={onLearnMore}/>}*!/*/}
  //  {/*</Grid>;*/}

  return (
    <Grid
      container
      justify='center'
      alignItems="center"
      style={{width:'100%', maxWidth:800, margin:'auto', marginTop:130}}
      id={width > mobile_size?ids.members_section_2:undefined}
    >
      {/*{width > mobile_size*/}
      {/*  ? [<Img key={0}/>, <Txt key={1} learnMore={true}/>]*/}
      {/*  : [<Txt key={0}/>, <Img key={1}/>, <SectionEnd style={{marginTop:35, width:'100%'}} onLearnMore={onLearnMore}/>]*/}
      {/*}*/}
    </Grid>
  )
};

const Section3 = () => {
  const { scrollTo, set_scrollTo } = useContext(state.scrollTo);
  const width = useContext(state.size).size.width;

  const onLearnMore = () => set_scrollTo({
    ...scrollTo,
    to: ids.members_section_4
  });

  const p_style = width > mobile_size?{width:'100%'}:{width:'100%', maxWidth:280, margin:'auto'};
  const i_style = width > mobile_size?{}:{width:'100%', maxWidth:280};

  return (
    <Grid id={width > mobile_size?ids.members_section_3:undefined} container justify='center' alignItems="center" style={{width:'100%', maxWidth:1200, margin:'auto', marginTop:130}}>
      {/*<Grid container justify='center' style={{width: '100%', maxWidth:400}}>*/}
      {/*  <div style={{width:'100%', maxWidth:378}}>*/}
      {/*    <Header2 style={{marginBottom:20, fontSize: width > mobile_size?17:15}}>*/}
      {/*      YOUR DAILY BLOG*/}
      {/*    </Header2>*/}

      {/*    <Grid key={0} container justify="center" style={p_style}>*/}
      {/*      <Paragraph style={{textAlign:width > mobile_size?'left':'center'}} id={width > mobile_size?undefined:ids.members_section_3}>*/}
      {/*        You as a GOtrack member - will create your own profile, profile your driving services and make smart searches, .*/}
      {/*      </Paragraph>*/}
      {/*    </Grid>*/}

      {/*    <Grid key={1} container justify="center" style={p_style}>*/}
      {/*      <Paragraph style={{textAlign:width > mobile_size?'left':'center', marginBottom:50}}>*/}
      {/*        Don't forget to promote your blog on your Instagram page.*/}
      {/*      </Paragraph>*/}
      {/*    </Grid>*/}

      {/*    {width > mobile_size && <SectionEnd onLearnMore={onLearnMore}/>}*/}
      {/*  </div>*/}
      {/*</Grid>*/}
      {/*<img src={section3img1} alt="" style={i_style}/>*/}
      {/*{width <= mobile_size && <SectionEnd style={{width:'100%',marginTop:35}} onLearnMore={onLearnMore}/>}*/}
    </Grid>
  )
};

const Section4 = () => {
  const { scrollTo, set_scrollTo } = useContext(state.scrollTo);
  const width = useContext(state.size).size.width;

  const onLearnMore = () => set_scrollTo({
    ...scrollTo,
    to: ids.members_section_5
  });

  const img1_style = width > mobile_size?{}:{width:'100%', maxWidth:280};
  const img2_style = width > mobile_size?{}:{...img1_style, height:176};
  const p_style = width > mobile_size?{width:'100%'}:{width:'100%', maxWidth:280, margin:'auto'};

  return (
    <div id={width > mobile_size?ids.members_section_4:undefined} style={{marginTop:130}}>
      {/*<Header2 style={{marginBottom:0, fontSize: width > mobile_size?17:15}}>*/}
      {/*  CREATE{width <= mobile_size ? <br/> : " "}YOUR BOUTIQUE<br/>AND EARN MONEY*/}
      {/*</Header2>*/}
      {/*<Grid container justify="center" style={p_style}>*/}
      {/*  <Paragraph style={{paddingTop:20}} id={width > mobile_size?undefined:ids.members_section_4}>*/}
      {/*    While uploading your daily blog - don't forget to<br/>*/}
      {/*    attach the link for online purchase.*/}
      {/*  </Paragraph>*/}
      {/*</Grid>*/}
      {/*<Grid container justify="center" style={p_style}>*/}
      {/*  <Paragraph style={{marginBottom:50}}>*/}
      {/*    You will earn money for every purchase made<br/>*/}
      {/*    thanks to your recommendation*/}
      {/*  </Paragraph>*/}
      {/*</Grid>*/}
      {/*<Grid container justify='center' style={{width: '100%', marginBottom:50}}>*/}
      {/*  <img src={section4img1} style={{margin: 10, borderRadius:5, boxShadow: '0 1px 3px rgba(0, 0, 0, 0.5)', ...img1_style}} alt=""/>*/}
      {/*  <img src={section4img2} style={{margin: 10, borderRadius:5, boxShadow: '0 1px 3px rgba(0, 0, 0, 0.5)', ...img2_style}} alt=""/>*/}
      {/*</Grid>*/}
      {/*<SectionEnd onLearnMore={onLearnMore}/>*/}
    </div>
  )
};

const Section5 = () => {
  const width = useContext(state.size).size.width;

  return (
    <div id={ids.members_section_5} style={{marginTop:130, marginBottom: 260}}>
      {/*<Header2 style={{marginBottom:10, fontSize: width > mobile_size?18:16}}>*/}
      {/*  join BA<span style={{color:style_vars.colors.btn_red}}>NANO</span>Z now!*/}
      {/*</Header2>*/}
      {/*<Header2 style={{marginBottom:50, fontSize:  width > mobile_size?18:16}}>*/}
      {/*  start blogging and get promoted*/}
      {/*</Header2>*/}
      {/*<Paragraph style={{fontSize: 20}}>*/}
      {/*  and see your followers drastically<br/>*/}
      {/*  climbing in no-time, as you get<br/>*/}
      {/*  massive exposure.*/}
      {/*</Paragraph>*/}
      {/*<StartBlogging/>*/}
      {/*<Grid container justify='center'>*/}
      {/*  <Link*/}
      {/*    style={{fontSize:12, margin:'auto', marginTop:15, fontWeight:300, textDecoration: 'underline', color:style_vars.colors.small_text, transform:'scaleY(1.4)'}}*/}
      {/*    to={routes.home}*/}
      {/*  >*/}
      {/*    See most promoted posts*/}
      {/*  </Link>*/}
      {/*</Grid>*/}
    </div>
  )
};

export default () => {
  const { tutorial_mode, set_tutorial_mode } = useContext(state.tutorial_mode);

  useEffect(() => {
    // if (platform_profile.tutorial_mode) {
    set_tutorial_mode({
      ...tutorial_mode,
      // tip_influencers: true,
      tip_daily_page_upload_post_box: true,
      tip_new_post_promote_5_posts: true,
    });
    // }
  }, []);

  return (
    <div>
      <MembersHeader/>
      <Section2/>
      <Section3/>
      <Section4/>
      <Section5/>
    </div>
  )
};
