import React, {useEffect, useState} from 'react'
import {signinStyles} from '../../../assets/jss/signin'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


export default ({
  label,
  value,
  onChange,
  helper,
  validator,
  ...rest
}) => {
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error===null)
      setError('');
    else if (validator) {
      try {
        validator(value);
        setError('')
      } catch (e) {
        if (e.message.length > 30)
          setError(e.message.substr(0, 27)+'...');
        else
          setError(e.message);
      }
    }
  }, [value, validator]);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const classes = signinStyles();
  return (
    <div className={classes.inputBox} {...rest}>
      <input
        // autoComplete="off"
        type={showPass ? 'text' : 'password'}
        name={label.split(' ').join('-')}
        required=""
        className={(error?classes.invalidCmpInput:classes.inputBoxInput)+" "+classes.inputPassword}
        value={value}
        onChange={onChange}
      />
      <InputAdornment
        position="end"
        className={classes.InputAdornment}
      >
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {showPass ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
      <label className={error?classes.invalidCmpLabel:classes.inputBoxLabel}>
        {error?error:label}
      </label>
      {helper && helper}
    </div>
  );
};