import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  sharebtn: {
    marginLeft: 12,
    fontSize: 33,
    cursor: 'pointer',
    color: 'rgb(69, 69, 69)',
  },

  container: {
    position: 'absolute',
    padding: 15,
    maxWidth: 'calc(100vw - 30px)',
    display: 'flex',
    zIndex: 5000,
  },

  network: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center',
  },

  network_share_count: {
    display: 'inline-flex',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    width: '0',
    marginTop: '3px',
    fontSize: '12px',
  },

  network_share_button: {
    cursor: 'pointer',
    '&:hover:not(:active)': {
      opacity: '0.75',
    },
  },

  network_custom_icon: {
    width:'32px',
    height:'32px',
  },
});