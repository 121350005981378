import {verifyEmail, verifyLetters, verifyPassword, verifyUsername} from "./formValidation";

const error = {
  anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
  autoHideDuration: 4000,
  open: true,
  variant: "error",
};

export const set_error = (message:string) => ({...error, message});

export const email_validation = (email: string) => {
  if (!email)
    throw new Error('email is required');
  if (!verifyEmail(email))
    throw new Error('please enter a valid email');
};

export const password_validation = (password: string) => {
  if (!password)
    throw new Error('password is required');
  if (!verifyPassword(password))
    throw new Error('please enter a valid password with characters: A-Z|a-z|0-9|$-*');
  if (8 > password.length || password.length > 100)
    throw new Error('please enter an 8-100 character long password');
};

export const full_name_validation = (full_name: string) => {
  if (!full_name)
    throw new Error('full name is required');
  if (!verifyLetters(full_name))
    throw new Error('please enter a valid full name with characters: A-Z|a-z| |');
  if (1 > full_name.length || full_name.length > 128)
    throw new Error('please enter a 1-128 letter long full name');
};

export const profile_link_validation = (link: string) => {
  if (!link)
    throw new Error('Instagram Username is required');
  if (!verifyUsername(link))
    throw new Error('please enter a valid Instagram Username with characters: A-Z|a-z|0-9|._');
  if (1 > link.length || link.length > 30)
    throw new Error('please enter a 1-30 letter long Instagram Username');};

export const token_validation = (token: string) => {
  return !!token;
};

export const captcha_validation = (captcha: string) => {
  if (!captcha)
    throw new Error('Please Prove you are not a robot!');
};

export const confirm_password_validation = (confirmed) => {
  if (!confirmed)
    throw new Error('please make sure you re-type your password correctly');
};

export const confirm_email_validation = (confirmed) => {
  if (!confirmed)
    throw new Error('please make sure you re-type your email correctly');
};
