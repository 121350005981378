import React, {useState, useRef, useEffect} from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import {makeStyles} from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import sleep from '../../utils/sleep'
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles({
  Document: {
    maxWidth: '100%',
  },
  Page: {
    maxWidth: 'calc(~"100% - 2em")',
    margin: '1em',

    '& canvas': {
      maxWidth: '100%!important',
      height: 'auto !important',
    }
  },
});

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

export default ({file=null}) => {
  const classes = useStyles();
  const ref = useRef(null);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loaded, set_loaded] = useState(false);
  const [size, set_size] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    set_loaded(true);
  };

  const onDocumentLoadError = ({ message }) => {
    console.log(message);
    set_loaded(false);
  };

  useEffect(() => {(async ()=>{
    await sleep(500);
    if (numPages !== null) {
      const element = ref.current;
      if (element) {
        set_size({
          height: element.clientHeight,
          width: element.clientWidth,
        })
      }
    }
  })()
  }, [numPages]);

  if (file===null)
    return null;

  return (
    <div ref={ref} style={size===null?{}:size}>
      <Document
        className={classes.Document}
        file={file}
        options={options}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
      >
        <Page className={classes.Page} pageNumber={pageNumber} />
      </Document>
      {loaded &&
      <Grid container justify={'center'} alignItems={'center'}>
        <IconButton disabled={pageNumber===1} onClick={()=>setPageNumber(pageNumber-1)}>
          <ArrowLeftIcon/>
        </IconButton>
        <p style={{margin:0}}>Page {pageNumber} of {numPages}</p>
        <IconButton disabled={pageNumber===numPages} onClick={()=>setPageNumber(pageNumber+1)}>
          <ArrowRightIcon/>
        </IconButton>
      </Grid>
      }
    </div>
  );
}
