import React from 'react'
import {Page} from '../../wrappers/view_wrappers'
import PageHeader from '../../components/headers/PageHeader'
import PageHeaderBottom from '../../components/headers/PageHeaderBottom'
import PageHeaderBottomSlope from '../../components/headers/PageHeaderBottomSlope'
import SectionsLayout from '../../components/layout/SectionsLayout'
import HeadSection from '../../components/sections/HeadSection'
import OurServicesSection from '../../components/sections/OurServicesSection'

export default () => {
  return (
    <Page>
      <PageHeader title='GOTRACK' text='TRANSPORTATION MARKETPLACE'/>
      <PageHeaderBottomSlope/>
      <PageHeaderBottom/>
      <SectionsLayout>
        <HeadSection
          title={
            'Find the best Transportation ' +
            'Service for your Business'}
          text={
            'We facilitate transportation logistics in real-time, ' +
            'connecting quality drivers with vehicles, ' +
            'and shippers with quality drivers'
          }
        />
        <OurServicesSection/>
      </SectionsLayout>
    </Page>
  )
}

