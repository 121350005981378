import React from 'react'
import GradeIcon from '@material-ui/icons/Grade';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import beautifulNumber from '../../utils/beautifulNumber'
import {do_nothing_function} from'../../utils/do_nothing_function'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles({
  root: props => ({width:props.active?48:24, height:props.active?48:31}),
  icon: {width:24, height: 24},
  value: props => (props.active?{width:24, height: 24, paddingTop: 2}:{}),
  text: {minHeight: 7, minWidth:7, lineHeight: '10px'},
});

const YellowButton = withStyles({
  root: {
    color: 'rgb(249,180,3)',
    '&:hover': {
      color: 'rgb(218,158,3)',
    },
  },
})(IconButton);

const GreyButton = withStyles({
  root: {
    color: '#aaaaaa',
    '&:hover': {
      color: '#8e8e8e',
    },
  },
})(IconButton);


export default ({stars, votes, onClick=do_nothing_function, ...props}) => {
  const active = !(!votes || !stars);
  const classes = useStyles({active});
  const Btn = active ? YellowButton : GreyButton;
  return (
    <Grid container justify='center' className={classes.root} {...props}>
      <Btn className={classes.icon} onClick={onClick} size={'medium'} {...props}>
        <GradeIcon color={'inherit'} fontSize={'inherit'}/>
      </Btn>
      <span className={classes.value}>{active ? (Math.round((stars + Number.EPSILON) * 10) / 10).toFixed(1) : ''}</span>
      <small className={classes.text}>{active ? '('+beautifulNumber(votes)+')' : ''}</small>
    </Grid>
  )
}