import React, {useEffect, useState} from "react";
import Slide from '@material-ui/core/Slide';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close'
import EditLicense from './EditLicense'
import Grid from "@material-ui/core/Grid";
import Tooltip from "../../../Tooltip";
import license_icon from '../../../../assets/images/icons/license_icon.svg'
import type {License as LicenseType, Function} from '../../../../types'
import useEdit from '../../../../hooks/useEdit'


interface Props {
  open: boolean,
  license: LicenseType,
  remove_license: Function,
  update_license: Function,
}

export default (props:Props) => {
  const open = props.open;
  const license = props.license;
  const remove_license = props.remove_license;
  const update_license = props.update_license;

  const {edit, edit_open, set_edit_open} = useEdit(false, 500);

  return edit
    ?
    <EditLicense
      open={edit_open}
      license={license}
      onCancel={()=>set_edit_open(false)}
      onSave={async (_, license) => {
        if (await update_license(_, license))
          set_edit_open(false);
      }}
    />
    :
    <div style={{marginBottom: 15}}>
      <Slide direction="right" timeout={1300} in={open} mountOnEnter unmountOnExit>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <Tooltip title={'edit your license'}>
              <img src={license_icon} alt={''} style={{width:32, height: 32, position:'relative', top:5}}/>
            </Tooltip>
          </Grid>
          <Grid item style={{flexGrow: 1}}>
            <TextField
              label={'license'}
              name={'license'}
              type='text'
              fullWidth
              value={license.type}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{display:'flex'}}>
                      <IconButton
                        size='small'
                        aria-label="increase input"
                        onClick={()=>{set_edit_open(true)}}
                      >
                        <EditIcon fontSize={'inherit'}/>
                      </IconButton>
                      <IconButton
                        size='small'
                        aria-label="increase input"
                        onClick={async () => {
                          if (await remove_license())
                            set_edit_open(false);
                        }}
                      >
                        <CloseIcon fontSize={'inherit'}/>
                      </IconButton>
                    </div>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Slide>
    </div>
};