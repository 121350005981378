// // import React from 'react';
// // import { makeStyles } from '@material-ui/core/styles';
// // import FormLabel from '@material-ui/core/FormLabel';
// // import FormControl from '@material-ui/core/FormControl';
// // import FormGroup from '@material-ui/core/FormGroup';
// // import FormControlLabel from '@material-ui/core/FormControlLabel';
// // import FormHelperText from '@material-ui/core/FormHelperText';
// // import Checkbox from '@material-ui/core/Checkbox';
// //
// // const useStyles = makeStyles(theme => ({
// //     formControl: {
// //         margin: theme.spacing(3),
// //     },
// // }));
// //
// // export default function RequiredEquipment() {
// //     const classes = useStyles();
// //     const [state, setState] = React.useState({
// //         "crane": false,
// //         "Binding chains": false,
// //         "electric/manual Jack": false,
// //         "Cooling case": false,
// //         "other": false,
// //     });
// //
// //     const handleChange = name => event => {
// //         setState({ ...state, [name]: event.target.checked });
// //     };
// //
// //     const keys = Object.keys(state);
// //     const error = keys.filter(v => v).length <= 1;
// //
// //     return (
// //         <FormControl required error={error} component="fieldset" className={classes.formControl}>
// //             <FormLabel component="legend">Cargo Type</FormLabel>
// //             <FormGroup>
// //                 {keys.map((key, index) => <FormControlLabel
// //                     control={<Checkbox checked={state[key]} onChange={handleChange(key)} value={key} />}
// //                     label={key}
// //                 />)}
// //             </FormGroup>
// //             {error?<FormHelperText>No Task Selected</FormHelperText>:null}
// //         </FormControl>
// //     );
// // }
//
// import React from 'react';
// import clsx from 'clsx';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import ListItemText from '@material-ui/core/ListItemText';
// import Select from '@material-ui/core/Select';
// import Checkbox from '@material-ui/core/Checkbox';
// import Chip from '@material-ui/core/Chip';
//
// const useStyles = makeStyles(theme => ({
//     root: {
//         display: 'flex',
//         flexWrap: 'wrap',
//     },
//     formControl: {
//         margin: theme.spacing(1),
//         width: 300,
//     },
//     chips: {
//         display: 'flex',
//         flexWrap: 'wrap',
//     },
//     chip: {
//         margin: 2,
//     },
//     noLabel: {
//         marginTop: theme.spacing(3),
//     },
// }));
//
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };
//
// const names = [
//     "crane",
//     "Binding chains",
//     "electric/manual Jack",
//     "Cooling case",
//     "other",
// ];
//
// function getStyles(name, personName, theme) {
//     return {
//         fontWeight:
//             personName.indexOf(name) === -1
//                 ? theme.typography.fontWeightRegular
//                 : theme.typography.fontWeightMedium,
//     };
// }
//
// export default function MultipleSelect() {
//     const classes = useStyles();
//     const theme = useTheme();
//     const [personName, setPersonName] = React.useState([]);
//
//     const handleChange = event => {
//         setPersonName(event.target.value);
//     };
//
//     const handleChangeMultiple = event => {
//         const { options } = event.target;
//         const value = [];
//         for (let i = 0, l = options.length; i < l; i += 1) {
//             if (options[i].selected) {
//                 value.push(options[i].value);
//             }
//         }
//         setPersonName(value);
//     };
//
//     return (
//         <div className={classes.root}>
//             <FormControl className={classes.formControl}>
//                 <InputLabel htmlFor="select-multiple-chip">Required Equipment</InputLabel>
//                 <Select
//                     multiple
//                     value={personName}
//                     onChange={handleChange}
//                     input={<Input id="select-multiple-chip" />}
//                     renderValue={selected => (
//                         <div className={classes.chips}>
//                             {selected.map(value => (
//                                 <Chip key={value} label={value} className={classes.chip} />
//                             ))}
//                         </div>
//                     )}
//                     MenuProps={MenuProps}
//                 >
//                     {names.map(name => (
//                         <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
//                             {name}
//                         </MenuItem>
//                     ))}
//                 </Select>
//             </FormControl>
//         </div>
//     );
// }

import React from 'react';
import MultiSelect from "@khanacademy/react-multi-select";
import InputLabel from '@material-ui/core/InputLabel';

const options = [
    "crane",
    "Binding chains",
    "electric/manual Jack",
    "Cooling case",
    "other",
].map(item=>({label: item, value: item}));

export default class Consumer extends React.Component {
    state = {
        selected: [],
    };

    render() {
        const {selected} = this.state;

        return <div style={{maxWidth:'400px', marginBottom:'80px'}}>
            <div style={{marginBottom:'7px'}}><InputLabel htmlFor="select-multiple-chip">Required Equipment</InputLabel></div>
            <MultiSelect
                options={options}
                selected={selected}
                onSelectedChanged={selected => this.setState({selected})}
            />
        </div>
    }
}
