import React, {useEffect, useState} from "react";
import Slide from '@material-ui/core/Slide';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Tooltip from "../../../Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core";
import StarsIcon from '@material-ui/icons/Stars';

export const useStyles = makeStyles(theme => ({
  close: {maxHeight: 0},
  open: {maxHeight: 10000},

  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    // maxWidth: 300,
    // width:'100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default ({open, skills={}, set_skills}) => {
  const classes = useStyles();

  const [skill_list, set_skill_list] = useState([]);
  const [skill, set_skill] = useState('');

  useEffect(() => set_skill_list(Object.keys(skills)), [skills]);

  return (
    <Slide direction="right" timeout={1300} in={open} mountOnEnter unmountOnExit>
      <div style={{marginBottom: 15}}>
        <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 8}}>
          <Grid item>
            <Tooltip title={'add in special driving skills'}
            >
              <StarsIcon/>
            </Tooltip>
          </Grid>
          <Grid item style={{flexGrow: 1}}>
            <TextField
              fullWidth
              label={'special driving skills'}
              value={skill}
              onChange={event=>set_skill(event.target.value)}
              InputProps={{
                endAdornment: skill?(
                  <InputAdornment position="end">
                    <div style={{display:'flex'}}>
                      <IconButton
                        aria-label="add skill"
                        onClick={() => {
                          if (!skill_list.includes(skill)) {
                            const update = {...skills};
                            update[skill] = true;
                            set_skills(update);
                          }
                          set_skill('')
                        }}
                      >
                        <CheckIcon/>
                      </IconButton>
                      <IconButton
                        aria-label="cancel skill"
                        onClick={()=>set_skill('')}
                      >
                        <CloseIcon/>
                      </IconButton>
                    </div>

                  </InputAdornment>
                ):undefined
              }}
            />
          </Grid>
        </Grid>
        {skill_list.length ?
          <FormControl className={classes.formControl} style={{paddingLeft:32}}>
            <div className={classes.chips}>
              {skill_list.map((value, index) => (
                <Chip
                  key={index}
                  label={value}
                  className={classes.chip}
                  onDelete={()=>{
                    const update = {...skills};
                    delete update[value];
                    set_skills(update);
                  }}
                />
              ))}
            </div>
          </FormControl>:null
        }
      </div>
    </Slide>
  )
};
