import React, {useEffect, useState} from "react";
import {Formik, Form} from 'formik';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid';
import sleep from "../../../utils/sleep";
import ids from "../../../ids";
import type {User} from "../../../types";
import {useStyles} from './licences/styles'
import Slide from '@material-ui/core/Slide';
import Tooltip from '../../Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Permits from './extra_points/Permits'
import ID from './extra_points/ID'
import Skills from './extra_points/Skills'
import CV from './extra_points/CV'
import TrafficViolationRecord from './extra_points/TrafficViolationRecord'
import WorkHistory from './extra_points/WorkHistory'

export default ({template, onSuccess, open, set_formProps}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const user: User = template;

  const [loading, set_loading] = useState(false);
  const [errors, set_errors] = useState({});
  const [permits, set_permits] = useState(template.permits?template.permits:{});
  const [skills, set_skills] = useState(template.skills?template.skills:{});
  const [history, set_history] = useState(template.work_history?template.work_history:[]);

  const maxWidth = mobile ? 280 : 400;

  const onSubmit = async (values, {setSubmitting}) => {
    set_loading(true);
    await sleep(1000);
    const res = true;
    // const res = await edit_account_info({
    //   set_user,
    //   full_name: values.full_name,
    //   email: values.country,
    //   description: values.region,
    //   city: values.city,
    //   phone_number: values.phone,
    //   company: values.company,
    //   job: values.job,
    // });
    set_loading(false);
    // if (res) {
    //   set_user({...user, ...res});
    //   await sleep(1000);
    //   Swal.fire({
    //     type: 'success',
    //     title: 'Account Updated',
    //     text: 'Your account is up to date! happy GOtracking',
    //     confirmButtonText: 'OK',
    //     timer: 6000,
    //   });
    //   await sleep(800);
    // }
    setSubmitting(false);
    !!res && onSuccess();
  };

  return (
    <Formik
      initialValues={{}}
      validate={()=>({})}
      onSubmit={onSubmit}
    >{({submitForm, isSubmitting, values, setFieldValue, handleChange, handleBlur}) => {

      const classes = useStyles();

      useEffect(() => {
        set_formProps({submitForm, isSubmitting});
      }, [submitForm, isSubmitting]);

      useEffect(() => {
        const root = document.getElementById(ids.new_driver_profile_form_root);
        open
          ? root.className = classes.open
          : root.className = classes.close;
      }, [open]);

      return (
        <div id={ids.new_driver_profile_form_root} style={{width:'100%', transition: 'max-height 0.5s ease-in-out'}}>
          <Grid container justify='center'>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form style={{paddingTop: 10, width: maxWidth}}>

              <Grid container style={{marginBottom:20}}>
                {/*<Typography variant="h5" gutterBottom style={{marginBottom:0}}>*/}
                {/*  Extra Points*/}
                {/*</Typography>*/}
                <span style={{width:0}}>
                  <Grid container style={{height:'100%', marginLeft:5}} direction="row" alignItems="center">
                    <Tooltip title={
                      <p style={{paddingLeft:10, paddingRight:10, maxWidth:250, fontSize:14, fontWeight:300}}>
                        add in some information that can help us validate you and rank you higher
                      </p>
                    }>
                      <InfoIcon fontSize='small'/>
                    </Tooltip>
                  </Grid>
                </span>
              </Grid>

              <Slide direction="right" timeout={1200} in={open} mountOnEnter unmountOnExit>
                <div>


                  <ID open={open} extras={{}} set_extras={()=>{}}/>
                  <Permits open={open} permits={permits} set_permits={set_permits}/>
                  <WorkHistory open={open} history={history} set_history={set_history}/>
                  <Skills open={open} skills={skills} set_skills={set_skills}/>
                  <CV open={open} extras={{}} set_extras={()=>{}}/>
                  <TrafficViolationRecord open={open} extras={{}} set_extras={()=>{}}/>

                  <div style={{maxWidth:200}}>
                    {Object.keys(errors).map((error, index) =>
                      <div key={index} style={{color:'red', marginBottom:10, fontSize:12}}>
                        {errors[error]}
                      </div>
                    )}
                  </div>
                  {Object.keys(errors).length > 0 && <div style={{height:25}}/>}
                </div>
              </Slide>

            </Form>
            </MuiPickersUtilsProvider>
          </Grid>
        </div>
      )
    }}</Formik>
  )
};