import React, {useContext} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {colors} from '../../assets/jss/style_vars'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link';
import state from '../../state'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor:colors.primary,
    color:'white',
    minHeight:64,
  },

  root: {
    flexGrow: 1,
  },

  Grid: {
    maxWidth: 1200,
    margin:'auto',
    padding:20,
    paddingTop:40,
    paddingBottom:80,
  },

  item: {
    width: '50%',
    minWidth: 300,
    padding:20,
    '& h3': {
      color: '#949ca9',
    }
  },

  GridSocial: {
    paddingLeft: 7,
  },

  link: {
    color:'white',
    width:'100%',
    textAlign:'left',
    '&:hover': {
      color: 'rgba(255,255,255,0.65)',
    }
  }
}));

const CustomLink = ({onClick, children}) => {
  const classes = useStyles();
  return (
    <Link className={classes.link} component="button" variant="body2" onClick={onClick}>
      {children}
    </Link>
  )
};

export default () => {
  const spacing = 8;
  const classes = useStyles();
  const links = useContext(state.links);
  const {signin, set_signin} = useContext(state.signin);
  const is_logged: boolean = useContext(state.is_logged);

  const onSignin = () => set_signin({...signin, open: true});

  return (
    <div className={classes.footer}>
      <Grid container className={classes.Grid} >

        <Grid item className={classes.item}>
          <h3>Gotrack</h3>
          <CustomLink onClick={links.go_to_about}>About us</CustomLink>
          <CustomLink onClick={links.go_to_our_team}>Team</CustomLink>
          <CustomLink onClick={links.go_to_investors_relations}>Investors Relations</CustomLink>
          <CustomLink onClick={links.go_to_press}>press</CustomLink>
          <CustomLink onClick={links.go_to_privacy}>Privacy Policies</CustomLink>
          <CustomLink onClick={links.go_to_trust_and_safety}>Trust & Safety</CustomLink>
          <CustomLink onClick={links.go_to_terms}>Terms</CustomLink>
          <CustomLink onClick={links.go_to_help}>Help</CustomLink>
        </Grid>

        <Grid item className={classes.item}>
          <h3>Services</h3>
          <CustomLink onClick={links.go_to_driver_without_vehicle_service}>Driver without vehicle</CustomLink>
          <CustomLink onClick={links.go_to_driver_with_vehicle_service}>Driver with vehicle</CustomLink>
          <CustomLink onClick={links.go_to_vehicle_rent_and_share_service}>Vehicle for rent/share</CustomLink>
          <CustomLink onClick={links.go_to_collaboration_service}>Collaboration</CustomLink>
        </Grid>

        <Grid item className={classes.item}>
          <h3>Gotrackers/Freelancers</h3>
          {!is_logged && <CustomLink onClick={onSignin}>Sign in</CustomLink>}
          <CustomLink onClick={links.go_to_gotrackers_carriers}>Carriers</CustomLink>
          <CustomLink onClick={links.go_to_gotrackers_driver_without_vehicle}>Driver without vehicle</CustomLink>
          <CustomLink onClick={links.go_to_gotrackers_driver_with_vehicle}>Driver with vehicle</CustomLink>
          <CustomLink onClick={links.go_to_gotrackers_vehicle_owner}>Vehicle owner</CustomLink>
          <CustomLink onClick={links.go_to_gotrackers_list_yourself}>List yourself</CustomLink>
          <CustomLink onClick={links.go_to_gotrackers_how_it_works}>How it works</CustomLink>
          <CustomLink onClick={links.go_to_gotrackers_questions}>Questions</CustomLink>
          <CustomLink onClick={links.go_to_gotrackers_how_to_earn_money}>How to earn money</CustomLink>
          <CustomLink onClick={links.go_to_gotrackers_insurance_and_protection}>Insurance & protection</CustomLink>
          <CustomLink onClick={links.go_to_gotrackers_gold_Tracker_benefits}>Gold Tracker benefits</CustomLink>
          <CustomLink onClick={links.go_to_gotrackers_career_and_placement}>Career and placement</CustomLink>
        </Grid>

        <Grid item className={classes.item}>
          <h3>Shippers/Seekers</h3>
          {!is_logged && <CustomLink onClick={onSignin}>Sign in</CustomLink>}
          <CustomLink onClick={links.go_to_shippers_how_it_works}>How it works</CustomLink>
          <CustomLink onClick={links.go_to_shippers_questions}>Questions</CustomLink>
          <CustomLink onClick={links.go_to_shippers_insurance_and_protection}>Insurance & protection</CustomLink>
        </Grid>

        <Grid container className={classes.GridSocial}>
          <IconButton>
            <a
              data-baseweb="button"
              href="https://www.linkedin.com/company/gotrack-transportation-marketplace/"
              aria-label=""
              target="_self"
              text="LinkedIn"
              style={{color:'white'}}
            >
              <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"><title>linkedin</title>
                <path
                  d="M21.37 1H2.62C1.73 1 1 1.71 1 2.58v18.83c0 .88.73 1.59 1.62 1.59h18.75c.9 0 1.63-.71 1.63-1.59V2.58C23 1.71 22.27 1 21.37 1zM7.53 19.75H4.26V9.25h3.27v10.5zM5.89 7.81C4.85 7.81 4 6.96 4 5.92s.84-1.89 1.89-1.89c1.04 0 1.89.85 1.89 1.89.01 1.04-.84 1.89-1.89 1.89zm13.86 11.94h-3.26v-5.1c0-1.22-.02-2.78-1.7-2.78-1.7 0-1.96 1.33-1.96 2.7v5.19H9.57V9.26h3.13v1.43h.04c.44-.83 1.5-1.7 3.09-1.7 3.3 0 3.91 2.17 3.91 5v5.76h.01z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}