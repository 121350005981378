import React, {useState, useContext} from "react";
import {Formik, Form, Field} from 'formik';
import Grid from '@material-ui/core/Grid';
import email_icon from '../../../assets/images/icons/email.svg'
import email_confirm_icon from '../../../assets/images/icons/email_confirm.svg'
import GradientButton from '../../custom/CustomButtons/GradientButton'
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Tooltip from '../../Tooltip';
import {LinearProgress} from '@material-ui/core';
import {update_email} from '../../../api/auth/account'
import sleep from "../../../utils/sleep";
import state from '../../../state'
import Swal from 'sweetalert2';
import {Input} from '../../custom/CustomInput/FormikInput'
import LockIcon from '@material-ui/icons/Lock';
import {verifyPassword} from "../../../validations/formValidation";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

interface Values {
  email: string;
  email_confirm: string;
  password: string;
  news_letter: string;
}

const validate = values => {
  const errors: Partial<Values> = {};

  if (!values.password)
    errors.password = 'Required';
  else if (!verifyPassword(values.password))
    errors.password = 'Invalid password characters';

  if (!values.email)
    errors.email = 'Required';
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
    errors.email = 'Invalid email address';

  if (!values.email_confirm)
    errors.email_confirm = 'Required';
  else if (values.email_confirm!==values.email)
    errors.email_confirm = 'please re-type your email correctly';

  return errors;
};

export default ({set_loading}) => {
  const {entered_page} = useContext(state.entered_page);
  const {set_user, user} = useContext(state.user);
  const [show_pass, set_show_pass] = useState(false);

  const onSubmit = async (values, {setSubmitting}) => {
    set_loading(true);
    const res = await update_email({
      set_user,
      email: values.email,
      password: values.password,
      news_letter: values.news_letter,
    });
    set_loading(false);
    if (res) {
      set_user({...user, email: values.email, news_letter: values.news_letter});
      await sleep(1000);
      Swal.fire({
        type: 'success',
        title: 'Email Updated',
        text: 'Your email and news letter are up to date! Happy GOtracking!',
        confirmButtonText: 'OK',
        timer: 6000,
      });
      await sleep(800);
    }
    setSubmitting(false);
  };

  return (
    <div>
      <Formik
        initialValues={{
          email: user.email?user.email:'',
          email_confirm: '',
          news_letter: user.news_letter?user.news_letter:true,
          password: '',
        }}
        validate={validate}
        onSubmit={onSubmit}
        render={({submitForm, isSubmitting}) => {
          return (
            <Form>
              <Slide direction="right" timeout={1000} in={entered_page} mountOnEnter unmountOnExit>
                <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                  <Grid item>
                    <Tooltip title={'update your email address'}>
                      <img src={email_icon} alt="" style={{width: 20, height: 20, margin: 2}}/>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{flexGrow: 1}}>
                    <Field
                      component={Input}
                      label="email address"
                      name="email"
                      type="email"
                    />
                  </Grid>
                </Grid>
              </Slide>

              <Slide direction="right" timeout={1400} in={entered_page} mountOnEnter unmountOnExit>
                <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                  <Grid item>
                    <Tooltip title={'re-type your email to confirm the correct address'}>
                      <img src={email_confirm_icon} alt="" style={{width: 20, height: 20, margin: 2}}/>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{flexGrow: 1}}>
                    <Field
                      component={Input}
                      label="confirm email"
                      name="email_confirm"
                      type="email"
                    />
                  </Grid>
                </Grid>
              </Slide>

              <Slide direction="right" timeout={1400} in={entered_page} mountOnEnter unmountOnExit>
                <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 45}}>
                  <Grid item>
                    <Tooltip title={'please enter your password to access email change'}>
                      <LockIcon style={{color: 'rgb(86,135,231)'}}/>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{flexGrow: 1}}>
                    <Field
                      component={Input}
                      label="password"
                      name="password"
                      type={show_pass?"text":"password"}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => set_show_pass(!show_pass)}
                              onMouseDown={event => event.preventDefault()}
                            >
                              {show_pass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ,
                      }}
                    />
                  </Grid>
                </Grid>
              </Slide>

              <Grid container justify='center'>
                <Fade in={entered_page} timeout={1800}>
                  <GradientButton
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Update
                  </GradientButton>
                </Fade>
              </Grid>
              {isSubmitting && <LinearProgress style={{marginTop:20}}/>}
            </Form>
          )
        }}
      />
    </div>
  )
};