import React, {useState, Fragment} from 'react'
import {search_categories} from '../../variables'
import AppBarSearchBtn from '../search/AppBarSearchBtn'
import SearchDrawer from '../search/SearchDrawer'
import ignoreAction from "../../utils/ignoreAction";


export default function SearchAppBar() {
  const [openDrawer, set_openDrawer] = useState(false);
  const [search_category, set_search_category] = useState(search_categories[0]);

  const handleOpenDrawer = (event) => {if (ignoreAction(event)) return; set_openDrawer(true)};
  const handleCloseDrawer = (event) => {if (ignoreAction(event)) return; set_openDrawer(false)};
  const handleCategoryChange = (index:number) => set_search_category(search_categories[index]);

  return (
    <Fragment>
      <AppBarSearchBtn handleOpenDrawer={handleOpenDrawer}/>
      <SearchDrawer
        openDrawer={openDrawer}
        handleCloseDrawer={handleCloseDrawer}
        handleCategoryChange={handleCategoryChange}
        search_category={search_category}
      />
    </Fragment>
  );
}