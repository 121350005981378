import { makeStyles } from '@material-ui/core/styles'
import {blue} from "@material-ui/core/colors";

export const small_btn_style = {
  WebkitTapHighlightColor: 'transparent',
  textAlign: 'left',
  userSelect: 'none',
  WebkitBoxDirection: 'normal',
  cursor: 'pointer',
  margin: '0',
  border: '0',
  font: 'inherit',
  verticalAlign: 'baseline',
  borderRadius: '3px',
  flexShrink: '0',
  fontWeight: '600',
  padding: '5px 12px',
  textDecoration: 'none',
  textTransform: 'none',
};

export const big_btn_style = {
  WebkitFontSmoothing: 'antialiased',
  display: 'inline-flex',
  outline: '0',
  alignItems: 'center',
  userSelect: 'none',
  justifyContent: 'center',
  textDecoration: 'none',
  WebkitAppearance: 'none',
  WebkitTapHighlightColor: 'transparent',
  boxSizing: 'border-box',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  border: 'none',
  cursor: 'pointer',
  margin: '.3125rem 1px',
  padding: '12px 30px',
  position: 'relative',
  fontSize: '16px',
  minWidth: 'auto',
  minHeight: 'auto',
  textAlign: 'center',
  transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  fontWeight: '400',
  lineHeight: '1.42857143',
  whiteSpace: 'nowrap',
  willChange: 'box-shadow, transform',
  touchAction: 'manipulation',
  borderRadius: '3px',
  letterSpacing: '0',
  textTransform: 'none',
  verticalAlign: 'middle',
};

export const followStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: blue[100],
    '&:hover': {
      backgroundColor: blue[300],
    },
  },
  fabProgress: {
    color: blue[100],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default makeStyles({
  whiteButton: {
    boxShadow: 'none',
    border: 'solid 1px #dbdbdb',
    textAlign: 'center',
    margin: 0,
  },
  white: {
    WebkitBoxDirection: 'normal',
    fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
    fontSize: '14px',
    lineHeight: '18px',
    WebkitAppearance: 'none',
    background: '0 0',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'block',
    fontWeight: '600',
    padding: '5px 9px',
    textAlign: 'center',
    textTransform: 'inherit',
    textOverflow: 'ellipsis',
    userSelect: 'none',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    border: '1px solid rgba(219,219,219,1)',
    color: 'rgba(38,38,38,1)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.21)',
    }
  },
  blue: {
    WebkitTapHighlightColor: 'transparent',
    textAlign: 'left',
    userSelect: 'none',
    WebkitBoxDirection: 'normal',
    cursor: 'pointer',
    margin: '0',
    border: '0',
    font: 'inherit',
    verticalAlign: 'baseline',
    backgroundColor: '#3897f0',
    borderRadius: '3px',
    color: '#fff',
    flexShrink: '0',
    fontWeight: '600',
    padding: '5px 12px',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#286dad',
    }
  },
  learnMore: {
    cursor: 'pointer',
    textAlign:'center',
    marginTop: 0,
    color: '#999999',
    transition: '0.35s',
    '&:hover': {
      fontSize: 20,
      color: '#56a0e2',
    }
  }
});
