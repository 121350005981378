import request from 'axios'
import {remove_auth_from_local_storage} from '../utils/auth_handler'
import {url} from '../utils/urls'
import {remove_profile_from_local_storage, set_default_profile} from "../utils/profile_handler";
import Toast from "../../components/global/Toast";

export const handle_logout = (set_user) => {
  remove_auth_from_local_storage();
  remove_profile_from_local_storage();
  set_default_profile();
  set_user(null);
};

export const silent_logout = async (set_user) => {
  handle_logout(set_user);
  // try {window.FB.logout()} catch (e) {} // handle facebook logout
  //                                       // google has no cookies so no handler
  //                                       // instagram is suspended
  try {
    await request.post(url+'/token/remove');
    return true;
  } catch (e) {
    console.log(e);
    // return generic_logout_error(e);
    return false;
  }
};


export const user_logout = async (set_user) => {
  Toast('info', 'logged out successfully');
  return await silent_logout(set_user);
};

export const error_logout = async (set_user) => {
  Toast('warning', 'session timed out');
  return await silent_logout(set_user);
};