import React, {useEffect, useState} from "react";
import Slide from '@material-ui/core/Slide';
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails} from '../licences/Expansion'
import type {Function} from "../../../../types";

export interface Props {
  open: boolean,
  history: Array<{
    company: string,
    job: string,
    started: Date,
    until: Date,
  }>,
  set_history: Function,
}

export default (props:Props) => {
  const open = props.open;
  const history  = props.history ;
  const set_history = props.set_history;
  const [is_open, set_is_open] = useState(false);

  useEffect(() => set_is_open(open), [open]);

  return (
    <Slide direction="right" timeout={1400} in={is_open} mountOnEnter unmountOnExit>
      <div style={{marginBottom: 15}}>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="work-history"
          >
            <Typography variant="h6" gutterBottom style={{fontWeight:400}}>Work History</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{marginLeft: 5, marginBottom:25}}>


            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

      </div>
    </Slide>
  )
};
