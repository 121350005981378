import React from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import useStyles from '../assets/jss/expandRotateStyles'

export default ({expanded, ...props}) => {
  const classes = useStyles();
  return (
      <ExpandMoreIcon
        className={clsx(
          classes.expand,
          {[classes.expandOpen]: expanded},
        )} {...props}
      />
  )
}
