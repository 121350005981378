import Swal from 'sweetalert2'
import get_filename from '../utils/get_filename'
import get_filextension from '../utils/get_filextension'

export type Size = {|
  unit: number,
  label: string,
|}

export const byte: Size = { unit: 1, label: 'bytes' };
export const KB: Size = { unit: 1024 * byte.unit, label: 'KB' };
export const MB: Size = { unit: 1024 * KB.unit, label: 'MB' };
export const GB: Size = { unit: 1024 * MB.unit, label: 'GB' };
export const TB: Size = { unit: 1024 * GB.unit, label: 'TB' };

export function validSize(file:File, size:Size, max_size: number) {
  const file_size = file.size/size.unit;
  if (file_size > max_size) {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: `File size cannot exceed ${max_size}${size.label}`,
      confirmButtonText: 'OK',
      // timer: 8000,
    });
    return false
  }
  return true
}

export const validFileExtensions = {
  photos: ["pdf", "jpg", "jpeg", "png"],
};

export function validExtension(file:File, extensions:Array) {
  const extension = get_filextension(file);
  if (extensions.includes(extension))
    return true;

  Swal.fire({
    type: 'error',
    title: 'Oops...',
    text: `File type '${extension}' is not supported. Please upload files in one of these formats: '${extensions.join(', ')}'`,
    confirmButtonText: 'OK',
    // timer: 8000,
  });
  return false
}

export const validPhotoExtension = (file:File) => validExtension(file, validFileExtensions.photos);

export const validSizebyte = (file:File, size:number) => validSize(file, byte, size);
export const validSizeKB = (file:File, size:number) => validSize(file, KB, size);
export const validSizeMB = (file:File, size:number) => validSize(file, MB, size);
export const validSizeGB = (file:File, size:number) => validSize(file, GB, size);
export const validSizeTB = (file:File, size:number) => validSize(file, TB, size);