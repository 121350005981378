import React, {useState, useMemo} from 'react'
import state from '../../state'
import { update_profile, save_profile_in_local_storage } from '../../api/utils/profile_handler'
import {
  default_snack,
  default_scroll_options,
  default_category_tab,
  default_search_dropdown,
} from '../../utils/create_default'
import useLinks from '../../hooks/useLinks'
import useLoadGoogleMaps from '../../hooks/useLoadGoogleMaps'

import ignoreAction from '../../utils/ignoreAction'
import {search_categories} from "../../variables";

export default ({children}) => {
  const [user, set_user] = useState(null);
  const [page_loading, set_page_loading] = useState(true);
  const [size, set_size] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [sidebar, set_sidebar] = useState(false);
  const [notifications, set_notifications] = useState([]);
  const [messages, set_messages] = useState([]);
  const [signin, set_signin] = useState({open: false});
  const [backdrop, set_backdrop] = useState(false);
  const [snackbar, set_snackbar] = useState(default_snack());
  const [scrollTo, set_scrollTo] = useState(default_scroll_options());
  const [share_buttons, set_share_buttons] = useState(false);
  const [category_tab, set_category_tab] = useState(default_category_tab());
  const [search_dropdown, set_search_dropdown] = useState(default_search_dropdown());
  const [spring_toast, set_spring_toast] = useState('');
  const [is_logged, set_is_logged] = useState(false);
  const [profile_complete, set_profile_complete] = useState(null);
  const [entered_page, set_entered_page] = useState(null);
  // const [search, set_search] = useState(default_search());
  const links = useLinks(set_entered_page, set_page_loading);
  const loaded_google_maps = useLoadGoogleMaps();

  const user_value = useMemo(() => ({ user, set_user: data=>{
    set_user(data);
    update_profile(data);
    save_profile_in_local_storage();
    if (data===null) {
      set_notifications([]);
      set_messages([]);
      set_is_logged(false);
      set_profile_complete(null)
    }
    else {
      set_is_logged(true);
      if (!data.username) {
        set_profile_complete(false)
      } else {
        set_profile_complete(true)
      }
    }
  }}), [user, set_user]);

  const snackbar_value = useMemo(() => ({ snackbar, set_snackbar }), [snackbar, set_snackbar]);
  const notifications_value = useMemo(() => ({ notifications, set_notifications }), [notifications, set_notifications]);
  const messages_value = useMemo(() => ({ messages, set_messages }), [messages, set_messages]);
  const size_value = useMemo(() => ({ size, set_size }), [size, set_size]);
  const sidebar_value = useMemo(() => ({ sidebar, set_sidebar }), [sidebar, set_sidebar]);
  const signin_value = useMemo(() => ({ signin, set_signin }), [signin, set_signin]);
  const page_loading_value = useMemo(() => ({ page_loading, set_page_loading }), [page_loading, set_page_loading]);
  const backdrop_value = useMemo(() => ({ backdrop, set_backdrop }), [backdrop, set_backdrop]);
  const scrollTo_value = useMemo(() => ({ scrollTo, set_scrollTo }), [scrollTo, set_scrollTo]);
  const share_buttons_value = useMemo(() => ({ share_buttons, set_share_buttons }), [share_buttons, set_share_buttons]);
  const category_tab_value = useMemo(() => ({ category_tab, set_category_tab }), [category_tab, set_category_tab]);
  const search_dropdown_value = useMemo(() => ({ search_dropdown, set_search_dropdown }), [search_dropdown, set_search_dropdown]);
  const spring_toast_value = useMemo(() => ({ spring_toast, set_spring_toast }), [spring_toast, set_spring_toast]);
  const is_logged_value = useMemo(() => (is_logged), [is_logged]);
  const profile_complete_value = useMemo(() => ({ profile_complete, set_profile_complete }), [profile_complete, set_profile_complete]);
  const entered_page_value = useMemo(() => ({ entered_page, set_entered_page }), [entered_page, set_entered_page]);
  const links_value = useMemo(() => links, [links]);
  const loaded_google_maps_value = useMemo(() => loaded_google_maps, [loaded_google_maps]);

  // const search_value = useMemo(() => ({
  //   ...search,
  //   set_search,
  //     handleOpenDrawer: (event) => {if (ignoreAction(event)) return; set_search({...search, openDrawer: true})},
  //     handleCloseDrawer: (event) => {if (ignoreAction(event)) return; set_search({...search, openDrawer: false})},
  //     handleCategoryChange: (index:number) => set_search({...search, search_category: search_categories[index]}),
  //     handleOpenSearchResults: () => set_search({...search, open_search_results: true}),
  //     handleCloseSearchResults: () => set_search({...search, open_search_results: false}),
  //     loading_search_results: search.open_search_results && search.search_results_options.length === 0,
  //     handleSearchInputChange: (event, new_value:string) => set_search({...search, search_inputValue: new_value}),
  //     handleSearchValueChange: (event, new_value:string) => set_search({...search, search_value: new_value}),
  //     handleSearchResultsOptions: (options:Array<Object>) => set_search({...search, search_results_options: options}),
  // }), [search, set_search]);

  return (
   <state.user.Provider value={user_value}>
   <state.snackbar.Provider value={snackbar_value}>
   <state.notifications.Provider value={notifications_value}>
   <state.messages.Provider value={messages_value}>
   <state.size.Provider value={size_value}>
   <state.sidebar.Provider value={sidebar_value}>
   <state.signin.Provider value={signin_value}>
   <state.page_loading.Provider value={page_loading_value}>
   <state.backdrop.Provider value={backdrop_value}>
   <state.scrollTo.Provider value={scrollTo_value}>
   <state.share_buttons.Provider value={share_buttons_value}>
   <state.search_dropdown.Provider value={search_dropdown_value}>
   <state.category_tab.Provider value={category_tab_value}>
   <state.spring_toast.Provider value={spring_toast_value}>
   <state.is_logged.Provider value={is_logged_value}>
   <state.profile_complete.Provider value={profile_complete_value}>
   <state.entered_page.Provider value={entered_page_value}>
   <state.links.Provider value={links_value}>
   <state.loaded_google_maps.Provider value={loaded_google_maps_value}>
     {/*<state.search.Provider value={search_value}>*/}
     {children}
     {/*</state.search.Provider>*/}
   </state.loaded_google_maps.Provider>
   </state.links.Provider>
   </state.entered_page.Provider>
   </state.profile_complete.Provider>
   </state.is_logged.Provider>
   </state.spring_toast.Provider>
   </state.category_tab.Provider>
   </state.search_dropdown.Provider>
   </state.share_buttons.Provider>
   </state.scrollTo.Provider>
   </state.backdrop.Provider>
   </state.page_loading.Provider>
   </state.signin.Provider>
   </state.sidebar.Provider>
   </state.size.Provider>
   </state.messages.Provider>
   </state.notifications.Provider>
   </state.snackbar.Provider>
   </state.user.Provider>
  )
}
