import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Searched from './Searched';
import {fade, makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom'
import route from '../../routes';
import ids from '../../ids';
import useAutocomplete from "@material-ui/lab/useAutocomplete/useAutocomplete";


const searchStyle = makeStyles(theme => {
  const width = 200;
  return {
    container: {
      width: width
    },
    label: {
      display: 'block',
    },
    input: {
      width: width,
    },
    listbox: {
      width: '100%',
      margin: 0,
      padding: 1,
      zIndex: 5000,
      position: 'absolute',
      listStyle: 'none',
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
      maxHeight: 200,
      color: '#586177',
      textAlign: 'left',
      transform: 'translateZ(0)',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      border: '1px solid rgba(0,0,0,.25)',
      '& li': {
        padding: 2,
      },
      '& li[data-focus="true"]': {
        backgroundColor: '#4a8df6',
        color: 'white',
        cursor: 'pointer',
        borderRadius: 5,
      },
      '& li:active': {
        backgroundColor: '#2977f5',
        color: 'white',
        borderRadius: 5,
      },
    },
    search: {
      transform: 'translateZ(0)',
      zIndex: 5000,
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: width,
      },
    },
}});

const categories = {
  "freight & transport": {},
  "public transport": {},
  "short-term transport": {},
  "driver placement": {},
  "vehicle placement": {},
  "collaborative transport": {},
};

for (const key of Object.keys(categories))
  categories[key].lowercase = key.toLowerCase();

const options = Object.keys(categories);
const options_lowercase = Array.from(
  Object.values(categories).map(
    category=>category.lowercase));

function group_filter(value:string) {
  value = value.toLowerCase();
  const selectable_options = Array
    .from(options_lowercase
    .filter(option => option.indexOf(value) !== -1));
  if (selectable_options)
    return selectable_options;
  return options;
}


export default () => {
  const ref = React.useRef(null);
  const history = useHistory();

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside); // Bind the event listener
    return () => {document.removeEventListener("mousedown", handleClickOutside)};  // Unbind the event listener on clean up
  });

  const [search, setSearch] = React.useState(Searched.value);
  const [filtered_options, setOptions] = React.useState(group_filter(search));
  const [popularTransports, setPopularTransports] = React.useState(false);

  const onUpdate = newValue => {Searched.value=newValue;setSearch(newValue);setOptions(group_filter(newValue))};

  const showPopularTransports = () => {setPopularTransports(true)};
  const hidePopularTransports = () => {setPopularTransports(false)};
  const togglePopularTransports = () => {setPopularTransports(!popularTransports)};

  const handleChange = event => {onUpdate(event.target.value)};
  const handleChangeValue = (event, newValue) => {onUpdate(newValue)};

  const handleClickOutside = event => {if (ref.current && !ref.current.contains(event.target))hidePopularTransports()};
  const handleKeyPress = (event, newValue) => {
    if(event.key === 'Enter') {
      if (popularTransports) {
        if (newValue)
          return newSearch(newValue);
        else if (search)
          return newSearch(search);
      }
      if (newValue) {
        return newSearch(newValue);
      } else if (search) {
        return newSearch(search);
      } else {
        togglePopularTransports();
      }
    } else {
      showPopularTransports();
    }
    if (newValue) {
      return onUpdate(newValue)
    }
  };

  const goSearch = event => {
    event.stopPropagation();
    if (search) {
      hidePopularTransports();
      history.push(route.search_transport.replace(':id', search));
    }
  };

  const newSearch = newValue => {
      hidePopularTransports();
      history.push(route.search_transport.replace(':id', newValue));
      onUpdate(newValue)
  };

  const {
    getRootProps,
    // getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    // groupedOptions,
  } = useAutocomplete({
    id: ids.search,
    freeSolo: true,
    options: options,
    onChange: handleChangeValue,
  });

  Searched.handleChange = onUpdate;
  Searched.hidePopularTransports = hidePopularTransports;

  const classes = searchStyle();

  return [
    <div ref={ref} onClick={togglePopularTransports} onKeyDown={handleKeyPress}>
      <div className={classes.search} {...getRootProps()}>
        <InputBase
          placeholder="find transport…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          {...getInputProps()}
          value={search}
          onChange={handleChange}
        />
        {popularTransports ? (
          <ul className={classes.listbox} {...getListboxProps()}>
            {filtered_options.map((option, index) => (
              <li
                {...getOptionProps({option, index})}
                onClick={()=>{newSearch(option)}}
                onKeyDown={event=>{handleKeyPress(event, option)}}
              >
                {option}
              </li>
            ))
            }
          </ul>
        ) : null}
        <div className={classes.searchIcon} onClick={goSearch}>
          <SearchIcon />
        </div>
      </div>
    </div>,

  ]
};
