import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import './arrow.css';

const styles = theme => ({
    arrow: {
        fontStyle: 'normal',
        fontFamily: 'Charlie Text,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Noto Sans,Ubuntu,Droid Sans,Helvetica Neue,sans-serif',
        fontWeight: 500,
        fontSize: '17px',
        lineHeight: '28px',
        color: '#6772e5',
        textAlign: 'center',
        // padding: '16px',
        // width: '100%',
        // height: '100%',
        transition: 'all 0.5s',
        cursor: 'pointer',
        // margin: '36px',
        // boxShadow: '0 10px 20px -8px rgba(0, 0, 0,.7)',
        display: 'inline-block',
        // textAlign: 'justify',
        position: 'relative',
        '&::after': {
            content: "'→'",
            fontSize: '22px',
            position: 'absolute',
            opacity: '1',
            top: '0px',
            right: '-20px',
            transition: '0.5s',
            display: 'inline-block',
        },
        '&:hover, &:focus': {
            color: '#1c3be1',
            /*padding-right: 24px;*/
            /*padding-left:8px;*/
        },
        '&:hover::after, &:focus::after': {
            // color: '#3b74e1',
            opacity: '1',
            right: '-30px',
        }
    }
});

class Arrow extends React.Component {
    render() {
        const {classes, children, ...other} = this.props;

        return <div className={classes.arrow+' moveArrow'} {...other}>
            {children?children:null}
        </div>
    }
}

Arrow.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Arrow);