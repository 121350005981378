import {useEffect, useContext} from 'react';
import {useLocation} from "react-router-dom";
import state from '../state'


// https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/hooks.md
export default () => {
    const {set_backdrop} = useContext(state.backdrop);
    const {set_entered_page} = useContext(state.entered_page);
    const location = useLocation();

    useEffect(() => {
        set_entered_page(true);
        window.scrollTo(0, 0);
        set_backdrop(false)
    }, [location]);
}