import {AxiosResponse as Response} from 'axios'
import {AuthType} from '../../types'
import {
  auth_headers,
  refresh_headers,
  Access_Expiration,
  Refresh_Expiration,
  X_Access_Token,
  X_Refresh_Token,
  // X_CSRF_TOKEN,
  Identity_ID,
  Refresh_ID,
} from './headers'


export const save_auth_in_local_storage = (response:Response):AuthType => {
  const identity_id = response.headers[Identity_ID];
  const refresh_id = response.headers[Refresh_ID];
  const access_expiration = parseInt(response.headers[Access_Expiration]) - 90;
  const refresh_expiration = parseInt(response.headers[Refresh_Expiration]) - 90;
  const access_expiration_date = new Date();
  const refresh_expiration_date = new Date(access_expiration_date.getTime());
  access_expiration_date.setSeconds(access_expiration_date.getSeconds() + access_expiration);
  refresh_expiration_date.setSeconds(refresh_expiration_date.getSeconds() + refresh_expiration);

  const access_token = response.headers[X_Access_Token];
  const refresh_token = response.headers[X_Refresh_Token];

  localStorage.setItem('access_expiration', access_expiration);
  localStorage.setItem('refresh_expiration', refresh_expiration);
  localStorage.setItem('access_expiration_date', access_expiration_date);
  localStorage.setItem('refresh_expiration_date', refresh_expiration_date);
  localStorage.setItem('access_token', access_token);
  localStorage.setItem('refresh_token', refresh_token);
  localStorage.setItem('identity_id', identity_id);
  localStorage.setItem('refresh_id', refresh_id);

  auth_headers[X_Access_Token] = access_token;
  auth_headers[Identity_ID] = identity_id;
  refresh_headers[X_Access_Token] = refresh_token;
  refresh_headers[Identity_ID] = refresh_id;
  return {
    refresh_id,
    identity_id,
    access_token,
    refresh_token,
    access_expiration,
    refresh_expiration,
    access_expiration_date,
    refresh_expiration_date,
  }
};

export const get_auth_from_local_storage = ():AuthType => {
  try {
    const auth: AuthType = {};
    auth.refresh_id = localStorage.getItem('refresh_id');
    auth.identity_id = localStorage.getItem('identity_id');
    auth.access_token = localStorage.getItem('access_token');
    auth.refresh_token = localStorage.getItem('refresh_token');
    auth.access_expiration = parseInt(localStorage.getItem('access_expiration'));
    auth.refresh_expiration = parseInt(localStorage.getItem('refresh_expiration'));
    auth.access_expiration_date = new Date(localStorage.getItem('access_expiration_date'));
    auth.refresh_expiration_date = new Date(localStorage.getItem('refresh_expiration_date'));
    auth_headers[Identity_ID] = auth.identity_id;
    auth_headers[X_Access_Token] = auth.access_token;
    refresh_headers[Identity_ID] = auth.refresh_id;
    refresh_headers[X_Access_Token] = auth.refresh_token;
    if (
      auth.refresh_id &&
      auth.identity_id &&
      auth.access_token &&
      auth.refresh_token &&
      auth.access_expiration &&
      auth.refresh_expiration &&
      auth.access_expiration_date &&
      auth.refresh_expiration_date
    )
      return auth
  } catch(e) {}
};

export const remove_auth_from_local_storage = () => {
  try{localStorage.removeItem('refresh_id')}catch(e){}
  try{localStorage.removeItem('identity_id')}catch(e){}
  try{localStorage.removeItem('access_token')}catch(e){}
  try{localStorage.removeItem('refresh_token')}catch(e){}
  try{localStorage.removeItem('access_expiration')}catch(e){}
  try{localStorage.removeItem('refresh_expiration')}catch(e){}
  try{localStorage.removeItem('access_expiration_date')}catch(e){}
  try{localStorage.removeItem('refresh_expiration_date')}catch(e){}
};

export const auth_error = e => e.response && (e.response.status === 401 || e.response.status === 403);