import React from 'react'
import useStyles from '../../assets/jss/termStyles'
import {company, website, app_name} from "../../variables";


export default function () {
  const classes = useStyles();
  return (
      <div className={classes.termContainer}>

        <div className={classes.termBox}>
          <h1 className={classes.termTitle}>Terms of Service</h1>
          <p><span className={classes.termText}>Last updated: April 16 2019</span></p>
          <p><span className={classes.termText}>Company: {company}. </span></p>
          <p><span className={classes.termText}>Website: </span><span className={classes.termText}>{website}</span></p>
          <p><span className={classes.termText}>Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the {website} website (the "Service") operated by {company} Ltd ("us", "we", or "our").</span></p>
          <p><span className={classes.termText}>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</span></p>
          <p><span className={classes.termText}>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</span></p>
          <p><span className={classes.termBold}>Basic Terms</span></p>
          <ol>
            <li className={classes.termText}><span className={classes.termText}>You must be 16 years or older to use this site.</span></li>
            <li className={classes.termText}><span className={classes.termText}>You may not post nude, partially nude, or sexually suggestive photos.</span></li>
            <li className={classes.termText}><span className={classes.termText}>You are responsible for any activity that occurs under your screen name.</span></li>
            <li className={classes.termText}><span className={classes.termText}>You are responsible for keeping your password secure.</span></li>
            <li className={classes.termText}><span className={classes.termText}>You must not crawl, scrape, or otherwise cache any content from {app_name} including but not limited to user profiles and photos.</span></li>
            <li className={classes.termText}><span className={classes.termText}>You must not create or submit unwanted email or comments to any {app_name} members ("Spam").</span></li>
          </ol>

          <br/>
          <p><span className={classes.termBold}>Creating a fashion picks:</span></p>
          <ol>
            <li className={classes.termText}>
              <span className={classes.termText}>We give you your own, free </span>
              <span className={classes.termText}>zone</span>
              <span className={classes.termText}> where you can post your favorite fashion items and make a commission from selling them. </span>
            </li>
            <li className={classes.termText}>
              <span className={classes.termText}>No risk. No inventory. No investment. All you have to do is choose and show off your picks on your zone through your fashion picks. We will help you to get followers and shoppers! </span>
            </li>
          </ol>
          <p><span className={classes.termBold}>Earning Money</span></p>
            <ol>
              <li className={classes.termText}><span className={classes.termText}>In order to start receiving money you have to pass a minimum of 100$ total in commissions.</span></li>
              <li className={classes.termText}><span className={classes.termText}>All the commissions you make, starting with the first dollar, are kept in your online wallet on {app_name}. You start receiving the money in your wallet when the total amount of money in your account reaches 100$ (You'll receive every dollar made since your first dollar). </span></li>
              <li className={classes.termText}><span className={classes.termText}>According to the agreements, it takes 30-90 days for {app_name} to receive the commission from the leading online fashion shops.</span></li>
              <li className={classes.termText}><span className={classes.termText}>{app_name} functions on a back to back payment principle: Once {app_name} receives the commissions from the sales, the Stylists' portion (50% of the commission) will be transferred to her PAYPAL account on the 10th of the following month.</span></li>
              <li className={classes.termText}><span className={classes.termText}>The Stylists will be automatically informed about any sale related to her immediately after {app_name} is informed about the sale by the shop.</span></li>
              <li className={classes.termText}><span className={classes.termText}>Every Stylists is required to have her own PAYPAL account.</span></li>
              <li className={classes.termText}><span className={classes.termText}>All the money she will receive will be transferred to her PAYPAL account.</span></li>
              <li className={classes.termText}><span className={classes.termText}>Through PAYPAL, she will be able to withdraw her money at her convenience.</span></li>
              <li className={classes.termText}><span className={classes.termText}>You will receive a report about any activity related to the money you've earned.</span></li>
              <li className={classes.termText}><span className={classes.termText}>You'll immediately get a notification when {app_name} is informed about a purchase made as a result of your recommendation. The notification will include:</span></li>
              <ol>
                <li className={classes.termText}><span className={classes.termText}>The name of the online shop the item was purchased from.</span></li>
                <li className={classes.termText}><span className={classes.termText}>The amount of money paid for the item</span></li>
                <li className={classes.termText}><span className={classes.termText}>Your portion of the sale.</span></li>
              </ol>
            </ol>

          <br/>
          <ul>
            <li><strong>
              Note: Since we just launched and we are still consolidating our affiliate marketing
              agreements, in the meantime, {app_name} is paying out your commission based on a formula which estimates
              the amount due to you each time someone makes a purchase from your {app_name} zone.
            </strong></li>
          </ul>

          <br/>
          <p><span className={classes.termBold}>Content</span></p>
          <p><span className={classes.termText}>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness.</span></p>
          <p>
            <span className={classes.termText}>By posting Content to the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights. You agree that this license includes the right for us to make your Content available to other users</span>
            <span className={classes.termText}> of the Service, who </span>
            <span className={classes.termText}>may also use your Content subject to these Terms.</span>
          </p>
          <p><span className={classes.termText}>You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.</span></p>
          <p><span className={classes.termText}>Accounts</span></p>
          <p><span className={classes.termText}>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</span></p>
          <p><span className={classes.termText}>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.</span></p>
          <p><span className={classes.termText}>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</span></p>
          <p><span className={classes.termText}>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trade mark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene. You expressly agree that we cannot be held liable for any loss or damage arising out of any misrepresentations you make in this regard.</span></p>

          <h3><span className={classes.termBold}>Intellectual Property</span></h3>
          <p><span className={classes.termText}>The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of {app_name} and its licensors. The Service is protected by copyright, trademark, and other laws of both the United Kingdom and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of {app_name}.</span></p>
          <p><span className={classes.termText}>When you upload content, you give {app_name} a worldwide, non-exclusive, royalty-free, transferable license (with right to sub-license) to use, reproduce, distribute, prepare derivative works of, display, and perform that Content in connection with the provision of the Service and otherwise in connection with the provision of the Service and {app_name} business.</span></p>

          <h3><span className={classes.termBold}>Links to Other Websites</span></h3>
          <p><span className={classes.termText}>Our Service may contain links to third-party websites or services that are not owned or controlled by {app_name}.</span></p>
          <p><span className={classes.termText}>{app_name} has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites or services. You further acknowledge and agree that {app_name} shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.</span></p>
          <p><span className={classes.termText}>We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.</span></p>
          <p><span className={classes.termText}>Termination</span></p>
          <p><span className={classes.termText}>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</span></p>
          <p><span className={classes.termText}>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</span></p>
          <p><span className={classes.termText}>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</span></p>

          <h3><span className={classes.termBold}>Indemnification</span></h3>
          <p><span className={classes.termText}>You agree to defend, indemnify and hold harmless {app_name} and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password; b) a breach of these Terms, or c) Content posted on the Service.</span></p>

          <h3><span className={classes.termBold}>Limitation of Liability</span></h3>
          <p><span className={classes.termText}>In no event shall {app_name}, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</span></p>

          <br/>
          <h3><span className={classes.termBold}>Disclaimer</span></h3>
          <p><span className={classes.termText}>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</span></p>
          <p><span className={classes.termText}>{app_name}, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</span></p>

          <h3><span className={classes.termBold}>Exclusions</span></h3>
          <p><span className={classes.termText}>Without limiting the generality of the foregoing and notwithstanding any other provision of these terms, under no circumstances will {app_name} ever be liable to you or any other person for any indirect, incidental, consequential, special, punitive or exemplary loss or damage arising from, connected with, or relating to your use of the Service, these Terms, the subject matter of these Terms, the termination of these Terms or otherwise, including but not limited to personal injury, loss of data, business, markets, savings, income, profits, use, production, reputation or goodwill, anticipated or otherwise, or economic loss, under any theory of liability (whether in contract, tort, strict liability or any other theory or law or equity), regardless of any negligence or other fault or wrongdoing (including without limitation gross negligence and fundamental breach) by {app_name} or any person for whom {app_name} is responsible, and even if {app_name} has been advised of the possibility of such loss or damage.</span></p>

          <br/>
          <p><span className={classes.termBold}>Governing Law</span></p>
          <p><span className={classes.termText}>These Terms shall be governed and construed in accordance with the laws of Israel, without regard to its conflict of law provisions.</span></p>
          <p><span className={classes.termText}>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</span></p>
          <p><span className={classes.termText}>Changes</span></p>
          <p><span className={classes.termText}>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 15 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</span></p>
          <p><span className={classes.termText}>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you must stop using the service.</span></p>
          <p><span className={classes.termText}>Privacy Policy and Cookie Policy</span></p>
          <p><span className={classes.termText}>Please refer to our Privacy Policy and Cookies Policy. You agree that they constitute part of these terms. You must read our Privacy Policy and Cookies Policy before you use the Service.</span></p>
          <p><span className={classes.termText}>Contact Us</span></p>
          <p><span className={classes.termText}>If you have any questions about these Terms, please contact us.</span></p>
          <h2>
            <br/>
          </h2>
        </div>
      </div>
  );
}