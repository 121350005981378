import React, {useContext, useEffect, useState} from "react";
import {register_verify} from '../../api/public/register'
import state from '../../state'
import routes from '../../routes'
import CircularIndeterminate from '../../components/loaders/CircularIndeterminate'
import Grid from '@material-ui/core/Grid';
import {useHistory} from 'react-router-dom'

export default ({match}) => {
  const email = match.params.email;
  const token = match.params.token;
  const history = useHistory();

  const {set_user} = useContext(state.user);
  const {set_snackbar} = useContext(state.snackbar);

  const [loading, set_loading] = useState(true);
  const [msg, set_msg] = useState('user validation in progress');


  useEffect(() => {
    const onLoad = async () => {
      const valid_user = await register_verify(email, token, set_user, set_snackbar);
      if (valid_user) {
        history.push(routes.my_account);
      }
      else
        set_msg('validation failed. please try to sign up again later ☹');
      set_loading(false)
    };
    onLoad()
  }, []);

  return (
    <Grid container justify="center">
      <Grid item>
        {loading && <CircularIndeterminate/>}
        <h6 style={{
          fontSize: '1.25rem',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: '500',
          lineHeight: '1.6',
          letterSpacing: '0.0075em',
        }}>
          {msg}
        </h6>
      </Grid>
    </Grid>
  );
}
