import React, { useEffect } from "react";
import TagManager from 'react-gtm-module'
// import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga";

ReactGA.initialize("UA-163373723-1");
const dataLayerName = 'PageDataLayer';

TagManager.initialize({
  gtmId: 'GTM-MTF9L2X',
  dataLayerName,
});
// ReactPixel.init('');

// export {ReactPixel}

export default (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
    const tagManagerArgs = {
      dataLayer: {
        userId: '001',
        userProject: 'project',
        page,
      },
      dataLayerName,
    };
    TagManager.dataLayer(tagManagerArgs)
    // ReactPixel.pageView();
  };

  const HOC = props => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname
    ]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};