import React from 'react';
import SizeAndWeight from './FreightDetails/SizeAndWeight';
import CargoType from './FreightDetails/CargoType';
import PackingType from './FreightDetails/PackingType';
import Procedure from './FreightDetails/Procedure';
import RequiredEquipment from './FreightDetails/RequiredEquipment';
import RequiredVehicle from './FreightDetails/RequiredVehicle';
import Grid from '@material-ui/core/Grid';

export default function FreightDetails() {
    return (
        <div>
            <Grid container >
                <Grid item xs={12} sm={12} md={6}>
                    <CargoType/>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <SizeAndWeight/>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <PackingType/>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Procedure/>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <RequiredEquipment/>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <RequiredVehicle/>
                </Grid>
            </Grid>
        </div>
    );
}