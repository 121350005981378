import React, {useEffect, useState} from "react";
import {Formik, Form} from 'formik';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid';
import sleep from "../../../utils/sleep";
import ids from "../../../ids";
import type {User} from "../../../types";
import {validate} from './services_and_pricing/services/validate'
import {useStyles} from './services_and_pricing/services/styles'
import Services from './services_and_pricing/services/Services'
import Tooltip from "../../Tooltip";
import InfoIcon from "@material-ui/icons/Info";
// import Orders from './orders/Orders'
// import Divider from '@material-ui/core/Divider'
// import Fade from '@material-ui/core/Fade'

export default ({template, onSuccess, open, set_formProps}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const user: User = template;

  const [loading, set_loading] = useState(false);
  const [errors, set_errors] = useState({});

  const maxWidth = mobile ? 280 : 400;

  const [onLoad, set_onLoad] = useState(true);

  const [services, set_services] = useState(
    template && template.services
      ? template.services
      : {}
      );

  const [last_service, set_last_service] = useState(null);

  const validate_services = ():string => {
    const new_errors = validate({services});
    set_errors(new_errors);
    return !new_errors.services;
  };

  const onSubmit = async (values, {setSubmitting}) => {

    if (!validate_services()) {
      setSubmitting(false);
      return;
    }

    set_loading(true);
    await sleep(1000);
    const res = true;
    // const res = await edit_account_info({
    //   set_user,
    //   full_name: values.full_name,
    //   email: values.country,
    //   description: values.region,
    //   city: values.city,
    //   phone_number: values.phone,
    //   company: values.company,
    //   job: values.job,
    // });
    set_loading(false);
    // if (res) {
    //   set_user({...user, ...res});
    //   await sleep(1000);
    //   Swal.fire({
    //     type: 'success',
    //     title: 'Account Updated',
    //     text: 'Your account is up to date! happy GOtracking',
    //     confirmButtonText: 'OK',
    //     timer: 6000,
    //   });
    //   await sleep(800);
    // }
    setSubmitting(false);
    !!res && onSuccess();
  };

  useEffect(() => {
    if (onLoad)
      return set_onLoad(false);
    validate_services()
  }, [services]);

  useEffect(() => {
    const service_list = Object.keys(services);
    if (service_list.length > 0)
      set_last_service(services[service_list[service_list.length-1]]);
    else
      set_last_service(null);
  }, [services]);

  return (
    <Formik
      initialValues={{}}
      validate={()=>({})}
      onSubmit={onSubmit}
    >{({submitForm, isSubmitting, values, setFieldValue, handleChange, handleBlur}) => {

      const classes = useStyles();

      useEffect(() => {
        set_formProps({submitForm, isSubmitting});
      }, [submitForm, isSubmitting]);

      useEffect(() => {
        const root = document.getElementById(ids.new_driver_profile_form_root);
        open
          ? root.className = classes.open
          : root.className = classes.close;
      }, [open]);

      return (
        <div id={ids.new_driver_profile_form_root} style={{width:'100%', transition: 'max-height 0.5s ease-in-out'}}>
          <Grid container justify='center'>
            <Form style={{paddingTop: 10, width: maxWidth}}>

              <Grid container style={{marginBottom:20}}>
                {/*<Typography variant="h5" gutterBottom style={{marginBottom:0}}>*/}
                {/*  Services*/}
                {/*</Typography>*/}
                <span style={{width:0}}>
                  <Grid container style={{height:'100%', marginLeft:5}} direction="row" alignItems="center">
                    <Tooltip title={
                      <p style={{paddingLeft:10, paddingRight:10, maxWidth:250, fontSize:14, fontWeight:300}}>
                        define your<br/>
                        driving services<br/>
                        <br/>
                        set price ranges and<br/>
                        calculation methods<br/>
                        for your driving
                      </p>
                    }>
                      <InfoIcon fontSize='small'/>
                    </Tooltip>
                  </Grid>
                </span>
              </Grid>

              <Services
                open={open}
                services={services}
                set_services={set_services}
                last_service={last_service}
                errors={errors}
                set_errors={set_errors}
                validate_services={validate_services}
              />

              {/*<Fade in={open} timeout={2500}>*/}
              {/*  <Divider style={{*/}
              {/*    marginTop: 50,*/}
              {/*    marginBottom: 50,*/}
              {/*  }}/>*/}
              {/*</Fade>*/}

              {/*<Orders*/}
              {/*  open={open}*/}
              {/*  services={services}*/}
              {/*  set_services={set_services}*/}
              {/*  last_service={last_service}*/}
              {/*  errors={errors}*/}
              {/*  set_errors={set_errors}*/}
              {/*  validate_services={validate_services}*/}
              {/*/>*/}

            </Form>
          </Grid>
        </div>
      )
    }}</Formik>
  )
};