import {useEffect, useContext} from 'react'
import state from '../state'
import * as login_auth from "../api/public/login"

export default () => {
  const { user, set_user } = useContext(state.user);
  const { set_snackbar } = useContext(state.snackbar);
  const { page_loading, set_page_loading } = useContext(state.page_loading);

  // code to run on component mount
  useEffect(() => {(async () => {
    try {
      await login_auth.is_logged(user, set_user, set_snackbar);
    }
    catch (e) {
      console.log(e)
    }
    if (page_loading)
      set_page_loading(false);
  })()}, []);
}
