import React, {useContext, useEffect} from 'react'
import Button from '@material-ui/core/Button'
import ids from '../../ids'
import { withStyles } from '@material-ui/core/styles'
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'
import google_icon from '../../assets/images/icons/google.svg'
// import facebook_icon from '../../assets/images/icons/facebook.svg'
import {signinStyles} from '../../assets/jss/signin'
// import instagram_icon from '../assets/images/icons/instagram.svg'
// import InstagramLogin from 'react-instagram-login'
import {
  // login_facebook,
  login_google,
} from '../../api/public/login'
import state from '../../state'
import CircularProgress from "@material-ui/core/CircularProgress";
import {username_required} from '../../api/utils/response_msg'
import Swal from 'sweetalert2'

const GoogleCircularProgress = withStyles({
  root: {
    color: 'rgba(152,27,32,0.74)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -42,
    marginLeft: -137,
  },
})(CircularProgress);

// const FacebookCircularProgress = withStyles({
//   root: {
//     color: 'rgba(35,60,79,0.61)',
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     marginTop: -48,
//     marginLeft: -137,
//   },
// })(CircularProgress);

// export const FacebookButton = withStyles(theme => ({
//   root: {
//     color: theme.palette.getContrastText('#4c69ba'),
//     backgroundColor: '#4c69ba',
//     '&:hover': {
//       backgroundColor: '#3f5799',
//     },
//   },
// }))(Button);

export const GoogleButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText('#e76241'),
    backgroundColor: '#e76241',
    '&:hover': {
      backgroundColor: '#d04a30',
    },
  },
}))(Button);

// export const InstagramButton = withStyles(theme => ({
//   root: {
//     color: theme.palette.getContrastText('#414141'),
//     backgroundColor: '#414141',
//     '&:hover': {
//       backgroundColor: '#2b2b2b',
//     },
//   },
// }))(Button);

export const GoogleSignIn = ({onSuccess, onFailure, length}) =>
  <div id={ids.google_login}>
    <GoogleLogin
      clientId="747729141708-mqbdc5845dldua81jsbkchqndbpdhilc.apps.googleusercontent.com"
      render={renderProps =>
        <GoogleButton onClick={renderProps.onClick} disabled={renderProps.disabled} style={{width:length,height:40,marginBottom:5, marginTop:0, color:'#fff'}}>
          WITH GOOGLE
        </GoogleButton>
      }
      buttonText="Login"
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
    >
      <GoogleButton>
        continue with google
      </GoogleButton>
    </GoogleLogin>
    <div style={{position:'relative'}}>
      <img
        onClick={()=>{document.getElementById(ids.google_login).childNodes[0].click()}}
        src={google_icon}
        style={{cursor:'pointer', position:'absolute', top:-45,left:7.5, width:40,height:40}}
        alt=""
      />
    </div>
  </div>;

// Instagram is currently disabled
// export const InstagramSignIn = ({responseInstagram, length}) =>
// //   <div id={ids.instagram_login}>
// //     <InstagramLogin
// //       clientId="232445481254721"
// //       onSuccess={responseInstagram}
// //       onFailure={responseInstagram}
// //     >
// //       <InstagramButton style={{width:length,height:40,marginBottom:5}}>
// //         LOGIN WITH INSTAGRAM
// //       </InstagramButton>
// //     </InstagramLogin>
// //     <div style={{position:'relative'}}>
// //       <img
// //         onClick={()=>{document.getElementById(ids.instagram_login).childNodes[0].click()}}
// //         src={instagram_icon}
// //         style={{cursor:'pointer', position:'absolute', top:-37.5,left:7.5, width:25,height:25}}
// //         alt=""
// //       />
// //     </div>
// //   </div>;

// export const FacebookSignIn = ({responseFacebook, length}) =>
//   <div id={ids.facebook_login}>
//     <FacebookLogin
//       appId="504499810449934"
//       // autoLoad={true}
//       fields="full_name,email,picture"
//       render={renderProps =>
//         <FacebookButton onClick={renderProps.onClick} style={{paddingLeft:25, width:length,height:40,marginBottom:10}}>
//           LOGIN WITH FACEBOOK
//         </FacebookButton>
//       }
//       callback={responseFacebook}
//     >
//       login with google
//     </FacebookLogin>
//     <div style={{position:'relative'}}>
//       <img
//         onClick={()=>{document.getElementById(ids.facebook_login).childNodes[0].click()}}
//         src={facebook_icon}
//         style={{cursor:'pointer', position:'absolute', top:-42.5,left:7.5, width:25,height:25}}
//         alt=""
//       />
//     </div>
//   </div>;


export const Devider = () =>
  <div style={{position:'relative', height:15, width:'100%'}}>
    <div style={{flexShrink: 1, flexGrow: 1, backgroundColor: '#efefef', height:1,position:'relative',top:'.45em', width: '40%'}}/>
    <div style={{color:'#999', height:15,fontSize:13,fontWeight:600, margin:'auto', textAlign:'center'}}>OR</div>
    <div style={{flexShrink: 1, flexGrow: 1, backgroundColor: '#efefef', height:1,position:'absolute',top:'.45em', right:0,width: '40%'}}/>
  </div>;

// function validate_external_response(data:Object) {
//   for (let key in data) {
//     if (!(typeof data[key] === 'string' || data[key] instanceof String))
//       return false
//   }
// }

export default () => {
  const classes = signinStyles();
  const { set_snackbar } = useContext(state.snackbar);
  const { set_backdrop } = useContext(state.backdrop);
  const { set_user } = useContext(state.user);
  const width: number = useContext(state.size).size.width;
  let length = width < 450 ? 240 : 280;

  const [loading_google, setLoading_google] = React.useState(false);
  // const [loading_facebook, setLoading_facebook] = React.useState(false);

  // const responseFacebook = async (response) => {
  //   const data = {
  //     facebookId: response.userID,
  //     accessToken: response.accessToken,
  //     imageUrl: response.picture.data.url,
  //     email: response.email,
  //     full_name: response.full_name,
  //   };
  //   if (!validate_external_response(data)) {
  //     console.log(response);
  //     set_snackbar({
  //       anchorOrigin: {vertical: 'top', horizontal: 'right'},
  //       autoHideDuration: 8000,
  //       open: false,
  //       message: "Oops... Something went wrong, try again later",
  //       variant: "error",
  //     });
  //     return;
  //   }
  //
  //   setLoading_facebook(true);
  //
  //   const res = await login_facebook(data, set_user, set_snackbar);
  //   if (typeof res === 'boolean') {
  //     if (res) {
  //       handleClose();
  //     }
  //   } else if (res.msg === username_required) {
  //     set_signin({...signin, ...res})
  //   }
  //
  //   setLoading_facebook(false);
  // };

  const googleFailedResponse = async (response) => {
    console.log(response);
    Swal.fire(
      response.error,
      response.details,
      'error',
    )
  };

  const googleSuccessResponse = async (response) => {
    set_backdrop(true);
    await login_google(response, set_user, set_snackbar);
    set_backdrop(false);
  };

  return (
    <div>
      <div className={classes.inputBox} style={{width:length, margin:'auto', marginBottom:30}}>
        <GoogleSignIn onSuccess={googleSuccessResponse} onFailure={googleFailedResponse} length={length}/>
      </div>

      {/*/!*<div className={classes.inputBox} style={{width:length, margin:'auto', marginBottom:30}}>*!/*/}
      {/*/!*  /!*<InstagramSignIn responseInstagram={responseInstagram} length={length}/>*!/*!/*/}
      {/*/!*</div>*!/*/}

      {/*<div className={classes.inputBox} style={{width:length, margin:'auto', marginBottom:0}}>*/}
      {/*  <FacebookSignIn responseFacebook={responseFacebook} length={length}/>*/}
      {/*  {loading_facebook && <div style={{position:'relative'}}><FacebookCircularProgress size={32} /></div>}*/}
      {/*</div>*/}

      <div className={classes.inputBox} style={{width:length, margin:'auto', marginBottom:30}}>
        <Devider/>
      </div>
    </div>
  )
}