import React, {useContext, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import state from "../../state";
// import {category_tabs} from '../../variables'
// import {CategoryTab} from '../../types'
import style_vars from '../../assets/jss/style_vars'
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import {app_name} from '../../variables'
import sleep from '../../utils/sleep'
import Button from '@material-ui/core/Button'
import PageviewIcon from '@material-ui/icons/Pageview'
import {useLocation, useHistory} from 'react-router-dom'
import routes from '../../routes.js'
import Home from '@material-ui/icons/Home'
import Contact from '@material-ui/icons/ContactPhone'
import About from '@material-ui/icons/Info'
import AddIcon from '@material-ui/icons/AddAPhoto';
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import Privacy from '@material-ui/icons/Https'
import Terms from '@material-ui/icons/ListAlt'
import Promote from '@material-ui/icons/PostAdd'
import PerfectScrollbar from 'react-perfect-scrollbar'

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {

  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderBottom: 'none',
    margin: 0,
    minHeight: 88,
    '&$expanded': {
      minHeight: 88,
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  content: {
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiExpansionPanelDetails);


const TemporaryDrawerStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  ListItem: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 80,
    marginBottom:5,
  },
  ListItemText: {
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    lineHeight: "1.42857143",
    listStyle: "none",
    fontSize: "15px",
    textTransform: "uppercase",
    boxSizing: "border-box",
    fontFamily: "Martel Sans",
    fontWeight: 700,
  }
});

export default function DrawLeft() {
  const links = useContext(state.links);
  const classes = TemporaryDrawerStyles();
  const {sidebar, set_sidebar} = useContext(state.sidebar);
  const {category_tab, set_category_tab} = useContext(state.category_tab);
  const [expanded, setExpanded] = useState('panel1');
  const location = useLocation();
  const history = useHistory();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const toggleDrawer = event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    set_sidebar(!sidebar);
  };

  const onPageClick = async (route) => {
    history.push(route);
    await sleep(300);
    toggleDrawer({})
  };

  const Page = ({label, route, Icon, ...props}) =>
    <ListItem
      button
      onClick={() => onPageClick(route)}
      style={location.pathname === route
        ? {backgroundColor:style_vars.colors.btn_red, boxShadow: '0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)'} : {}
      }
      className={classes.ListItem}
      {...props}
    >
      <ListItemIcon><Icon style={location.pathname === route ? {color: '#fff'} : {color:'#666'}}/></ListItemIcon>
      <ListItemText
        primary={label}
        className={classes.ListItemText}
        style={location.pathname === route ? {color: '#fff'} : {color:'#666'}}
      />
    </ListItem>;

  return (
    <Drawer open={sidebar} onClose={toggleDrawer}>
      <PerfectScrollbar options={{wheelSpeed:0.35}}>
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <Grid container justify='center' style={{paddingBottom:20, paddingTop:30, cursor:'pointer'}} onClick={links.go_home}>
            {/*<div style={{width:0, height:40}}>*/}
            {/*  <div style={{position:'relative'}}>*/}
            {/*    <img src={logo} alt="" style={{position:'absolute', top: -50, right: 17, height:120, width:60}}/>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div style={{fontSize:'1.3rem'}}>{app_name}</div>
          </Grid>

          <Divider/>

          <ExpansionPanel
            square
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            onClick={event=>{event.stopPropagation()}}
          >
            <ExpansionPanelSummary aria-controls="panel1d-content">
              <Button>
                <List>
                  <ListItem>
                    <ListItemIcon><PageviewIcon /></ListItemIcon>
                    <ListItemText primary={"PAGES"} style={{marginRight:60}}/>
                  </ListItem>
                </List>
              </Button>
              <Divider />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List style={{paddingLeft:10, paddingRight:10, width:'100%'}}>
                <Page label={'HOME'} Icon={Home} route={routes.home}/>
                <Page label={'ORDERS'} Icon={Promote} route={routes.promote}/>
                <Page label={'SERVICES'} Icon={AddIcon} route={routes.upload_post}/>
                <Page label={'MEMBERS'} Icon={GroupAddIcon} route={routes.members}/>
                <Page label={'ABOUT'} Icon={About} route={routes.about}/>
                <Page label={'TERMS'} Icon={Terms} route={routes.terms}/>
                <Page label={'PRIVACY'} Icon={Privacy} route={routes.privacy}/>
                <Page label={'CONTACT'} Icon={Contact} route={routes.contact}/>
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <Divider/>

          {/*<ExpansionPanel*/}
          {/*  square*/}
          {/*  expanded={expanded === 'panel2'}*/}
          {/*  onChange={handleChange('panel2')}*/}
          {/*  onClick={event=>{event.stopPropagation()}}*/}
          {/*>*/}
          {/*  <ExpansionPanelSummary aria-controls="panel2d-content">*/}
          {/*    <Button>*/}
          {/*      <List>*/}
          {/*        <ListItem>*/}
          {/*          <ListItemIcon><FavoriteBorderIcon /></ListItemIcon>*/}
          {/*          <ListItemText primary={"CATEGORIES"} />*/}
          {/*        </ListItem>*/}
          {/*      </List>*/}
          {/*    </Button>*/}
          {/*    <Divider />*/}
          {/*  </ExpansionPanelSummary>*/}
          {/*  <ExpansionPanelDetails>*/}
          {/*    <List style={{paddingLeft:10, paddingRight:10, width:'100%'}}>*/}
          {/*      {category_tabs.map((category: CategoryTab) =>*/}
          {/*        <ListItem*/}
          {/*          button*/}
          {/*          key={category.index}*/}
          {/*          onClick={async () => {*/}
          {/*            set_category_tab(category_tabs[category.index]);*/}
          {/*            await sleep(300);*/}
          {/*            toggleDrawer({})*/}
          {/*          }}*/}
          {/*          style={category_tab.index === category.index*/}
          {/*            ? {backgroundColor:style_vars.colors.btn_red, boxShadow: '0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)'}*/}
          {/*            : {backgroundImage:`url(${category.img})`}*/}
          {/*          }*/}
          {/*          className={classes.ListItem}*/}
          {/*        >*/}
          {/*          <ListItemText*/}
          {/*            primary={category.label}*/}
          {/*            className={classes.ListItemText}*/}
          {/*            style={category_tab.index === category.index ? {color: '#fff'} : {color:'#666'}}*/}
          {/*          />*/}
          {/*        </ListItem>*/}
          {/*      )}*/}
          {/*      <ListItem*/}
          {/*        button*/}
          {/*        style={{backgroundColor: 'rgb(231, 229, 230)', height:42}}*/}
          {/*        className={classes.ListItem}*/}
          {/*      />*/}
          {/*    </List>*/}
          {/*  </ExpansionPanelDetails>*/}
          {/*</ExpansionPanel>*/}
        </div>
      </PerfectScrollbar>
    </Drawer>
  );
}