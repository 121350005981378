import React, {ReactNode, useState, Fragment} from 'react'
import Slider from '@material-ui/core/Slider';
import Cropper from 'react-easy-crop'
import { getOrientation } from 'get-orientation/browser'
import getCroppedImg from './CropImage'
import { getRotatedImage } from './rotateImage'
import {Button, makeStyles} from '@material-ui/core'
import Swal from "sweetalert2";
import ImageTools from "../../../utils/ImageTools";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions'
import LinearProgress from '@material-ui/core/LinearProgress'
import type {ImageReady} from "../../../types";

const useStyles = makeStyles({
  paper: {
    maxWidth:600,
    maxHeight:500,
    width: '100%',
    height: '100%',
  },

  cropContainer: {
    width: '100%',
    height: '400px',
    position: 'relative',
  },

  controls: {
    margin: 'auto',
    width: '50%',
    display: 'flex',
    alignItems: 'center',
  },

  slider: {
    padding: '30px 0px',
  },

  button: {
    textAlign: 'center',
  },
});

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
};

function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file)
  })
}

type Props = {|
  id: string,
  onImage: ImageReady,
  width: number,
  height: number,
|};

export default ({id, onImage, width=150, height=150}: Props): ReactNode => {
  const aspect = 1;

  const classes = useStyles();

  const [imageSrc, set_imageSrc] = useState(null);
  const [crop, set_crop] = useState({ x: 0, y: 0 });
  const [zoom, set_zoom] = useState(1);
  const [croppedAreaPixels, set_croppedAreaPixels] = useState(null);
  const [isCropping, set_isCropping] = useState(false);
  const [target, set_target] = useState(null);

  const onClose = () => {
    set_imageSrc(null);
    if (target && target.files) {
      target.value = '';
    }
  };

  const onZoomChange = zoom => set_zoom(zoom);
  const onCropChange = crop => set_crop(crop);
  const onCropComplete = (croppedArea, croppedAreaPixels) => set_croppedAreaPixels(croppedAreaPixels);

  const showResult = async () => {
    set_isCropping(true);
    try {
      const {file, url} = await getCroppedImg(imageSrc, croppedAreaPixels);
      new ImageTools()
        .resize(file, {width, height})
        .then((blob:Blob) => onImage(url, blob));
    }
    catch (e) {
      console.error(e);
    }
    set_isCropping(false);
    onClose();
  };

  const onFileChange = async (event:Event) => {
    event.preventDefault();
    if (event.target.files && event.target.files.length > 0) {
      set_target(event.target);

      const file = event.target.files[0];

      if (!file)
        return;

      if (!file.size)
        return;

      const max_size = 4096*4096; // blue ray
      if (file.size > max_size) {
        Swal.fire({
          title: 'File size too big',
          text: `Image ${file.size} exceeded the size limit of ${max_size/1000000}MB`,
          type: 'error',
        });
        return;
      }

      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation)
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);

      set_imageSrc(imageDataUrl);
      set_crop({ x: 0, y: 0 });
      set_zoom(1);
    }
  };

  return (
    <div>
      <input
        accept="image/*"
        id={id}
        multiple
        type="file"
        style={{display:'none'}}
        onChange={onFileChange}
      />
      <Dialog open={!!imageSrc} PaperProps={{className: classes.paper}}>
        {imageSrc && (
          <Fragment>
            <div className={classes.cropContainer}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                cropShape="round"
                showGrid={false}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
              />
            </div>
            <div className={classes.controls}>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => onZoomChange(zoom)}
                classes={{ container: 'slider' }}
              />
            </div>
          </Fragment>
        )}
        <DialogActions>
          <Button disabled={isCropping} color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isCropping} color="primary" autoFocus onClick={showResult}>
            Upload
          </Button>
        </DialogActions>
        {isCropping && <LinearProgress color='secondary'/>}
      </Dialog>
    </div>
  )
}