import React, {useState, useEffect} from "react"
import {useLoadScript} from "@react-google-maps/api"
import {google_maps_key} from '../variables'

const libraries = ["places"];


export default () => {
  const [loaded_google_maps, set_loaded_google_maps] = useState(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: google_maps_key,
    libraries,
  });

  useEffect(()=>{
    if (loadError) {
      set_loaded_google_maps(false);
      console.log('loaded_google_maps useLoadScript Error', loadError);
    }
    else if (isLoaded)
      set_loaded_google_maps(true);
  },[isLoaded, loadError]);

  return loaded_google_maps
}
