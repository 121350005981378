import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

export default function TransportType() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        "freight transport": false,
        "retail distribution": false,
        "heavy cargo": false,
        "concrete": false,
        "earthworks": false,
        "supply lines": false,
        "vehicles": false,
        "transports": false,
        "containers": false,
        "courier service": false,
    });

    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
    };

    const keys = Object.keys(state);
    const error = keys.filter(v => v).length <= 1;

    return (
        <div className={classes.root}>
            <FormControl required error={error} component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Choose Transport Task</FormLabel>
                <FormGroup>
                    {keys.map((key, index) => <FormControlLabel
                        control={<Checkbox checked={state[key]} onChange={handleChange(key)} value={key} />}
                        label={key}
                    />)}
                </FormGroup>
                {error?<FormHelperText>No Task Selected</FormHelperText>:null}
            </FormControl>
        </div>
    );
}