import request from 'axios'
import {generic_error} from '../utils/errors'
import {handle_register_success, handle_login_success} from '../utils/success'
import {handle_refresh} from './refresh'
import {base_config as config} from "../utils/configs";
import {url} from '../utils/urls'
import sleep from '../../utils/sleep'

export const register = async (
  email,
  password,
  news_letter,
  captcha,
  ) => {
  try {
    await request.post(url+'/register', {
      email,
      password,
      news_letter,
      captcha
    }, config);
    handle_register_success();
    await sleep(1000);
    return true;
  } catch (e) {
    return generic_error(e)
  }
};

export const register_verify = async (
  email,
  token,
  set_user,
  set_snackbar,
  ) => {
  try {
    const response = await request.post(url+'/register/verify', {email, token}, config);
    handle_refresh(response);
    return handle_login_success(response, set_user, set_snackbar)
  } catch (e) {
    return generic_error(e)
  }
};
