import React, {useContext, useState} from "react";
import {password_recovery_2} from '../../api/public/change_password'
import state from '../../state'
import Grid from '@material-ui/core/Grid';
import Submit from '../../components/custom/CustomButtons/Submit'
import {password_validation, confirm_password_validation, set_error} from '../../validations/signin_validations'
import PasswordInput from '../../components/custom/CustomInput/PasswordInput'
import CmpPassword from '../../components/custom/CustomInput/CmpPassword'

export default ({match}) => {
  const links = useContext(state.links);
  const email = match.params.email;
  const token = match.params.token;

  const {set_user} = useContext(state.user);
  const {set_snackbar} = useContext(state.snackbar);

  const [loading, set_loading] = useState(false);
  const [password, set_password] = useState('');
  const [password_confirmed, set_password_confirmed] = useState(false);

  const onSubmit = async () => {
    try {
      password_validation(password);
      confirm_password_validation(password_confirmed);
    } catch (e) {
      return set_snackbar(set_error(e.message))
    }

    set_loading(true);
    if (await password_recovery_2(email, password, token, set_user, set_snackbar))
        links.go_home();

    set_loading(false)
  };

  return (
    <Grid container justify="center" spacing={5}>
      <Grid item xs={12}>
        <PasswordInput
          style={{width:240, margin:'auto'}}
          label='Enter new password'
          value={password}
          onChange={event=>set_password(event.target.value)}
          validator={password_validation}
        />
      </Grid>
      <Grid item xs={12}>
        <CmpPassword
          expansionPanelStyle={{backgroundColor:'#fafafa'}}
          style={{width:240, margin:'auto'}}
          label='Confirm new password'
          cmpValue={password}
          isValid={password_confirmed}
          setValid={set_password_confirmed}
        />
      </Grid>
      <Grid item xs={12}>
        <div style={{width:200, margin:'auto'}}>
          <Submit
            onSubmit={onSubmit}
            text={"Change Password"}
            loading={loading}
          />
        </div>
      </Grid>
    </Grid>
  );
}
