import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const values: Array = [
  'hourly',
  'daily',
  'monthly',
  'per assignment',
  'per stop',
  'by weight & distance',
  'other',
];

const Other = ({close, onChange, onSave, onCancel}) => {
  const [value, set_value] = useState('');
  return (
    <TextField
      style={{marginBottom:10}}
      label={'enter price method'}
      value={value}
      onChange={event => set_value(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoneyIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <div style={{display:'flex'}}>
              <IconButton
                size='small'
                aria-label="increase input"
                onClick={()=>{
                  if (value && !values.includes(value)) {
                    close();
                    onChange({target:{value}});
                  }
                }}
              >
                <EditIcon fontSize={'inherit'}/>
              </IconButton>
              <IconButton
                size='small'
                aria-label="increase input"
                onClick={close}
              >
                <CloseIcon fontSize={'inherit'}/>
              </IconButton>
            </div>
          </InputAdornment>
        )
      }}
    />
  )
};

export default function SimpleSelect({value, onChange, disabled=false, actions=null}) {
  const classes = useStyles();
  const [other, set_other] = useState(false);

  const handleChange = (event) => {
    const new_value = event.target.value;
    if (!new_value)
      return;

    if (new_value === 'other') {
      set_other(true);
      return;
    }

    if (!values.includes(new_value)) {
      values.splice(values.length-1, 0, new_value);
    }
    onChange(new_value);
  };

  const closeOther = () =>
    set_other(false);

  return other
    ?
    <Other
      value={value}
      close={closeOther}
      onChange={handleChange}
    />
    :
    <div style={{display:'flex'}}>
      {disabled
        ?
        <TextField disabled={disabled} fullWidth={true} value={value}/>
        :
        <FormControl className={classes.formControl}>
          <InputLabel>price method</InputLabel>
          <Select value={value} onChange={handleChange}>
            {values.map((value, index) => {
              if (value === 'other')
                return (
                  <div key={index}>
                    <Divider style={{margin:10}}/>
                    <MenuItem value={value}>{value}</MenuItem>
                  </div>
                );
              return (
                <MenuItem key={index} value={value}>{value}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      }
      {actions}
    </div>;
};