import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import Profile from "../forms/new_driver/Profile";
import WorkLocations from "../forms/new_driver/WorkLocations";
import PersonalDetails from "../forms/new_driver/PersonalDetails";
import WorkPreferences from "../forms/new_driver/WorkPreferences";
import ServicesAndPricing from "../forms/new_driver/ServicesAndPricing";
import Licences from "../forms/new_driver/Licences";
import ExtraPoints from "../forms/new_driver/ExtraPoints";
import sleep from '../../utils/sleep'
import StepButton from '@material-ui/core/StepButton';
import ids from '../../ids'
import state from "../../state";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import type {NewDriverFormProps} from "../../interface";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  resetContainer: {
    width: '100%',
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return [
    'Driver Profile',
    'Licence',
    'Personal Info',
    'Work Locations',
    'Work Preferences',
    // 'Services & Pricing',
    'Extra Points'
  ];
}

function StepSwitch(props:NewDriverFormProps) {
  switch (props.step) {
    case 0: return <Profile {...props}/>;
    case 1: return <Licences {...props}/>;
    case 2: return <PersonalDetails {...props}/>;
    case 3: return <WorkLocations {...props}/>;
    case 4: return <WorkPreferences {...props}/>;
    // case 5: return <ServicesAndPricing {...props}/>;
    case 5: return <ExtraPoints {...props}/>;
    default: return <div>Unknown step</div>;
  }
}

export default function VerticalLinearStepper({set_stepProps, template, set_template}) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const { scrollTo, set_scrollTo } = useContext(state.scrollTo);

  const [activeStep, setActiveStep] = useState(0);
  const [open, set_open] = useState(true);
  const [completed, setCompleted] = useState({});
  const [formProps, set_formProps] = useState(null);
  const steps = getSteps();

  const totalSteps = () => steps.length;
  const completedSteps = () => Object.keys(completed).length;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const allStepsCompleted = () => completedSteps() === totalSteps();

  // TODO: not working
  const scrollToStep = (index) => set_scrollTo({
    ...scrollTo,
    to: `${ids.new_driver_form_step}-${index}`
  });

  const handleStep = (step) => async () => {
    set_open(false);
    await sleep(500);
    setActiveStep(step);
    await sleep(500);
    set_open(true);
  };

  const handleNext = async () => {
    set_open(false);
    await sleep(500);
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);

    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);

    await sleep(500);
    set_open(true);
    scrollToStep(newActiveStep)
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleBack = async () => {
    set_open(false);
    await sleep(500);
    setActiveStep((prevActiveStep) => {
      const prevStep = prevActiveStep - 1;
      scrollToStep(prevStep);
      return prevStep;
    });
    await sleep(500);
    set_open(true);
  };

  const handleReset = async () => {
    set_open(false);
    await sleep(500);
    scrollToStep(0);
    setActiveStep(0);
    setCompleted({});
    await sleep(500);
    set_open(true);
  };

  useEffect(() => {
    if (formProps !== null) {
      const {
        isSubmitting,
        submitForm,
      } = formProps;

      set_stepProps({
        isSubmitting,
        submitForm,
        activeStep,
        steps,
        classes,
        handleReset,
        handleBack,
        allStepsCompleted,
      })
    }
  }, [formProps, activeStep]);

  return (
    <div className={classes.root}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        orientation="vertical"
        style={mobile?{paddingLeft:10, paddingRight:10}:{}}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={handleStep(index)} completed={completed[index]} id={`${ids.new_driver_form_step}-${index}`}>
              {label}
            </StepButton>
            <StepContent style={mobile?{paddingLeft:10, paddingRight:0}:{}}>
              <StepSwitch
                template={template}
                set_template={set_template}
                open={open}
                step={index}
                onSuccess={()=>{handleComplete();handleNext();}}
                set_formProps={set_formProps}
              />
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}