import React, {useContext} from 'react'
import cover from '../../assets/images/wallpaper2.jpg'
import {Page} from '../../wrappers/view_wrappers'
import PageHeader from '../../components/headers/PageHeader'
import PageHeaderBottom from '../../components/headers/PageHeaderBottom'
import PageHeaderBottomSlope from '../../components/headers/PageHeaderBottomSlope'
import {useLocation} from 'react-router-dom'
import SectionsLayout from '../../components/layout/SectionsLayout'
import HeadSection from '../../components/sections/HeadSection'
import state from '../../state'
import signinToViewStyles from "../../assets/jss/signinToViewStyles";


export default ({children=null, ...props}) => {
  const location = useLocation();
  const classes = signinToViewStyles();
  const links = useContext(state.links);

  return (
    <Page>
      <PageHeader
        title={location.pathname
          .slice(1, location.pathname.length)
          .split('/').join(' ')
          .split('_').join(' ')
          .toUpperCase()
        }
        text={
          <div className={classes.signin} onClick={links.go_home}>
            Coming Soon!
          </div>
        }
        // backgroundImage={'undefined'}
        // backgroundImage={undefined}
        backgroundImage={cover}
        darken={true}
      />
      <PageHeaderBottomSlope/>
      <PageHeaderBottom/>
      <SectionsLayout {...props}>
        <HeadSection
          title='This page is under development 🚧'
          text='This page is under development and is unavailable at the moment, make sure to come again soon and get updated'
        />
        {children}
      </SectionsLayout>
    </Page>
  )
}

