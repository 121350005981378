import React, {useEffect, useState} from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';

function NumberFormatCustom({value, isAllowed, suffix='', prefix='', inputRef, onChange, ...other}) {
  return (
    <NumberFormat
      {...other}
      value={value}
      getInputRef={inputRef}
      onValueChange={({ value, floatValue }) => {
        onChange({
          target: {
            value,
            floatValue,
          },
        });
      }}
      isAllowed={isAllowed}
      thousandSeparator
      isNumericString
      prefix={prefix}
      suffix={suffix}
    />
  );
}

export default function InputNumber(
  {
    label,
    step=1,
    min=0,
    max=100,
    value,
    setValue,
    suffix,
    prefix,
    iconSize='medium',
    ...props
  }
) {

  const [Input, set_Input] = useState({Input:null});
  const [floatValue, set_floatValue] = useState(value);


  const isAllowed = ({ formattedValue, floatValue }) => {
    return formattedValue === "" || (floatValue <= max && floatValue >= min);
  };

  const handleInputChange = (event) => {
    set_floatValue(event.target.floatValue);
    setValue(event.target.value === '' ? '' : event.target.value);
  };

  const increase_input = () => {
    if (isAllowed({ formattedValue:value, floatValue:floatValue+step }))
      setValue(floatValue+step)
  };

  const decrease_input = () => {
    if (isAllowed({ formattedValue:value, floatValue:floatValue-step }))
      setValue(floatValue-step)
  };

  useEffect(() => {
    set_Input({
      Input: props =>
        <NumberFormatCustom
          {...props}
          isAllowed={isAllowed}
          prefix={prefix}
          suffix={suffix}
          min={min}
          max={max}
        />
    })
  }, [
    suffix,
    prefix,
    min,
    max,
  ]);


  return Input.Input !== null
    ?
    <TextField
      label={label}
      value={value}
      onChange={handleInputChange}
      InputProps={{
        inputComponent: Input.Input,
        endAdornment: iconSize !== null ? (
          <InputAdornment position="end">
            <div style={{display:'flex'}}>
              <IconButton
                aria-label="increase input"
                onClick={increase_input}
                size={iconSize}
              >
                <AddIcon fontSize='inherit'/>
              </IconButton>
              <IconButton
                aria-label="decrease input"
                onClick={decrease_input}
                size={iconSize}
              >
                <RemoveIcon fontSize='inherit'/>
              </IconButton>
            </div>

          </InputAdornment>
        ) : undefined,
      }}
      InputLabelProps={{ shrink: value!==''}}
      {...props}
    />
    :
    <TextField/>
}