import request from 'axios'
import {auth_url} from '../utils/urls'
import {auth_config} from '../utils/configs'
import {auth_call, silent_auth_call} from '../utils/auth_calls'

export const is_following = silent_auth_call(async ({owner_id}) => {
    const res = await request.get(auth_url+'/profile/is_following/'+owner_id, auth_config);
    return res.data.follow;
});

export const follow = auth_call(async ({owner_id}) => {
    await request.post(auth_url+'/profile/follow/'+owner_id, {}, auth_config);
    return true;
});

export const unfollow = auth_call(async ({owner_id}) => {
    await request.post(auth_url+'/profile/unfollow/'+owner_id, {}, auth_config);
    return true;
});