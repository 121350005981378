import React, {useState, useContext, useEffect} from "react";
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import {LinearProgress} from '@material-ui/core';
import state from '../state'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import FaceIcon from '@material-ui/icons/Face';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Chip from '@material-ui/core/Chip';
import type {Driver, User} from "../types";
import beautifulNumber from '../utils/beautifulNumber'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid'
import {DeleteDriverSetting, DriverCard} from './cards/DriverCard'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import NewDriverDialog from './dialogs/NewDriverDialog'
import {get_user_drivers_page, count_user_drivers} from '../api/public/users';
import {usePagination} from '../hooks/usePagination'
import {Paginator} from './Pagination'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default () => {
  const classes = useStyles();
  const user:User = useContext(state.user).user;

  const [open, set_open] = useState(false);

  const fetchCount = () => count_user_drivers(user._id);
  const fetchPage = (page_number: number, items_per_page: number) =>
    get_user_drivers_page(
      user._id,
      page_number,
      items_per_page,
    );

  const {
    loading, set_loading,
    count_items, set_count_items,
    items_per_page, set_items_per_page,
    page_number, set_page_number,
    last_page, set_last_page,
    page_items, set_page_items,
    page_matrix, set_page_matrix,
    add_item, remove_item,
  } = usePagination({
    fetchCount,
    fetchPage,
    initial_items_per_page: 30,
  });

  const [template, set_template] = useState({
    driver_created: false, // signal if profile was created
  });

  const driver_created = template.driver_created;

  // reset template
  useEffect(()=>{
    if (user===null)
      set_template({driver_created: false});

    else if(open===false) {
      set_template({...template, driver_created: false})
    }
  },[user, open]);

  // add new driver to assets (driver )
  useEffect(()=>{
    if (driver_created) {
      if (page_items.length > 0 && page_items[0]._id === template._id)
        return;
      add_item(template);
    }
  },[driver_created]);

  useEffect(()=>{
    if (user !== null && page_items.length === 0) {
      const update = {...template};
      update.full_name = user.full_name;
      if (user.image_url_hd.startsWith('https://')) {
        update.image_url_hd = user.image_url_hd;
      }
      update.description = user.description;
      update.home_country = user.country;
      update.home_region = user.region;
      update.home_city = user.city;
      update.email = user.email;
      update.company = user.company;
      update.job = user.job;
      update.phone_number = user.phone_number;
      set_template(update)
    }
  },[user]);

  useEffect(()=>{
    if (page_items.length > 0 && driver_created === false) {
      const update = {...template};
      const driver:Driver = page_items[0];
      if (driver.image_url_hd.startsWith('https://')) {
        update.image_url_hd = driver.image_url_hd;
      }
      update.description = driver.description;
      update.home_country = driver.home_country;
      update.home_region = driver.home_region;
      update.home_city = driver.home_city;
      update.company = driver.company;
      update.job = driver.job;
      set_template(update)
    }
  },[page_items, driver_created]);

  return (
      <div>
        <Grid container justify='center' style={{marginTop:35}}>
          <Button variant="contained" color="primary" onClick={()=>set_open(true)}>
            New Driver
          </Button>
        </Grid>

        <Grid container justify='center' className={classes.root} spacing={2} style={{marginTop:35}}>
          {page_items.map((driver, index)=>
            <Grid item key={driver._id}>
              <DriverCard
                settings={({set_is_open, handleClose}) => [
                  <DeleteDriverSetting key={1} driver={driver} handleClose={handleClose} afterDelete={()=>remove_item(driver)} set_is_open={set_is_open}/>,
                ]}
                driver={driver}
                style={{maxWidth:300}}
                timeout={300+100*index}
              />
            </Grid>
          )}
        </Grid>

        <Paginator
          style={{marginTop:40}}
          loading={loading}
          set_page_number={set_page_number}
          page_number={page_number}
          last_page={last_page}
        />

        <NewDriverDialog
          open={open}
          set_open={set_open}
          template={template}
          set_template={set_template}
        />

      </div>
  )
};