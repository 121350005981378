import React, {useState, useContext, useEffect, useCallback} from "react";
import state from '../../state'
import {Page} from '../../wrappers/view_wrappers'
import SignInToView from '../OtherPages/SignInToView'
import SectionsLayout from '../../components/layout/SectionsLayout'
import {useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import nice_cover from '../../assets/images/nice_cover.svg'
import Slide from '@material-ui/core/Slide'
import Zoom from '@material-ui/core/Zoom'
import Fade from '@material-ui/core/Fade'
import {text1, text2, text3} from '../../assets/jss/textStyle'
import type {User, Function} from "../../types";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { blue } from '@material-ui/core/colors';
import LearnMore from "../../components/LearnMore";
import Edit from '../../components/Edit'
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LockIcon from '@material-ui/icons/Lock';
import {colors} from '../../assets/jss/style_vars'
import IconButton from '@material-ui/core/IconButton'
import photo_icon from '../../assets/images/icons/photo2.svg'
import Tooltip from "../../components/Tooltip";
import userProfileStyles from "../../assets/jss/material-dashboard-pro-react/views/userProfileStyles";
import CustomInput from "../../components/custom/CustomInput/CustomInput.jsx";
import sleep from "../../utils/sleep";
import Save from '../../components/Save'
import Cancel from '../../components/Cancel'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {edit_description as edit_description_api, edit_photo as edit_photo_api} from '../../api/auth/account'
import Swal from 'sweetalert2'
import ImageTools from '../../utils/ImageTools'
import Crop from '../../components/custom/CustomUpload/Crop'
import ids from "../../ids";
import Avatar from '../../components/custom/CustomUpload/Avatar'


const username = {
  ...text1,
  marginTop: 0,
  fontSize: '28px',
  textAlign:'center',
};

const useStyles = makeStyles(theme => ({
  title: {
    ...username,
    color:'#fff',
    fontWeight:400,
    fontSize:36,
    paddingTop:30,
    paddingBottom:25,
    margin:0,
  },

  accountPaper: {
    borderRadius: 2,
    border: '1px solid #ababab',
    maxWidth:700,
    margin:'auto',
    [theme.breakpoints.down('xs')]: {
      border: 'none',
    }
  },

  accountCover: {
    minHeight:80,
    maxHeight:250,
    color: 'rgba(0,0,0,.9)',
    boxSizing: 'inherit',
    margin: '0',
    padding: '0',
    border: '0',
    fontSize: '100%',
    verticalAlign: 'baseline',
    position: 'relative',
    width: '100%',
    paddingBottom: '20%',
    background: `url(${nice_cover}) 50%/cover`,
  },

  accountDetailWrapper: {
    position:'relative',
    top:-170,
  },

  accountDetailsWrapper: {
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem',
    },
    padding: '2rem',
    paddingTop:0,
    paddingBottom:0,
  },

  username,
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
}))(Button);

export default () => {
  const {entered_page} = useContext(state.entered_page);
  const links = useContext(state.links);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const userProfileStyle = userProfileStyles();
  const user: User = useContext(state.user).user;
  const set_user: Function = useContext(state.user).set_user;
  const {profile_complete} = useContext(state.profile_complete);
  const [description, set_description] = useState(user&&user.description?user.description:'');
  const [description_input_value, set_description_input_value] = useState(description);
  const [edit_description, set_edit_description] = useState(false);
  const [edit_description_btns, set_edit_description_btns] = useState('edit');

  const closeEditDescription = async () => {
    set_edit_description_btns('edit');
    await sleep(300);
    set_edit_description(false)
  };

  const cancelEditDescription = async () => {
    set_description_input_value(description);
    set_edit_description_btns('edit');
    await sleep(300);
    set_edit_description(false)
  };

  const saveEditDescription = async () => {
    set_description(description_input_value);
    set_edit_description_btns('edit');
    set_user({...user, description:description_input_value});
    edit_description_api({set_user, description:description_input_value});
    await sleep(290);
    set_edit_description(false);
  };

  useEffect(() => {
    if (edit_description===true) {
      document.getElementById('about-me').focus()
    }
  }, [edit_description]);

  useEffect(() => {
    if (profile_complete===false)
      links.go_to_my_account_setup(100);
  }, [profile_complete]);

  if (user===null) return (
    <SignInToView
      head_section_title={
        'Profile your assets ' +
        'and tell us your needs'
      }
      head_section_text={
        'type in your location ' +
        'and driving requirements ' +
        'and start connecting with other shippers and carriers'
      }
    />
  );

  return (
    <Page>
      <SectionsLayout style={{padding:mobile?0:10, paddingTop: mobile?0:30, paddingBottom: 30}}>
        <Slide direction='up' timeout={700} in={profile_complete && entered_page}>
          <Paper elevation={mobile?0:2} className={classes.accountPaper}>
            <div className={classes.accountCover}/>
            <div className={classes.accountDetailWrapper}>
              <p className={classes.title}>My Account</p>

              <Avatar
                id={ids.upload_profile_photo}
                direction='down'
                timeout={1500}
                open={profile_complete && entered_page}
                onClick={links.go_to_gravatar}
                justify='center'
                image={user.image_url_hd}
                onImage={(url: string, blob:Blob) => {
                  set_user({...user, image_url_hd: url, image_url: url});
                  edit_photo_api({set_user, user, file:blob})
                }}
              />

              <Edit
                onClick={links.go_to_my_account_edit}
                timeout={2200}
                open={profile_complete && entered_page}
                containerProps={{style:{width:'100%'}}}
                wrapperProps={{style:{top:-80}}}
                tip={'edit your account details'}
              />

              <Grid container justify='center' className={classes.accountDetailsWrapper}>
                <div style={{maxWidth:mobile?300:400, margin:'auto'}}>
                  <Fade in={profile_complete && entered_page} timeout={1800}>
                    <p className={classes.username}>{user.username}</p>
                  </Fade>

                  <Fade in={profile_complete && entered_page} timeout={2200}>
                    {edit_description
                      ?
                      <ClickAwayListener onClickAway={closeEditDescription}>
                        <div className={userProfileStyle.description} style={{marginLeft:28, textAlign:'left', marginBottom:0, minHeight:130}}>
                          <div style={{display:'flex', width:'100%', marginBottom:-10, fontSize:18, fontWeight:400}}>
                            <div>About me</div>
                            <Save
                              onClick={saveEditDescription}
                              timeout={250}
                              open={edit_description_btns === 'save' && profile_complete && entered_page}
                              containerProps={{style:{marginTop:-8, marginLeft:6}}}
                              wrapperProps={{style:{}}}
                              btnProps={{style:{width:32, height: 32}}}
                              iconProps={{style:{width:24, height: 24}}}
                              tip={'save changes'}
                            />
                            <Cancel
                              onClick={cancelEditDescription}
                              timeout={450}
                              open={edit_description_btns === 'save' && profile_complete && entered_page}
                              containerProps={{style:{marginTop:-8}}}
                              wrapperProps={{style:{}}}
                              btnProps={{style:{width:32, height: 32}}}
                              iconProps={{style:{width:24, height: 24}}}
                              tip={'cancel changes'}
                            />
                          </div>
                          <CustomInput
                            id='about-me'
                            labelText="tell us about yourself"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: description_input_value,
                              onChange: event => {event.target.value.length < 501 && set_description_input_value(event.target.value)},
                              multiline: true,
                              rows: 3
                            }}
                          />
                        </div>
                      </ClickAwayListener>
                      :
                      <div className={userProfileStyle.description} style={{marginLeft:28, textAlign:'left', marginBottom:0, minHeight:130}}>
                        <div style={{display:'flex', width:'100%', marginBottom:10, fontSize:18, fontWeight:400}}>
                          <div>About me</div>
                          <Edit
                            onClick={async ()=>{set_edit_description_btns('save'); await sleep(300);set_edit_description(true)}}
                            timeout={300}
                            open={edit_description_btns === 'edit' && profile_complete && entered_page}
                            containerProps={{style:{width:0, position:'default'}}}
                            wrapperProps={{style:{position:'relative', top:-8, right:-5}}}
                            btnProps={{style:{width:32, height: 32}}}
                            iconProps={{style:{width:24, height: 24}}}
                            tip={'tell us about yourself'}
                          />
                        </div>
                        {description?description:'tell us about yourself'}
                      </div>
                    }
                  </Fade>

                </div>
              </Grid>

              <Edit
                onClick={links.go_to_my_account_edit}
                timeout={2200}
                open={profile_complete && entered_page}
                wrapperProps={{style:{top:-10}}}
                tip={'edit your personal information'}
              />
              <Fade in={profile_complete && entered_page} timeout={1800}>
                <p className={classes.username}>My Info</p>
              </Fade>

              <Grid container justify='center' className={classes.accountDetailsWrapper}>
                <div style={{maxWidth:mobile?300:400, margin:'auto'}}>
                  <Fade in={profile_complete && entered_page} timeout={2300}>
                    <div>
                      <Grid container>
                        <div>
                          <div style={{...text3, paddingTop:mobile?0:3, marginBottom:mobile?10:7, marginRight:mobile?5:10}}>{/*<LockIcon style={{fontSize:mobile?13:18}}/>*/}</div>
                          <div style={{...text3, paddingTop:mobile?0:3, marginBottom:mobile?10:7, marginRight:mobile?5:10}}>{/*<LockIcon style={{fontSize:mobile?13:18}}/>*/}</div>
                          <div style={{...text3, paddingTop:mobile?0:3, marginBottom:mobile?10:7, marginRight:mobile?5:10}}>{/*<LockIcon style={{fontSize:mobile?13:18}}/>*/}</div>
                          <div style={{...text3, paddingTop:mobile?0:3, marginBottom:mobile?10:7, marginRight:mobile?5:10}}>{/*<LockIcon style={{fontSize:mobile?13:18}}/>*/}</div>
                          <div style={{...text3, paddingTop:mobile?0:3, marginBottom:mobile?10:7, marginRight:mobile?5:10}}>{/*<LockIcon style={{fontSize:mobile?13:18}}/>*/}</div>
                          <div style={{...text3, paddingTop:mobile?0:3, marginBottom:mobile?10:7, marginRight:mobile?5:10}}>{/*<LockIcon style={{fontSize:mobile?13:18}}/>*/}</div>
                          <div style={{...text3, paddingTop:mobile?0:3, marginBottom:mobile?10:7, marginRight:mobile?5:10}}>{/*<LockOpenIcon style={{fontSize:mobile?13:18, color: colors.red}}/>*/}</div>
                          <div style={{...text3, paddingTop:mobile?0:3, marginBottom:mobile?10:7, marginRight:mobile?5:10}}>{/*<LockOpenIcon style={{fontSize:mobile?13:18, color: colors.red}}/>*/}</div>
                          <div style={{...text3, paddingTop:mobile?0:3, marginBottom:mobile?10:7, marginRight:mobile?5:10}}><LockIcon style={{fontSize:mobile?13:18, color:colors.disabled}}/></div>
                        </div>
                        <div>
                          <div style={{...text3, fontSize:mobile?13:18}}>email:</div>
                          <div style={{...text3, fontSize:mobile?13:18}}>name:</div>
                          <div style={{...text3, fontSize:mobile?13:18}}>country:</div>
                          <div style={{...text3, fontSize:mobile?13:18}}>region:</div>
                          <div style={{...text3, fontSize:mobile?13:18}}>city:</div>
                          <div style={{...text3, fontSize:mobile?13:18}}>phone:</div>
                          <div style={{...text3, fontSize:mobile?13:18}}>company:</div>
                          <div style={{...text3, fontSize:mobile?13:18}}>job title:</div>
                          <div style={{...text3, fontSize:mobile?13:18}}>password:</div>
                        </div>
                        <div style={{marginLeft: mobile?5:15}}>
                          <div style={{...text2, fontSize:mobile?13:18}}>{user.email}</div>
                          <div style={{...text2, fontSize:mobile?13:18}}>{user.full_name}</div>
                          <div style={{...text2, fontSize:mobile?13:18}}>{user.country?user.country:'-'}</div>
                          <div style={{...text2, fontSize:mobile?13:18}}>{user.region?user.region:'-'}</div>
                          <div style={{...text2, fontSize:mobile?13:18}}>{user.city?user.city:'-'}</div>
                          <div style={{...text2, fontSize:mobile?13:18}}>{user.phone_number?user.phone_number:'-'}</div>
                          <div style={{...text2, fontSize:mobile?13:18}}>{user.company?user.company:'-'}</div>
                          <div style={{...text2, fontSize:mobile?13:18}}>{user.job?user.job:'-'}</div>
                          <div style={{...text2, fontSize:mobile?13:18}}>********</div>
                        </div>
                      </Grid>
                    </div>
                  </Fade>
                </div>
              </Grid>

              <Edit
                onClick={links.go_to_my_assets}
                timeout={2200}
                open={profile_complete && entered_page}
                wrapperProps={{style:{top:mobile?0:10}}}
                tip={'edit your asset profiles'}
              />
              <Fade in={profile_complete && entered_page} timeout={1800}>
                <p className={classes.username} style={{marginTop:mobile?10:20, marginBottom:10}}>My Assets</p>
              </Fade>
              <Fade in={profile_complete && entered_page} timeout={2300}>
                <div style={{marginBottom:70}}>
                  <Grid container justify='center'>
                    <div>
                      <div style={{...text3, fontSize:18}}>drivers:</div>
                      <div style={{...text3, fontSize:18}}>vehicles:</div>
                    </div>
                    <div style={{marginLeft: 15}}>
                      <div style={{...text2, fontSize:18}}>{user.drivers}</div>
                      <div style={{...text2, fontSize:18}}>{user.vehicles}</div>
                    </div>
                  </Grid>

                  <Grid container justify='center'>
                    {user.drivers + user.vehicles === 0
                      ?
                      <ColorButton
                        onClick={links.go_to_my_assets}
                        style={{
                          marginTop:10,
                          textTransform:'none',
                          paddingLeft:30,
                          paddingRight:30,
                          height: 50,
                          paddingTop: 0,
                        }}
                      >
                        <div>
                          Become a
                          <div style={{fontSize: 16, position: 'relative', top: -15}}>
                            GO<LocalShippingIcon style={{position: 'relative', width: 18, top: 6}}/>
                          </div>
                          <div style={{position: 'relative', top: -26, letterSpacing: 1.3, paddingLeft: 1, fontSize: 11}}>tracker</div>
                        </div>
                      </ColorButton>
                      :
                      <ColorButton onClick={()=>{}}>
                        Add Assets
                      </ColorButton>
                    }

                  </Grid>
                  <LearnMore
                    tip={
                      "As a Carrier/Freelancer " +
                      "you need to profile the drivers/vehicles your " +
                      "going to book to shippers and other carriers " +
                      "who are looking for your services"
                    }
                    style={{marginTop:5}}
                  />

                </div>
              </Fade>

              <Edit
                onClick={links.go_to_my_account_edit}
                timeout={2200}
                open={profile_complete && entered_page}
                wrapperProps={{style:{top:-10}}}
                tip={'tell us what you need and what you serve'}
              />
              <Fade in={profile_complete && entered_page} timeout={1800}>
                <p className={classes.username}>Orders & Services</p>
              </Fade>
              <Fade in={profile_complete && entered_page} timeout={2300}>
                <div>
                  <Grid container justify='center'>
                    <div>
                      <div style={{...text3, fontSize:18}}>Orders I Require:</div>
                      <div style={{marginLeft:15, marginBottom:15}}>
                        {user.order_types.length ? user.order_types.map((order, i) =>
                          <div key={i} style={{...text2, fontSize:18}}>{order}</div>
                        ) : '-'}
                      </div>

                      <div style={{...text3, fontSize:18}}>Services I Provide:</div>
                      <div style={{marginLeft:15, marginBottom:15}}>
                        {user.service_types.length ? user.order_types.map((service, i) =>
                          <div key={i} style={{...text2, fontSize:18}}>{service}</div>
                        ) : '-'}
                      </div>
                    </div>
                  </Grid>

                  <LearnMore
                    tip={
                      "Here you can list what kind of services you demand " +
                      "so carriers and freelancers that qualify can easily " +
                      "find you and answer orders. " +
                      "You also list the type of services you provide " +
                      "so other shippers and carriers can find you " +
                      "and book you for orders. "
                    }
                    style={{marginTop:5}}
                  />

                </div>
              </Fade>

            </div>
            <Crop/>

          </Paper>
        </Slide>
      </SectionsLayout>
    </Page>
  );
}