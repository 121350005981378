import React from 'react';
import image from '../../assets/images/errors/ErrorPage404-04.jpg';
import truck from '../../assets/images/errors/404Trucking.png';
import {useHistory} from 'react-router-dom'
import route from '../../routes';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Navbar from '../../components/navbar/Navbar';
import NavPlaceholder from '../../components/navbar/NavPlaceholder';
import PopularServices from "../Gotrack/Home";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

const PageNotFound = styled.img`
  width: 735px;
  height: 490px;
  top: 0px;
  left: 0px;
  transform: translate(-10%, 0%);
`;

const Truck = styled.img`
  width: 400px;
  height: 250px;
  bottom: -80px;
  left: -200px;
  position: absolute;
`;

const Container = styled.div`
  position: relative;
  margin: auto;
  width: 400px;
  height: 400px;
  margin-top: 30px;
`;

const NoMatch = () => {
  const history = useHistory();
  const classes = useStyles();
  return [
    <NavPlaceholder key={0}/>,
    <Container key={1}>
      <PageNotFound src={image} alt=""/>
      <Truck src={truck} alt=""/>
      <Button
        onClick={() => {history.push(route.home)}}
        variant="contained"
        color="primary"
        className={classes.button}
      >
        Go Back
      </Button>
    </Container>,
    <Navbar key={2}/>,
  ]
};

export default NoMatch;