import {protocol, url} from './conf';
import axios from 'axios';

const headers = {headers: {
    'Content-Type': 'application/json',
    'X-Access-Token': '',
}};

const mock = data => ({
    status_code: 200,
    data: [],
});

export const get_search_results = async data => {
    // const url = `${protocol}://${url.services}/username/find`;
    // try {
    //     return await axios.post(url, data, headers).data;
    // } catch (error) {
    //     return error;
    // }
    return mock(data);
};