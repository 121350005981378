// // // url: 'https://cors-anywhere.herokuapp.com/https://gotrack.io',


export const env = {
  test_local: 'TEST LOCAL',
  production: 'PRODUCTION',
};

export const local_env = {
  env: env.test_local,
  base_url: 'http://localhost:3000',
  backend: 'http://localhost:5000',
  auth_url: 'http://localhost:5000/auth',
};

export const production_env = {
  env: env.production,
  base_url: 'https://gotrack.io',
  backend: 'https://api.gotrack.io',
  auth_url: 'https://api.gotrack.io/auth',
};

// export default local_env;
export default production_env;