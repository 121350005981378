import React, {useEffect, useState, useContext} from "react";
import {Formik, Form, Field} from 'formik';
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid';
import flag from '../../../assets/images/icons/flag.svg'
import city from '../../../assets/images/icons/city.svg'
import region_icon from '../../../assets/images/icons/region.svg'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import GradientButton from '../../custom/CustomButtons/GradientButton'
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import FaceIcon from '@material-ui/icons/Face';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import {useSpring, animated} from 'react-spring'
import Tooltip from '../../Tooltip';
import BusinessIcon from '@material-ui/icons/Business';
import {LinearProgress} from '@material-ui/core';
import {account_setup} from '../../../api/auth/account'
import sleep from "../../../utils/sleep";
import state from '../../../state'
import Swal from 'sweetalert2';
import {Input} from '../../custom/CustomInput/FormikInput'
import {edit_account_info} from '../../../api/auth/account'

interface Values {
  full_name: string;
  country: string;
  state: string;
  city: string;
  phone: string;
  company: string;
  job: string;
}

const validate = values => {
  const errors: Partial<Values> = {};

  if (!values.full_name)
    errors.full_name = 'Required';

  return errors;
};

export default ({set_loading}) => {
  const {entered_page} = useContext(state.entered_page);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const maxWidth = mobile?300:400;
  const {set_user, user} = useContext(state.user);

  const onSubmit = async (values, {setSubmitting}) => {
    set_loading(true);
    const res = await edit_account_info({
      set_user,
      full_name: values.full_name,
      country: values.country,
      region: values.region,
      city: values.city,
      phone_number: values.phone,
      company: values.company,
      job: values.job,
    });
    set_loading(false);
    if (res) {
      set_user({...user, ...res});
      await sleep(1000);
      Swal.fire({
        type: 'success',
        title: 'Account Updated',
        text: 'Your account is up to date! happy GOtracking',
        confirmButtonText: 'OK',
        timer: 6000,
      });
      await sleep(800);
    }
    setSubmitting(false);
  };

  return (
    <div>
      <Formik
        initialValues={{
          full_name: user.full_name?user.full_name:'',
          country: user.country?user.country:'',
          state: user.region?user.region:'',
          city: user.city?user.city:'',
          phone: user.phone_number?user.phone_number:'',
          company: user.company?user.company:'',
          job: user.job?user.job:'',
        }}
        validate={validate}
        onSubmit={onSubmit}
        render={({submitForm, isSubmitting, values, setFieldValue, handleChange, handleBlur}) => {

          const [region_style, set_region_style] = useState({height: 0, marginTop: 0, from: {height: 0, marginTop: 0}});
          const regionStyle = useSpring(region_style);

          useEffect(() => {
            if (!values.country) {
              set_region_style({height: 0, marginTop: 0, from: {height: region_style.from.height, marginTop: region_style.from.marginTop}})
            } else {
              set_region_style({height: 41, marginTop: 30, from: {height: region_style.from.height, marginTop: region_style.from.marginTop}})
            }
          }, [values]);

          return (
            <Grid container justify='center'>
            <Form style={{maxWidth}}>
              <Slide direction="right" timeout={1000} in={entered_page} mountOnEnter unmountOnExit>
                <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                  <Grid item>
                    <Tooltip title={'fill in your first and last name'}>
                      <FaceIcon style={{color: 'rgb(203, 139, 201)'}}/>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{flexGrow: 1}}>
                    <Field
                      component={Input}
                      label="full name*"
                      name="full_name"
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Slide>

              <Slide direction="right" timeout={1200} in={entered_page} mountOnEnter unmountOnExit>
                <div style={{marginBottom: 20, marginTop: 30}}>
                  <div style={{display: 'flex'}}>
                    <Tooltip title={
                      'select your country ' +
                      'so we can better address ' +
                      'your needs and connect you ' +
                      'with opportunities near your vicinity.'}>
                      <img src={flag} alt="" style={{width: 20, height: 20, margin: 2, marginTop: 10}}/>
                    </Tooltip>
                    <CountryDropdown
                      style={{
                        width: maxWidth - 30,
                        marginLeft: 7,
                        padding: 10,
                        fontSize: '1rem',
                        color: '#575757',
                      }}
                      name="country"
                      value={values.country}
                      onChange={(_, e) => handleChange(e)}
                      onBlur={handleBlur}
                    />
                  </div>

                  <Grow in={!!values.country} timeout={1000}>
                    <animated.div style={regionStyle}>
                      <div style={{display: 'flex'}}>
                        <Tooltip title={
                          'some countries have regions or states within them. ' +
                          'please select your region if you have one.'}>
                          <div style={{width: 20, height: 20, margin: 2, marginTop: 10}}>
                            <img src={region_icon} alt="" style={{width: 40, height: 40, position: 'relative', top: -7, right: 10}}/>
                          </div>
                        </Tooltip>
                        <RegionDropdown
                          style={{
                            width: maxWidth - 30,
                            marginLeft: 7,
                            padding: 10,
                            fontSize: '1rem',
                            color: '#575757',
                          }}
                          name="state"
                          country={values.country}
                          value={values.state}
                          onChange={(_, e) => handleChange(e)}
                          onBlur={handleBlur}
                        />
                      </div>
                    </animated.div>
                  </Grow>
                </div>
              </Slide>

              <Slide direction="right" timeout={1400} in={entered_page} mountOnEnter unmountOnExit>
                <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                  <Grid item>
                    <Tooltip title={
                      'write down where you live. ' +
                      'we consider this information private and don\'t sell/share it.'}>
                      <img src={city} alt="" style={{width: 20, height: 20, margin: 2}}/>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{flexGrow: 1}}>
                    <Field
                      component={Input}
                      label="city"
                      name="city"
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Slide>

              <Slide direction="right" timeout={1600} in={entered_page} mountOnEnter unmountOnExit>
                <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 15}}>
                  <Grid item>
                    <Tooltip title={
                      'write down your contact phone number, ' +
                      'we may want to verify you and give you a call or an SMS. ' +
                      'other potential clients may want to contact you this way. ' +
                      'your phone number is private to you and will not be shown ' +
                      'unless you specifically ask to share it.'}>
                      <ContactPhoneIcon style={{color: '#629ad4'}}/>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{flexGrow: 1}}>
                    <Field
                      component={Input}
                      label="phone"
                      name="phone"
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Slide>

              <Slide direction="right" timeout={1800} in={entered_page} mountOnEnter unmountOnExit>
                <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 30}}>
                  <Grid item>
                    <Tooltip title={
                      'write down your company ' +
                      'if you have one or represent one in any way, shape or form.'}>
                      <BusinessIcon style={{color: '#d47b90'}}/>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{flexGrow: 1}}>
                    <Field
                      component={Input}
                      label="company"
                      name="company"
                      type="text"
                    />
                  </Grid>
                </Grid>
              </Slide>

              <Grid container justify='center'>
                <Fade in={entered_page} timeout={1800}>
                  <GradientButton
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Save
                  </GradientButton>
                </Fade>
              </Grid>
              {isSubmitting && <LinearProgress style={{marginTop:20}}/>}
            </Form>
            </Grid>
          )
        }}
      />
    </div>
  )
};