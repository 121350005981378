import request from 'axios'
import {auth_config} from '../utils/configs'
import {auth_url} from '../utils/urls'
import {silent_auth_call} from "../utils/auth_calls";
import type {PostType} from "../../types";

export const get_messages = () => request.get(auth_url+'/messages', auth_config);
export const get_notifications = () => request.get(auth_url+'/notifications', auth_config);

export const get_points_notification = silent_auth_call(async ():PostType => {
  const res = await request.post(auth_url+'/notifications/points', {}, auth_config);
  return {
    notification: res.data.notification,
    points: res.data.points,
    points_delta: res.data.points_delta,
  }
});

export const delete_notifications = silent_auth_call(async ({ids}):PostType => {
  await request.post(auth_url+'/notifications/delete', {ids}, auth_config);
  return true;
});
