import request, {AxiosResponse, AxiosError} from 'axios'
import sleep from './sleep'

// https://medium.com/better-programming/how-to-detect-the-location-of-your-websites-visitor-using-javascript-92f9e91c095f
// https://stackoverflow.com/questions/6548504/how-can-i-get-city-name-from-a-latitude-and-longitude-point

export const geolocationLookUpResponse = {
  status: null,
};

const options = {
  enableHighAccuracy: true,
  timeout: 1000,
  maximumAge: 0
};

async function displayLocation(latitude, longitude) {
  try {
    const res: AxiosResponse = await request.get(`http://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true`);
    geolocationLookUpResponse.status = res.data.error_message ? 500 :res.status;
    geolocationLookUpResponse.data = res.data;
  }
  catch (e) {
    const res: AxiosError = e;
    geolocationLookUpResponse.status = res.response.status;
    geolocationLookUpResponse.data = res.response.data;
  }
}

// for when getting location is a success
const successCallback = function(position){
  const x = position.coords.latitude;
  const y = position.coords.longitude;
  displayLocation(x,y);
};

// for when getting location results in an error
const errorCallback = function(error){
  let errorMessage = 'Unknown Error occurred while retrieving reverse-geolocation';
  switch(error.code) {
    case 1: errorMessage = 'Retrieving reverse-geolocation permission denied'; break;
    case 2: errorMessage = 'Reverse-geolocation position is unavailable'; break;
    case 3: errorMessage = 'Request timed out while retrieving reverse-geolocation'; break;
  }
  geolocationLookUpResponse.status = 500;
  geolocationLookUpResponse.data = {errorMessage, error};
};

export function geolocationLookUp () {
  if ("geolocation" in navigator) {
    // check if geolocation is supported/enabled on current browser
    navigator.geolocation.getCurrentPosition(
      successCallback,
      errorCallback,
      options,
    )
  } else {
    // geolocation is not supported
    // get your location some other way
    geolocationLookUpResponse.status = 400;
    geolocationLookUpResponse.data = {errorMessage: 'geolocation is not enabled on this browser'};
  }
}

export type locationType = {|
  status: string, // "success"
  country: string, // "Israel"
  countryCode: string, // "IL"
  region: string, // "D"
  regionName: string, // "Southern District"
  city: string, // "Ashdod"
  zip: string, // ""
  lat: number, // 31.7915
  lon: number, // 34.6497
  timezone: string, // "Asia/Jerusalem"
  isp: string, // "Hot-Net internet services Ltd."
  org: string, // "Hotnet Broadband"
  as: string, // "AS12849 Hot-Net internet services Ltd."
  query: string, // "77.139.16.6"
|}

export function ipLookUp () {
  return request.get('http://ip-api.com/json')
}

export async function get_location():locationType {
  if (geolocationLookUpResponse.status === null) {
    try {
      const response = await ipLookUp();
      geolocationLookUpResponse.status = response.status;
      geolocationLookUpResponse.data = response.data;
    }
    catch (e) {
      console.log('ipLookUp Request failed', e);

      geolocationLookUp();
      while (geolocationLookUpResponse.status === null) {await sleep(1000)}
      if (geolocationLookUpResponse.status === 200) {
        // const response = geolocationLookUpResponse.data;
        // const address = response.results[0];
        // console.log(address.formatted_address);
      }
      else {console.log(
        geolocationLookUpResponse.status, 
        geolocationLookUpResponse.data)}
    }
  }
  if (geolocationLookUpResponse.status !== 200)
    return false;
  return geolocationLookUpResponse.data
}
