import React, {useState, useContext, useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import state from '../../state'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import routes from '../../routes'
import {useHistory, useLocation} from 'react-router-dom'
import {SlideDialogDown} from '../transitions/DialogTransitions'

export default () => {
  const links = useContext(state.links);
  const width: number = useContext(state.size).size.width;
  const profile_complete: boolean = useContext(state.profile_complete).profile_complete;
  const [open, set_open] = useState(false);

  const location = useLocation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => set_open(false);
  useEffect(() => {profile_complete === false && set_open(true)}, [profile_complete]);
  useEffect(() => {location.pathname === routes.my_account_setup && handleClose()}, [location, profile_complete]);

  return (
    <Dialog
      open={open}
      TransitionComponent={SlideDialogDown}
      keepMounted
      onClose={handleClose}
      aria-labelledby="incomplete sign in"
      aria-describedby="please fill in your profile details"
      style={{zIndex:1000}}
      fullScreen={fullScreen && width < 560}
    >
      <Grid container justify='center' style={{maxWidth:376, margin:'auto'}}>
        <DialogTitle style={{textAlign: 'center', margin: 'auto', marginTop: 0}}>
          COMPLETE YOUR PROFILE
        </DialogTitle>
        <IconButton
          color="default"
          aria-label="cancel"
          style={{position:'absolute', right:0, top: fullScreen && width < 560 ? 80 :0}}
          onClick={handleClose}
        >
          <CancelIcon/>
        </IconButton>
        <DialogContent style={{padding: fullScreen && width < 560 ? 0 :20, paddingTop:0, maxWidth:336}}>
          <Grid container justify='center'>
            <div style={{textAlign:'center', width:'100%', marginBottom:10}}>
              just few more steps
            </div>
            <Button variant="contained" color="primary" onClick={()=>{handleClose();links.go_to_my_account_setup()}}>
              Complete My Profile
            </Button>
          </Grid>
        </DialogContent>
      </Grid>

    </Dialog>
  );
}