import React, {useContext, useEffect} from "react";
import {
  Link,
  // animateScroll as scroll,
} from "react-scroll";
import state from '../../state'
import ids from '../../ids'
import sleep from '../../utils/sleep'

const delay = 200;
const max_wait = 90*1000;

const smooth_scroll_to = async (id) => {
  let wait = 0;
  while (wait < max_wait) {
    if (document.getElementById(id)) {
      document.getElementById(ids.scroller).click();
      return true;
    }
    else {
      await sleep(delay);
      wait = wait + delay;
    }
  }
  return false;
};

export default () => {
  const {scrollTo, set_scrollTo} = useContext(state.scrollTo);

  // const scrollToTop = () => {
  //   scroll.scrollToTop();
  // };

  useEffect(() => {
    const onChange = async () => {
      const didScroll = await smooth_scroll_to(scrollTo.to);
      if (didScroll)
        set_scrollTo({...scrollTo, to:''})
    };
    if (scrollTo.to)
      onChange()
  }, [scrollTo]);

  return (
    <div
      style={{
        position:'fixed',
        width:0,
        height:0,
        zIndex: -1000
      }}>
      <Link
        id={ids.scroller}
        {...scrollTo}
      />
    </div>
  );
}