export default {
  // GLOBAL
  root: 'root',
  navbar: 'navbar',
  search: 'search',
  scroller: 'scroller',
  footer: 'footer',

  // App Bar
  account_menu: 'account-menu',
  account_menu_mobile: 'account-menu-mobile',

  // SIGNIN
  signin_title: 'signin_title',
  google_login: 'google-login-for-icon',
  facebook_login: 'facebook-login-for-icon',
  instagram_login: 'instagram-login-for-icon',

  // HOME
  myInput: 'myInput',
  copy_msg: 'copy-msg',
  share_and_win_popup_bg: 'share-and-win-popup-bg',
  share_blog: 'share-blog',
  close_share_frame: 'close-share-frame',
  lz: 'lz',
  categorySelect: 'categorySelect',
  loadingmore: 'loadingmore',
  newsemail: 'newsemail',
  newsletterSign: 'newsletterSign',

  // DAILY POST
  daily_post: 'daily-post',
  daily_promote: 'daily-promote',

  // NEW POST
  new_post_promote: 'new-post-promote',

  reward_canvas: 'reward-canvas',

  // MEMBERS
  members_section_2: 'members_section-2',
  members_section_3: 'members_section-3',
  members_section_4: 'members_section-4',
  members_section_5: 'members_section-5',

  uploadpost_img: 'uploadpost-img',
  uploadpost_upld: 'uploadpost-upld',

  upload_profile_photo: 'upload-profile-photo',
  new_driver_profile_form_root: 'new-driver-profile-form-root',
  new_driver_form_step: 'new-driver-form-step',

  search_category_menu: 'search-category-menu',
  search_category_popover: 'search-category-popover',
}