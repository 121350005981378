import React from 'react'
import Button from '@material-ui/core/Button'
import {signinStyles} from '../../../assets/jss/signin'
import {withStyles} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

const ColorCircularProgress = withStyles({
  root: {
    color: 'rgba(50,50,50,0.5)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -36,
    marginLeft: -72,
  },
})(CircularProgress);

export const SubmitBlue = withStyles({
  root: {
    color:'#fff',
    height: 40,
    backgroundColor: '#03a9f4',
    '&:hover': {
      backgroundColor: '#0288c4',
    },
  },
})(Button);

export const SubmitWhite = withStyles({
  root: {
    color:'#999',
    height: 40,
    backgroundColor: '#e7e7e7',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
})(Button);

export const SubmitRose = withStyles({
  root: {
    color:'#fff',
    height: 40,
    backgroundColor: '#f488d0',
    '&:hover': {
      backgroundColor: '#c4579f',
    },
  },
})(Button);

export const SubmitColors = {
  blue: SubmitBlue,
  rose: SubmitRose,
  white: SubmitWhite,
};

const Submit = ({onSubmit, color = 'blue', text, loading, disabled, ...props}) => {
  const classes = signinStyles();
  const Submit = SubmitColors[color];
  return (
    <div {...props}>
      <Submit
        onClick={onSubmit}
        className={classes.buttons}
        disabled={disabled===undefined?false:disabled}
      >
        {text}
      </Submit>
      {loading &&
        <div style={{position:'relative'}}>
          <ColorCircularProgress size={32} />
        </div>
      }
    </div>
  )
};

export default Submit
