import React from "react";
import Countdown from "react-countdown";


// https://github.com/ndresx/react-countdown
// https://codesandbox.io/s/sad-zhukovsky-hs7hc
export default ({date, onComplete, onCountDown}) => {
  return (
    <Countdown
      date={date}
      renderer={({ hours, minutes, seconds, completed }) => {
        if (completed)
          return onComplete();
        return onCountDown({
          counter:`${hours}:${minutes}:${seconds}`
        })
      }}
    />
  )
}