import React, {useEffect, useState} from "react";
import Slide from '@material-ui/core/Slide';
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails} from '../licences/Expansion'
import DocumentPhoto from '../../../custom/CustomUpload/DocumentPhoto'
import {validSizeMB, validPhotoExtension} from '../../../../validations/file_validations'
import type {Function, Permits, Permit, FileChangeProps} from '../../../../types'
import FormHelperText from '@material-ui/core/FormHelperText'

export interface Props {
  open: boolean,
  permits: Permits,
  set_permits: Function,
}

export default (props:Props) => {
  const open = props.open;
  const permits = props.permits;
  const set_permits = props.set_permits;

  const [is_open, set_is_open] = useState(false);
  const [permit_list, set_permit_list] = useState([]);

  useEffect(() => set_is_open(open), [open]);
  useEffect(() => set_permit_list(Object.values(permits)), [permits]);

  const addPermit = (props:FileChangeProps) => {
    const {file, url, name, extension, handleClear} = props;

    if (!name) return;
    handleClear();

    const update = {...permits};
    update[name] = {name, file, extension, url};
    set_permits(update)
  };

  const deletePermit = (props:FileChangeProps) => {
    const {name} = props;

    if (!name) return;

    const update = {...permits};
    delete update[name];
    set_permits(update)
  };

  const updatePermit = (props:FileChangeProps, permit:Permit) => {
    const {name, file, extension, url} = props;
    const {old_name} = permit;

    if (!name) return;

    const update = {...permits};
    delete update[old_name];
    update[name] = {name, file, extension, url};
    set_permits(update)
  };

  return (
    <Slide direction="right" timeout={1200} in={is_open} mountOnEnter unmountOnExit>
      <div style={{marginBottom: 15}}>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="extra-price-options"
          >
            <Typography variant="h6" gutterBottom style={{fontWeight:400}}>Driver Permits</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{marginLeft: 5, marginBottom:25}}>

              {permit_list.map((permit, index) =>
                <Slide key={index} direction="right" timeout={1200+index} in={open} mountOnEnter unmountOnExit>
                  <div style={{padding:15, maxWidth:280}}>
                    <legend style={{fontSize:14, marginBottom:5}}>{permit.name}</legend>
                    <DocumentPhoto
                      values={permit}
                      removeButtonProps={{color: "danger", round: true}}
                      validate={(file:File) => {
                        return validSizeMB(file, 2)
                          && validPhotoExtension(file);
                      }}
                      onRemove={()=>deletePermit(permit)}
                      disableName={true}
                    />
                  </div>
                </Slide>
              )}

              <Slide direction="right" timeout={1200} in={open} mountOnEnter unmountOnExit>
                <div style={{padding:15, maxWidth:280}}>
                  <DocumentPhoto
                    label={'permit name'}
                    addButtonProps={{color: "twitter", round: true}}
                    addButtonText={'Add Permit'}
                    changeButtonProps={{color: "rose", round: true}}
                    removeButtonProps={{color: "danger", round: true}}
                    validate={(file:File) => {
                      return validSizeMB(file, 2)
                        && validPhotoExtension(file);
                    }}
                    onSubmit={addPermit}
                    showDefaultImage={false}
                  />
                </div>
              </Slide>

            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

      </div>
    </Slide>
  )
};
