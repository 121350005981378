import React, {Fragment, useEffect, useState} from "react";
import VisibilitySensor from "react-visibility-sensor";
import sleep from '../utils/sleep'

import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles({
  relative: {
    position: 'relative',
  },

  absolute: {
    position: 'absolute',
    height: 40,
  },

  transparent: {
    color: 'transparent',
  },
});

export const Hide = ({ delay=0, children}) => {
  const [show, set_show] = useState(false);

  useEffect(() => {(async()=>{
    await sleep(delay);
    set_show(true);
  })()}, [delay]);

  return show && children
};

export default ({ on=false, delay=0, children, offset={bottom: 0}, ...props}) => {
  const classes = useStyles();
  const [triggered, set_triggered] = useState(false);
  const mount = {is_mounted:true};

  const onTrigger = async (isVisible) => isVisible && [await sleep(delay), mount.is_mounted ? set_triggered(true): null];

  useEffect(() => {return () => mount.is_mounted = false}, []);

  useEffect(() => {
    if (on)
      onTrigger(true);
  }, [on]);

  return (
    <Fragment>
      {!triggered &&
      <div key={0} className={classes.relative}>
        <div className={classes.absolute} style={offset}>
          <VisibilitySensor active={!triggered} onChange={onTrigger} {...props}>
            <div className={classes.transparent}>.</div>
          </VisibilitySensor>
        </div>
      </div>
      }
      {children({triggered})}
    </Fragment>
  )
}