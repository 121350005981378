import request from 'axios'
import {url} from '../utils/urls'
import {generic_error} from '../utils/errors'
import {base_config} from '../utils/configs'
import {handle_refresh} from "./refresh";
import {handle_login_success} from "../utils/success";


export const password_recovery_1 = async (email) => {
  try {
    await request.post(url+'/password_recovery_1', {email}, base_config);
    return true
  } catch (e) {
    return generic_error(e);
  }
};

export const password_recovery_2 = async (email, password, token, set_user, set_snackbar) => {
  try {
    const response = await request.post(url+'/password_recovery_2', {email, password, token}, base_config);
    handle_refresh(response);
    return handle_login_success(response, set_user, set_snackbar)
  } catch (e) {
    return generic_error(e);
  }
};
