import React from "react";

export default ({...props}) => {
  return (
    <div style={{position:'relative', zIndex: -1, width: '100vw'}}>
      <div
        style={{
          height: '1200px',
          top: '-800px',
          backgroundColor: 'rgba(209,216,237,0.22)',
          position: 'absolute',
          transform: 'skewY(-10deg)',
          width:'100%',
        }}
        {...props}
      />
    </div>
  )
}