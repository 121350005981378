import { cardTitle } from "../../material-dashboard-pro-react.jsx";
import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  profileContainer: {
    padding: 20
  },
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  cardCategory: {
    marginTop: "10px",
    color: "#999999 !important",
    textAlign: "center"
  },
  description: {
    color: "#999999",
    width: 250,
  },
  updateProfileButton: {
    float: "right"
  },
  cardUsername: {
    color: '#454545',
    WebkitBoxDirection: 'normal',
    padding: '0',
    border: '0',
    font: 'inherit',
    verticalAlign: 'baseline',
    fontSize: '28px',
    lineHeight: '32px',
    margin: '-5px 0 -6px',
    fontWeight: '300',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif',
  },
  cardName: {
    // font: 'normal normal normal 18px/1.4em arial,\'ｍｓ ｐゴシック\',\'ms pgothic\',\'돋움\',dotum,helvetica,sans-serif',
    // color: '#555555',
    // pointerEvents: 'auto',
    // lineHeight: 'normal',
    // letterSpacing: 'normal',
    // textAlign: 'left',
    // fontSize: '20px',
    // margin: '0',
    // padding: '0',
    // border: '0',
    // outline: '0',
    // marginBottom: 25,
    // verticalAlign: 'baseline',
    // background: '0 0',
    // fontFamily: 'open sans,sans-serif',

    color: 'rgba(38,38,38,1)',
    WebkitBoxDirection: 'normal',
    margin: 0,
    padding: 0,
    border: '0',
    font: 'inherit',
    verticalAlign: 'baseline',
    display: 'inline',
    fontWeight: '600',
    fontSize: '15px',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif',
  },

  cardPublicStats: {
    color: 'rgba(38,38,38,1)',
    margin: 0,
    padding: 0,
    border: '0',
    verticalAlign: 'baseline',
    listStyle: 'none',
    display: 'flex',
    WebkitBoxOrient: 'horizontal',
    WebkitBoxDirection: 'normal',
    flexDirection: 'row',
  },
  cardPublicStat: {
    listStyle: 'none',
    WebkitBoxDirection: 'normal',
    margin: '0',
    padding: '0',
    border: '0',
    font: 'inherit',
    verticalAlign: 'baseline',
    fontSize: '16px',
    marginRight: '40px',
    textDecoration: 'none',
    color: 'inherit',
  },
  cardPublicStatNumber: {
    listStyle: 'none',
    WebkitBoxDirection: 'normal',
    margin: '0',
    padding: '0',
    border: '0',
    font: 'inherit',
    verticalAlign: 'baseline',
    color: 'rgba(38,38,38,1)',
    fontWeight: '600',
  }
})