import React from "react";
import Grid from "@material-ui/core/Grid";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import beautifulNumber from "../utils/beautifulNumber";
import {makeStyles} from "@material-ui/core/styles";
import {do_nothing_function} from'../utils/do_nothing_function'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles({
  root: {width:24},
  text: {minHeight: 7, minWidth:7, lineHeight: '7px'},
});

export default ({likes, onClick=do_nothing_function, ...props}) => {
  const classes = useStyles();
  return (
    <Grid container justify='center' className={classes.root} {...props}>
      <IconButton onClick={onClick} size={'small'}>
        <FavoriteBorderIcon color={'inherit'} fontSize={'inherit'}/>
      </IconButton>
      <small className={classes.text}>{likes!==undefined && likes > 0 ? beautifulNumber(likes) : ''}</small>
    </Grid>
  )
}