import {makeStyles} from "@material-ui/core/styles";
import {deepOrange} from "@material-ui/core/colors";

export const useAsynchronousStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },

  paper: {
    width: 'calc(100vw - 0px)',
    height: 'calc(100vh - 125px)',
    position:'absolute',
    left: 0,
    top: 110,
    boxShadow: 'none',
    borderRadius: 0,
    '& ul': {
      maxHeight: 'calc(100vh - 170px)'
    }
  },

  drawPaper: {
    // width: '100%',
    height: '100%',
  },

}));

export const useCardStyles = makeStyles((theme) => ({
  root: {
    width:'100%',
    minWidth: 300,
    maxWidth: 600,
    margin: 5,
    marginBottom:5,
    padding: 10,
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    }
  },

  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },

  expandOpen: {
    transform: 'rotate(180deg)',
  },

  avatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));