import React, {useState, useRef, useEffect} from "react";
import Button from "../CustomButtons/Button.jsx";
import defaultImage from "../../../assets/images/image_placeholder.jpg";
import TextField from "@material-ui/core/TextField";
import type {Function, OnFileChange, ValidateFile, FileChangeProps} from '../../../types'
import get_filename from '../../../utils/get_filename'
import get_filextension from '../../../utils/get_filextension'
import PDF from '../PDF'
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {do_nothing_function} from '../../../utils/do_nothing_function'
import InputAdornment from "@material-ui/core/InputAdornment";
import CheckIcon from "@material-ui/icons/Check";
import FormHelperText from "@material-ui/core/FormHelperText";

interface Props {
  values?: FileChangeProps,
  label?: string,
  addButtonProps?: Object,
  changeButtonProps?: Object,
  removeButtonProps?: Object,
  addButtonText?: string,
  changeButtonText?: string,
  removeButtonText?: string,
  showDefaultImage?: boolean,
  disableName?: boolean,
  onRemove?: Function,
  validate?: ValidateFile,
  onChange?: OnFileChange,
  onSubmit?: OnFileChange,
  onCancel?: OnFileChange,
}

export const defaultState = () => {
  const state:FileChangeProps = {};
  state.extension = null;
  state.name = '';
  state.file = null;
  state.url = defaultImage;
  return state
};

const initialValues = defaultState();

export default (
  {
    values=initialValues,
    label,
    addButtonProps,
    changeButtonProps,
    removeButtonProps,
    addButtonText='Select image',
    changeButtonText='Change',
    removeButtonText='Remove',
    validate=do_nothing_function,
    onRemove=do_nothing_function,
    onChange=do_nothing_function,
    showDefaultImage=true,
    onSubmit,
    onCancel,
    disableName=false,
    ...props
  }: Props) => {
  const ref = useRef(null);
  const [expanded, set_expanded] = useState(false);

  const onExpand = () => {
    set_expanded(true);
  };

  const onShrink = () => {
    set_expanded(false);
  };

  const [state, setState] = useState(values);
  useEffect(()=>{setState(values)},[values]);

  const handleImageChange = async (event) => {
    event.preventDefault();
    const reader = new FileReader();
    let file: File = event.target.files[0];

    const name = get_filename(file);
    const extension = get_filextension(file);
    file = new File([file], `${name}.${extension.toLowerCase()}`, {type: file.type});

    if (!validate(file))
      return;

    reader.onloadend = () => {
      const update = {
        ...state,
        name: state.name === '' ? name : state.name,
        extension: extension,
        file: file,
        url: reader.result
      };
      setState(update);
      onChange({...update, handleRemove});
    };
    reader.readAsDataURL(file);

  };

  const handleClick = () => ref.current.click();

  const handleRemove = () => {
    setState({
      ...state,
      file: null,
      extension: null,
      url: defaultImage
    });
    ref.current.value = null;
    onRemove()
  };

  const handleClear = () => {
    setState(initialValues);
    ref.current.value = null;
  };

  if (expanded)
    return (
      <Dialog open={expanded} onClose={onShrink}>
        <div style={{position:'relative'}}>
          {state.extension === 'pdf'
            ? <PDF file={state.url}/>
            : <img src={state.url} alt="..." />
          }
          {state.url !== defaultImage &&
            <IconButton onClick={onShrink} style={{position: 'absolute', top: 0, right: 0}}>
              <CloseIcon/>
            </IconButton>
          }
        </div>
      </Dialog>
    );

  return (
    <div {...props}>
      {state.file !== null
        ? disableName
          ? null :
            <TextField
              label={label}
              value={state.name}
              onChange={event => {setState({...state, name: event.target.value})}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{display:'flex'}}>
                      {state.name && onSubmit &&
                      <div>
                        <IconButton aria-label="save license" onClick={()=>onSubmit({...state, handleRemove, handleClear})}>
                          <CheckIcon/>
                        </IconButton>
                        <FormHelperText style={{textAlign:'center', position:'relative', bottom:15, margin: 0}}>
                          submit
                        </FormHelperText>
                      </div>
                      }
                      {state.name && onCancel &&
                      <div>
                        <IconButton aria-label="cancel" onClick={()=>onCancel({...state, handleRemove, handleClear})}>
                          <CloseIcon/>
                        </IconButton>
                        <FormHelperText style={{textAlign:'center', position:'relative', bottom:15, margin: 0}}>
                          cancel
                        </FormHelperText>
                      </div>
                      }
                    </div>
                  </InputAdornment>
                )
              }}
            />
        : null
      }
      <div className="fileinput text-center">
        <input type="file" onChange={handleImageChange} ref={ref} />
        <div className={"thumbnail"} style={{position:'relative'}}>
          {state.url === defaultImage && !showDefaultImage ? null : state.extension === 'pdf'
            ? <PDF file={state.url}/>
            : <img src={state.url} alt="..." />
          }
          {state.url !== defaultImage &&
            <IconButton onClick={onExpand} style={{position: 'absolute', top: 0, right: 0}}>
              <SettingsOverscanIcon/>
            </IconButton>
          }
        </div>
        <div>
          {state.file === null
            ?
            addButtonProps &&
              <Button {...addButtonProps} onClick={handleClick}>
                {addButtonText}
              </Button>
            :
            <span>
              {changeButtonProps &&
                <Button {...changeButtonProps} onClick={handleClick}>
                  {changeButtonText}
                </Button>
              }
              {removeButtonProps &&
                <Button
                  {...removeButtonProps}
                  onClick={handleRemove}
                >
                  <i className="fas fa-times"/> {removeButtonText}
                </Button>
              }
            </span>
          }
        </div>
      </div>
    </div>
  );
}
