import {createContext} from "react";


export default {
  user: createContext(null),
  snackbar: createContext(null),
  notifications: createContext(null),
  messages: createContext(null),
  size: createContext(null),
  sidebar: createContext(null),
  signin: createContext(null),
  page_loading: createContext(null),
  backdrop: createContext(null),
  scrollTo: createContext(null),
  share_buttons: createContext(null),
  category_tab: createContext(null),
  search_dropdown: createContext(null),
  spring_toast: createContext(null),
  is_logged: createContext(null),
  profile_complete: createContext(null),
  links: createContext(null),
  entered_page: createContext(null),
  search: createContext(null),
  loaded_google_maps: createContext(null),
}
