import Typography from "@material-ui/core/Typography";
import logo from "../assets/images/truck.svg";
import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import VisibilityTrigger from './VisibilityTrigger'
import state from '../state'

const useStyles = makeStyles(theme => ({
  title: {
    cursor: 'pointer',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  relative: {
    position:'relative',
    width:'100px',
  },

  absolute: {
    position:'absolute',
    top:'-30px',
    left:'10px',
  },

  relative2: {
    position: 'relative',
    width: "100px",
    height: "50px",
  },

  absolute2: {
    position: 'absolute',
    width: "100%",
    height: "100%",
    left: '5px',
  },

  absolute3: {
    position: 'absolute',
    fontSize: '28px',
    color: 'rgb(255, 255, 255)',
    top: '13px',
    left: '-10px',
    fontStyle: 'normal',
    fontFeatureSettings: '"pnum"',
    fontVariantNumeric: 'proportional-nums',
    textTransform: 'uppercase',
    letterSpacing: '0.025em',
    lineHeight: '32px',
    fontWeight: 'bold',
  },

  absolute4: {
    position: 'absolute',
    fontSize: '17px',
    color: 'rgb(255, 255, 255)',
    top: '37px',
    left: '-8px',
    letterSpacing: '0.55em',
  },

}));

export default () => {
  const classes = useStyles();
  const links = useContext(state.links);
  return (
    <VisibilityTrigger delay={1000}>
      {({triggered}) =>
        <Fade in={triggered} timeout={2000}>
          <Typography className={classes.title} onClick={links.go_home} variant="h6">
            <div className={classes.relative}>
              <div className={classes.absolute}>
                <div className={classes.relative2}>
                  <Slide direction="right" in={triggered} mountOnEnter unmountOnExit timeout={2300}>
                    <img src={logo} className={classes.absolute2} alt=""/>
                  </Slide>
                  <Slide direction="right" in={triggered} mountOnEnter unmountOnExit timeout={2500}>
                    <div className={classes.absolute3}>GO</div>
                  </Slide>
                  <Slide direction="up" in={triggered} mountOnEnter unmountOnExit timeout={2500}>
                    <div className={classes.absolute4}>track</div>
                  </Slide>
                </div>
              </div>
            </div>
          </Typography>
        </Fade>
      }
    </VisibilityTrigger>
  )
}