import Pagination from "@material-ui/lab/Pagination/Pagination";
import React from "react";
import ButtonLoader from './loaders/ButtonLoader'


export const Paginator = ({last_page, page_number, loading, set_page_number, ...props}) => {
  return (
    <ButtonLoader loading={loading} {...props}>
      <Pagination
        style={{height: 44, background: 'transparent', minHeight: 'unset'}}
        count={last_page}
        variant="outlined"
        page={page_number}
        onChange={(event, page) => set_page_number(page)}
      />
    </ButtonLoader>
  )
};
