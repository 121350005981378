import React, {useEffect, useState} from "react";
import Slide from '@material-ui/core/Slide';
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails} from '../licences/Expansion'
import DocumentPhoto from '../../../custom/CustomUpload/DocumentPhoto'
import {validSizeMB, validPhotoExtension} from '../../../../validations/file_validations'
import type {FileChangeProps} from "../../../../types";

export default ({open, extras, set_extras}) => {
  const [is_open, set_is_open] = useState(false);
  const [permit_list, set_permit_list] = useState([]);

  useEffect(() => set_is_open(open), [open]);
  useEffect(() => set_permit_list(Object.keys(extras)), [extras]);

  return (
    <Slide direction="right" timeout={1500} in={is_open} mountOnEnter unmountOnExit>
      <div style={{marginBottom: 15}}>
        <div style={{marginLeft: 5, marginBottom:25}}>
          <div style={{padding:15, maxWidth:280}}>
            <legend>Traffic Violation Record</legend>
            <DocumentPhoto
              addButtonProps={{color: "rose", round: true}}
              changeButtonProps={{color: "rose", round: true}}
              removeButtonProps={{color: "danger", round: true}}
              validate={(file:File) => {
                return validSizeMB(file, 2)
                  && validPhotoExtension(file);
              }}
              onChange={(props:FileChangeProps) => {

              }}
              disableName={true}
            />
          </div>
        </div>
      </div>
    </Slide>
  )
};
