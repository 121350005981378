import React, {useEffect, useState} from "react";
import Slide from '@material-ui/core/Slide';
import TextField from "@material-ui/core/TextField";
import WarningIcon from '@material-ui/icons/Warning';
import Grid from "@material-ui/core/Grid";
import Tooltip from "../../../Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  close: {maxHeight: 0},
  open: {maxHeight: 10000},

  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    // maxWidth: 300,
    // width:'100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default ({open, limitations={}, set_limitations}) => {
  const classes = useStyles();

  const [limitation_list, set_limitation_list] = useState([]);
  const [limitation, set_limitation] = useState('');

  useEffect(() => set_limitation_list(Object.keys(limitations)), [limitations]);

  return (
    <Slide direction="right" timeout={1800} in={open} mountOnEnter unmountOnExit>
      <div style={{marginBottom: 15}}>
        <Grid container spacing={1} alignItems="flex-end" style={{marginBottom: 8}}>
          <Grid item>
            <Tooltip title={'add in your license limitations, such as: glasses/hearing aid/diabetes/etc. '}
            >
              <WarningIcon/>
            </Tooltip>
          </Grid>
          <Grid item style={{flexGrow: 1}}>
            <TextField
              fullWidth
              label={'license limitations'}
              value={limitation}
              onChange={event=>set_limitation(event.target.value)}
              InputProps={{
                endAdornment: limitation?(
                  <InputAdornment position="end">
                    <div style={{display:'flex'}}>
                      <IconButton
                        aria-label="add limitation"
                        onClick={() => {
                          if (!limitation_list.includes(limitation)) {
                            const update = {...limitations};
                            update[limitation] = true;
                            set_limitations(update);
                          }
                          set_limitation('')
                        }}
                      >
                        <CheckIcon/>
                      </IconButton>
                      <IconButton
                        aria-label="cancel limitation"
                        onClick={()=>set_limitation('')}
                      >
                        <CloseIcon/>
                      </IconButton>
                    </div>

                  </InputAdornment>
                ):undefined
              }}
            />
          </Grid>
        </Grid>
        {limitation_list.length ?
          <FormControl className={classes.formControl} style={{paddingLeft:32}}>
            <div className={classes.chips}>
              {limitation_list.map((value, index) => (
                <Chip
                  key={index}
                  label={value}
                  className={classes.chip}
                  onDelete={()=>{
                    const update = {...limitations};
                    delete update[value];
                    set_limitations(update);
                  }}
                />
              ))}
            </div>
          </FormControl>:null
        }
      </div>
    </Slide>
  )
};
