import React, {useState, useContext} from 'react'
import {signinStyles} from '../../assets/jss/signin'
import {login} from '../../api/public/login'
import state from '../../state'
import ForgotPassword from './ForgotPassword'
import Submit from '../custom/CustomButtons/Submit'
import BaseInput from '../custom/CustomInput/BaseInput'
import PasswordInput from '../custom/CustomInput/PasswordInput'
import ReCAPTCHA from "react-google-recaptcha"
import {captcha_key} from '../../variables'
import {
  captcha_validation,
  email_validation,
  password_validation,
  set_error,
} from '../../validations/signin_validations'
import '../../assets/scss/recaptcha.scss'

export default ({
  openPasswordRecovery,
  setOpenPasswordRecovery,
  email,
  password,
  onEmailChange,
  onPasswordChange,
  setPassword,
}) => {
  const set_user = useContext(state.user).set_user;
  const set_snackbar = useContext(state.snackbar).set_snackbar;

  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState('');

  const reset_captcha = () => {
    setCaptcha('');
    window.grecaptcha.reset();
  };

  const onCaptcha = (value) => {
    setCaptcha(value);
  };

  const onSignIn = async (new_password) => {
    if (loading)
      return;

    try {
      email_validation(email);
      password_validation(password);
      captcha_validation(captcha);
    } catch (e) {
      return set_snackbar(set_error(e.message))
    }

    setLoading(true);
    await login(email, new_password?new_password:password, captcha, set_user, set_snackbar);
    setLoading(false);
    reset_captcha();
  };

  const classes = signinStyles();
  return (
    <div>
      <BaseInput
        label='email'
        value={email}
        onChange={onEmailChange}
      />
      <PasswordInput
        style={{marginBottom:10}}
        label='password'
        value={password}
        onChange={onPasswordChange}
        helper={
          <ForgotPassword
            classes={classes}
            openPasswordRecovery={openPasswordRecovery}
            setOpenPasswordRecovery={setOpenPasswordRecovery}
            set_snackbar={set_snackbar}
            email={email}
            setPassword={setPassword}
            onSignIn={onSignIn}
          />
        }
      />
      <ReCAPTCHA
        sitekey={captcha_key}
        onChange={onCaptcha}
      />
      <Submit
        style={{marginTop:5}}
        onSubmit={()=>onSignIn()}
        text={"Sign In"}
        loading={loading}
      />
    </div>
  );
};