import { makeStyles } from '@material-ui/core/styles'
import style_vars from '../../assets/jss/style_vars'


export default makeStyles({
  signin: {
    cursor: 'pointer',
    textShadow: 'rgb(0, 0, 0) 0px 5px 2px, rgb(0, 0, 0) 0px 5px 5px, rgb(0, 0, 0) 0px 5px 20px, rgb(0, 0, 0) 0px 5px 25px',
    '&:hover': {
      textShadow: [
        '0 0 5px #fff',
        '0 0 10px #fff',
      ].join(', '),
    },
  }
});