import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import { amber, green } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'
import style_vars from '../../assets/jss/style_vars'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  primary: CheckCircleIcon,
  default: InfoIcon,
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: style_vars.colors.btn_green,
  },
  default: {
    backgroundColor: 'rgb(50, 50, 50)',
  },
  error: {
    backgroundColor: '#d32f2f',
  },
  info: {
    backgroundColor: '#2196f3',
  },
  primary: {
    backgroundColor: '#dcd7dc',
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const handleClose = setOpen => (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
};

const cut_in_half = s => {
  let middle = Math.floor(s.length / 2);
  let before = s.lastIndexOf(' ', middle);
  let after = s.indexOf(' ', middle + 1);

  if (middle - before < after - middle)
    middle = before;
  else
    middle = after;

  const s1 = s.substr(0, middle);
  const s2 = s.substr(middle + 1);

  const lines = [];
  if (s1) lines.push(s1);
  if (s2) lines.push(s2);
  return lines
};

const split_line = (line, max_length) => {
  if (line.length > max_length) {
    const lines = cut_in_half(line);
    if (lines.length === 2) {
      lines.forEach((line, index) => {
        if (line.length > max_length)
          lines[index] = split_line(line, max_length)
      })
    }
    return lines
  }
  return line
};

function flatten(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}

const split_lines = (message, max_length) => {
  const words = message.split(' ');
  const lines = [[]];
  let line_index = 0;
  let line_length;
  let line = lines[line_index];

  if (message.length===0)
    return [];

  if (words.length < 2)
    return [message];

  words.forEach(word => {
    if (line.length === 0)
      line_length = word.length;
    else if (line_length+1+word.length <= max_length)
      line_length += 1 + word.length;
    else {
      lines.push([]);
      line_index++;
      line = lines[line_index];
      line_length = word.length;
    }
    line.push(word);
  });

  lines.forEach((line, index)=>{
    lines[index] = lines[index].join(' ')
  });
  return lines;
};

export const handle_long_message = (message, max_length) => {
  if (message.length > max_length) {
    const lines = split_lines(message, max_length);
    return (
      <div>
        {lines.map((line, index) =>
          <div key={index}>{line}</div>
        )}
      </div>
    )
  } else return message
};

export function SnackbarWrapper({message, setOpen, variant, ...props}) {
  const classes = useStyles();
  const { className, ...other } = props;
  const Icon = variantIcon[variant];
  const onClose = handleClose(setOpen);

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'default', 'primary']).isRequired,
};

export default function CustomSnackbar({
  message,
  variant,
  open,
  setOpen,
  autoHideDuration,
  anchorOrigin,
  ...props
}) {
  const onClose = handleClose(setOpen);

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      style={{zIndex: 8000}}
    >
      <SnackbarWrapper
        setOpen={setOpen}
        variant={variant}
        message={message}
        {...props}
      />
    </Snackbar>
  );
}