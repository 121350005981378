import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    width: '100%',
    bottom:'100%',
  },

  buttonProgress: {
    color: 'rgba(0,0,0,0.54)',
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
});

export default ({loading, size=24, children, ...props}) => {
  const [height, setHeight] = useState(size);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  });

  const classes = useStyles();
  return (
    <div ref={ref} {...props}>
      {children}
      {loading &&
        <div className={classes.wrapper}>
          <CircularProgress
            size={size}
            className={classes.buttonProgress}
            style={{marginTop: -(size/2+height/2), marginLeft: -size/2}}
          />
        </div>
      }
    </div>
  );
}