import React from 'react'
import {withStyles} from "@material-ui/core";
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'block'
  },
}))(MuiExpansionPanelDetails);

export default ({panel, details, open, onChange, ...props}) => {
  return (
    <div {...props}>
      <ExpansionPanel square expanded={open} onChange={onChange}>
        {panel && panel}
        <ExpansionPanelDetails>
          {details}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
