import React from 'react'


export default ({style={}, fill='black', ...props}) =>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
	 	width="612px"
		height="612px"
		viewBox="0 0 612 612"
		style={{...style, enableBackground:'new 0 0 612 612'}}
		xmlSpace="preserve"
		{...props}
	>
		<g>
			<path
				fill={fill}
				d="M541.322,500.219v-94.372c0-20.277-16.438-36.716-36.715-36.716h-9.598V24.598c0-3.082-1.547-5.958-4.117-7.657
				L467.587,1.537c-6.103-4.033-14.239,0.342-14.239,7.657v110.652l-6.945-18.734c-9.34-25.196-33.373-41.918-60.245-41.918H225.702
				c-27.03,0-51.169,16.916-60.394,42.323l-6.655,18.329V9.194c0-7.314-8.137-11.69-14.24-7.657L121.107,16.94
				c-2.571,1.699-4.118,4.575-4.118,7.657v344.534h-9.597c-20.277,0-36.715,16.438-36.715,36.716v94.372H55.035
				c-5.069,0-9.178,4.109-9.178,9.179v50.743c0,5.069,4.109,9.179,9.178,9.179h39.598v24.322c0,10.139,8.219,18.357,18.358,18.357
				h48.645c10.139,0,18.358-8.219,18.358-18.357V569.32h252.014v24.322c0,10.139,8.22,18.357,18.357,18.357h48.646
				c10.139,0,18.357-8.219,18.357-18.357V569.32h39.598c5.07,0,9.179-4.11,9.179-9.179v-50.742c0-5.07-4.109-9.179-9.179-9.179
				L541.322,500.219L541.322,500.219z M170.814,170.975h270.372v90.44H170.814V170.975z M164.527,474.533H133.17
				c-9.581,0-17.348-7.768-17.348-17.349v-0.438c0-9.581,7.767-17.348,17.348-17.348h31.356c9.581,0,17.348,7.767,17.348,17.348v0.438
				C181.875,466.765,174.108,474.533,164.527,474.533z M368.398,479.648H243.602c-10.139,0-18.358-8.22-18.358-18.357V344.976
				c0-10.138,8.219-18.357,18.358-18.357h124.796c10.138,0,18.357,8.22,18.357,18.357v116.314
				C386.756,471.428,378.536,479.648,368.398,479.648z M478.829,474.533h-31.356c-9.58,0-17.348-7.768-17.348-17.349v-0.438
				c0-9.581,7.768-17.348,17.348-17.348h31.356c9.581,0,17.349,7.767,17.349,17.348v0.438
				C496.178,466.765,488.41,474.533,478.829,474.533z M365.607,393.801H246.099c-5.019,0-9.087-4.068-9.087-9.088v-0.184
				c0-5.019,4.068-9.086,9.087-9.086h119.508c5.019,0,9.087,4.067,9.087,9.086v0.184C374.694,389.733,370.626,393.801,365.607,393.801
				z M365.607,357.085H246.099c-5.019,0-9.087-4.068-9.087-9.087v-0.184c0-5.018,4.068-9.086,9.087-9.086h119.508
				c5.019,0,9.087,4.068,9.087,9.086v0.184C374.694,353.017,370.626,357.085,365.607,357.085z M365.607,467.232H246.099
				c-5.019,0-9.087-4.068-9.087-9.087v-0.184c0-5.019,4.068-9.087,9.087-9.087h119.508c5.019,0,9.087,4.068,9.087,9.087v0.184
				C374.694,463.164,370.626,467.232,365.607,467.232z M365.607,430.516H246.099c-5.019,0-9.087-4.068-9.087-9.086v-0.184
				c0-5.019,4.068-9.087,9.087-9.087h119.508c5.019,0,9.087,4.068,9.087,9.087v0.184C374.694,426.448,370.626,430.516,365.607,430.516
				z"
			/>
		</g>
	</svg>
