export const fonts = {
  text_font: 'normal normal normal 18px/1.4em arial,\'ｍｓ ｐゴシック\',\'ms pgothic\',\'돋움\',dotum,helvetica,sans-serif',
  header2_font: 'normal normal bold 30px/1.4em \'josefin slab\',serif',
  learn_more_font: 'normal normal bold 23px/1.4em \'josefin slab\',serif',
  small_header: 'normal normal normal 70px/1.4em \'josefin slab\',serif',
  top_small_header: 'normal normal normal arial,ms pgothic,dotum,helvetica,sans-serif',
};

export const font_families = {
  header: 'lulo-clean-w01-one-bold,sans-serif',
  learn_more: 'opensanshebrewcondensed-regular,sans-serif',
  Paragraph: 'arial,ｍｓ ｐゴシック,ms pgothic,돋움,dotum,helvetica,sans-serif',
};

export const colors = {
  primary: '#333f50',
  secondary: '#f5323e',
  background: 'rgba(255, 255, 255, 0.1)',
  default: 'rgba(0, 0, 0, 0.54)',

  blue: '#03a9f4',
  rose: '#e91e63',
  red: '#fb6144',
  // background: '#fafafa',
  border: '#dbdbdb',
  transparent: 'transparent',
  text: '#999999',
  btn_red: 'rgb(207, 79, 79)',
  btn_red_focused: '#ff5959',
  btn_green: '#399791',
  btn_green_focused: '#4ac4bc',
  small_text: '#454545',  // rgb(69,69,69)
  header2_text: '#777777',
  post_card: '#888888',
  learn_more: '#555555',
  promote: "#51ce56",
  disabled: '#b1b1b1',
};

export const borders = {
  default: 'solid 1px rgba(255, 255, 255, 0.4)',
  invisible: '1px solid rgba(0, 0, 0, 0.0)',
  rose: '1px solid rgba(233,70,126,0.31)',
  red: `1px solid ${colors.btn_red}`,
  red_focused: `1px solid ${colors.btn_red_focused}`,
  green: `1px solid ${colors.btn_green}`,
  green_focused: `1px solid ${colors.btn_green_focused}`,

  style: 'solid',
  color: colors.border,
  width: '1px',
};

export default {
  colors,
  borders,
  fonts,
  font_families,
}
