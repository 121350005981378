import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({...props}) =>
  <SvgIcon
    {...props}
  >
    <path d={[
      "M21.71 5L19 2.29a1 1 0 00-.71-.29 1 1 0",
      "00-.7.29L4 15.85 2 22l6.15-2L21.71",
      "6.45a1 1 0 00.29-.74 1 1 0 00-.29-.71zM6.87",
      "18.64l-1.5-1.5L15.92 6.57l1.5 1.5zM18.09",
      "7.41l-1.5-1.5 1.67-1.67 1.5 1.5z",
    ].join(' ')}/>
  </SvgIcon>