export const Access_Expiration = 'access-expiration';
export const Refresh_Expiration = 'refresh-expiration';
export const X_Access_Token = 'x-access-token';
export const X_Refresh_Token = 'x-refresh-token';
export const X_CSRF_TOKEN = 'x-csrf-token';
export const Identity_ID = 'identity-id';
export const Refresh_ID = 'refresh-id';

export const base_headers = {
  'Content-Type': 'application/json',
};

export const multipart_form_data = {
  'Accept': 'application/json',
  'Content-Type': 'multipart/form-data',
};

export const auth_headers = {
  ...base_headers,
  [X_Access_Token]: null,
  [Identity_ID]: null
};

export const refresh_headers = {
  ...base_headers,
  [X_Access_Token]: null,
  [Identity_ID]: null
};