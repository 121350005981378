import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import Fab from '@material-ui/core/Fab';
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

export default function PrimarySearchAppBar({handleOpenDrawer}) {
  const mobile: boolean = useMediaQuery(useTheme().breakpoints.down('xs'));

  return (
    <Fab
      color="inherit"
      variant={mobile?"round":"extended"}
      size={mobile?"small":"medium"}
      style={{marginLeft:mobile?0:10, backgroundColor:'rgba(255,255,255,0.1)'}}
      onClick={handleOpenDrawer}
    >
      <SearchIcon/>
      {!mobile &&
      <div style={{paddingLeft:5, fontSize:14, fontWeight:100, textTransform:'none'}}>
        <div>Search…</div>
      </div>
      }
    </Fab>
  );
}