import React, {useEffect, useState} from 'react'
import {signinStyles} from '../../../assets/jss/signin'

export default ({
  label,
  value,
  onChange,
  helper,
  validator,
  ...rest
}) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error===null)
      setError('');
    else if (validator) {
      try {
        validator(value);
        setError('')
      } catch (e) {
        if (e.message.length > 30)
          setError(e.message.substr(0, 27)+'...');
        else
          setError(e.message);
      }
    }
  }, [value, validator]);

  const classes = signinStyles();

  return (
    <div className={classes.inputBox} {...rest}>
      <input
        // autoComplete="off"
        type="text"
        name={label.split(' ').join('-')}
        required=""
        className={error?classes.invalidCmpInput:classes.inputBoxInput}
        value={value}
        onChange={onChange}
      />
      <label className={error?classes.invalidCmpLabel:classes.inputBoxLabel}>{error?error:label}</label>
      {helper && helper}
    </div>
  );
};