import {Values} from './interface'

export const validate = values => {
  const errors: Partial<Values> = {};
  const licenses: Object = values.licenses;

  if (Object.keys(licenses).length === 0)
    errors.licenses = 'Please Add at least 1 driving license you want to provide';

  return errors;
};
