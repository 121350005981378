import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import state from '../../../state'
import SignIN from './tools/SignIn'
import ids from '../../../ids'
import Notification from "./tools/Notification";
import {AppBarAvatar} from "../../icons/Avatars";
import Add from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default ({handleMobileMenuOpen}) => {
  const links = useContext(state.links);
  const classes = useStyles();
  const {user} = useContext(state.user);
  return (
    <div className={classes.sectionMobile}>
      {user &&
      <IconButton
        aria-label="New Post"
        color="inherit"
        onClick={links.go_to_upload_post}
      >
        <Add/>
      </IconButton>
      }
      {user && <Notification/>}
      {user
        ?
        <IconButton
          aria-label="account of current user"
          aria-controls={ids.account_menu_mobile}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
          style={{width:48,height:48, padding: 0}}
        >
          <AppBarAvatar gravatar_link={user.image_url}/>
        </IconButton>
        :
        <SignIN/>
      }
    </div>
  );
}