import React, {useEffect, useState} from 'react'
import {signinStyles} from '../../../assets/jss/signin'
import Hide from '../CustomExpansion/Hide'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


export default ({
  label,
  cmpValue,
  setValid,
  isValid,
  expansionPanelStyle,
  ...rest
}) => {
  const [showPass, setShowPass] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (value === cmpValue)
      setValid(true);
    else
      setValid(false);
  }, [value, cmpValue, setValid]);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const classes = signinStyles();
  return (
    <Hide open={!isValid} expansionPanelStyle={expansionPanelStyle}>
      <div className={classes.inputBox} {...rest}>
        <input
          autoComplete="off"
          type={showPass ? 'text' : 'password'}
          name={label.split(' ').join('-')}
          required=""
          className={(cmpValue === value ? classes.validCmpInput : classes.invalidCmpInput)+" "+classes.inputPassword}
          value={value}
          onChange={event=>setValue(event.target.value)}
        />
        <InputAdornment
          position="end"
          className={classes.InputAdornment}
        >
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPass ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
        <label className={cmpValue === value ? classes.validCmpLabel : classes.invalidCmpLabel}>
          {label}
        </label>
      </div>
    </Hide>
  );
};