import React, {useContext, useState} from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import SideBar from './SideBar'
import Logo from '../Logo'
import Hamburger from './appbar_menu/tools/Hamburger'
import AccountMenu from './appbar_menu/tools/AccountMenu'
import AccountMobileMenu from './appbar_menu/tools/AccountMobileMenu'
import DesktopToolsSection from "./appbar_menu/DesktopToolsSection";
import MobileToolsSection from "./appbar_menu/MobileToolsSection";
import state from '../../state'
import type {User} from "../../types";
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import appbarStyles from '../../assets/jss/appbarStyles'
import {ShareButtons} from '../icons/Share'
import {small_mobile_size, mobile_size, access_levels, search_categories} from '../../variables'
import Grow from '@material-ui/core/Grow'
import Button from '@material-ui/core/Button'
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import AppBarSearch from '../search/AppBarSearch'

function HideOnScroll(props) {
  const { share_buttons } = useContext(state.share_buttons);
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger || share_buttons}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const PageBtn = ({classes, label, onClick}) =>
  <Button
    className={classes.site_page}
    onClick={onClick}
    tabIndex="0"
    type="button"
    role="tab"
    aria-selected="false"
    aria-controls="tab"
  >
    {label}
  </Button>;

const Edge = () => {
  const width: number = useContext(state.size).size.width;
  return <div style={{width: width > 1200 ? (width-1200)/2 : 0}}/>;
};

export default () => {
  const links = useContext(state.links);
  const classes = appbarStyles();
  const user: User = useContext(state.user).user;
  const width: number = useContext(state.size).size.width;
  const mobile: boolean = useMediaQuery(useTheme().breakpoints.down('xs'));
  const {signin, set_signin} = useContext(state.signin);

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  return (
    <Grow in={true} timeout={1700}>
      <div className={classes.grow}>
        <HideOnScroll>
          <AppBar
            // position="static"
            className={classes.app_bar }
          >
            <Toolbar>
              <Edge/>

              <Hamburger/>
              <Logo style={width>small_mobile_size?{}:{fontSize:16}}/>

              <AppBarSearch/>

              {width > mobile_size && <PageBtn label='Carrier' classes={classes} onClick={links.go_to_members}/>}
              {width > mobile_size && <PageBtn label='Shipper' classes={classes} onClick={links.go_to_promote}/>}
              {width > mobile_size && user && <PageBtn label='Products' classes={classes} onClick={links.go_to_my_following}/>}
              {width > mobile_size && user && user.access_level === access_levels.admin && <PageBtn label='Admin' classes={classes} onClick={links.go_to_admin}/>}

              <div className={classes.grow}/>
              <DesktopToolsSection handleProfileMenuOpen={handleProfileMenuOpen}/>
              <MobileToolsSection handleMobileMenuOpen={handleMobileMenuOpen}/>

              <Edge/>
            </Toolbar>

            <ShareButtons/>
          </AppBar>
        </HideOnScroll>
        <AccountMobileMenu
          mobileMoreAnchorEl={mobileMoreAnchorEl}
          isMobileMenuOpen={isMobileMenuOpen}
          handleMenuClose={handleMobileMenuClose}
          handleProfileMenuOpen={handleProfileMenuOpen}
        />
        <AccountMenu
          isMenuOpen={isMenuOpen}
          anchorEl={anchorEl}
          handleMenuClose={handleMenuClose}
        />
        <SideBar/>
      </div>
    </Grow>
  );
}