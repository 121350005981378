import React from "react";
import { makeStyles } from '@material-ui/core/styles'


export const layoutStyles = makeStyles({
  page: {
    minHeight:'100vh',
    display: 'flex',
    flexDirection:'column',
    width: '100vw',
  },
});

