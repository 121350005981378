import React, {useState, useContext} from 'react'
import forgotPasswordsStyles from '../../assets/jss/forgotPasswordsStyles'
import state from "../../state";
import {register} from "../../api/public/register";
import BaseInput from '../custom/CustomInput/BaseInput'
import CmpInput from '../custom/CustomInput/CmpInput'
import PasswordInput from '../custom/CustomInput/PasswordInput'
import CmpPassword from '../custom/CustomInput/CmpPassword'
import Submit from '../custom/CustomButtons/Submit'
import {
  set_error,
  email_validation,
  captcha_validation,
  password_validation,
  confirm_email_validation,
  confirm_password_validation,
} from "../../validations/signin_validations";
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ReCAPTCHA from "react-google-recaptcha"
import {captcha_key} from '../../variables'
import '../../assets/scss/recaptcha.scss'


const NewsCheckbox = withStyles({
  root: {
    color: '#ff99cd',
    '&$checked': {
      color: '#f390c3',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export default ({
  email,
  password,
  onEmailChange,
  onPasswordChange,
  }) => {
  const set_snackbar = useContext(state.snackbar).set_snackbar;
  const signin = useContext(state.signin).signin;
  const set_signin = useContext(state.signin).set_signin;

  const [loading, setLoading] = useState(false);
  const [email_confirmed, set_email_confirmed] = useState(false);
  const [password_confirmed, set_password_confirmed] = useState(false);
  const [newsLetter, setNewsLetter] = useState(true);
  const [captcha, setCaptcha] = useState('');

  const reset_captcha = () => {
    setCaptcha('');
    window.grecaptcha.reset();
  };

  const onCaptcha = (value) => {
    setCaptcha(value);
  };

  const handleNewsLetter = event => {
    setNewsLetter(event.target.checked);
  };

  const onSubmit = async () => {
    if (loading)
      return;

    try {
      email_validation(email);
      confirm_email_validation(email_confirmed);
      password_validation(password);
      confirm_password_validation(password_confirmed);
      captcha_validation(captcha);
    } catch (e) {
      return set_snackbar(set_error(e.message))
    }

    setLoading(true);

    if (await register(email, password, newsLetter, captcha)) {
      set_signin({...signin, open: false});
    }
    reset_captcha();
    setLoading(false);
  };

  const forgotPasswordsStyle = forgotPasswordsStyles();

  return (
    <div style={{marginTop:10}}>
      <BaseInput
        label='email'
        value={email}
        onChange={onEmailChange}
        validator={email_validation}
      />
      <CmpInput
        label='confirm email'
        isValid={email_confirmed}
        setValid={set_email_confirmed}
        cmpValue={email}
      />

      <PasswordInput
        label='password'
        value={password}
        onChange={onPasswordChange}
        validator={password_validation}
      />
      <CmpPassword
        label='confirm password'
        cmpValue={password}
        isValid={password_confirmed}
        setValid={set_password_confirmed}
      />

      <div style={{marginTop:5}}/>
      <ReCAPTCHA
        sitekey={captcha_key}
        onChange={onCaptcha}
      />
      <FormControlLabel
        control={
          <NewsCheckbox
            checked={newsLetter}
            onChange={handleNewsLetter}
            value="checkedG"
          />
        }
        label={<span style={{color: '#999'}}>I'd like to receive news letters</span>}
      />
      <p className={forgotPasswordsStyle.agree}>
        By signing up, you agree to our <a target="_blank" href="/terms">Terms</a>
      </p>
      <Submit
        onSubmit={onSubmit}
        text={"Sign Up"}
        loading={loading}
      />
    </div>
  );
}
