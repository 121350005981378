import Arrow from "./arrow/Arrow";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Button from '@material-ui/core/Button';
import Tooltip from './Tooltip';


export default ({tip, tipProps={}, btnProps={}, arrow=true, arrowProps={}, ...props}) => {
  return (
    <Grid container justify='center' {...props}>
      <div style={{display: 'flex'}}>
        {arrow &&
        <div style={{position:'relative'}}>
          <Arrow style={{color: '#777777', fontSize:18, position:'absolute', left:-30}} {...arrowProps}/>
        </div>
        }
        <Tooltip title={tip} {...tipProps}>
          <Button color="inherit" style={{color: '#777777'}} {...btnProps}>
            Learn More
          </Button>
        </Tooltip>
      </div>
    </Grid>
  )
}