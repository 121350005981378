import React from "react";

export default {
  home: '/',
  search_transport: "/search_transport/:id",
  search_transport_results: "/search_transport/:id/results",
  // signup: "/signup",

  my_account: '/my_account',
  my_account_setup: '/my_account/setup',
  my_account_edit: '/my_account/edit',
  my_assets: '/my_assets',

  profile: '/user/:username',

  new_orders: '/orders/new',

  about: '/about',
  team: '/our_team',
  investors_relations: '/investors_relations',
  press: '/press',
  privacy: '/privacy',
  trust_and_safety: '/trust_and_safety',
  terms: '/terms',
  help: '/help',
  contact: '/contact',

  services_driver_without_vehicle: '/services/driver_without_vehicle',
  services_driver_with_vehicle: '/services/driver_with_vehicle',
  services_vehicle_rent_and_share: '/services/vehicle_rent_and_share',
  services_collaboration: '/services/collaboration',

  gotrackers_carriers: '/gotrackers/carriers',
  gotrackers_driver_without_vehicle: '/gotrackers/driver_without_vehicle',
  gotrackers_driver_with_vehicle: '/gotrackers/driver_with_vehicle',
  gotrackers_vehicle_owner: '/gotrackers/vehicle_owner',
  gotrackers_list_yourself: '/gotrackers/list_yourself',
  gotrackers_how_it_works: '/gotrackers/how_it_works',
  gotrackers_questions: '/gotrackers/questions',
  gotrackers_how_to_earn_money: '/gotrackers/how_to_earn_money',
  gotrackers_insurance_and_protection: '/gotrackers/insurance_and_protection',
  gotrackers_gold_Tracker_benefits: '/gotrackers/gold_tracker_benefits',
  gotrackers_career_and_placement: '/gotrackers/career_and_placement',

  shippers_how_it_works: '/shippers/how_it_works',
  shippers_questions: '/shippers/questions',
  shippers_insurance_and_protection: '/shippers/insurance_and_protection',

  validate_new_password: '/validate/new_password/:email/:token',
  validate_register: '/validate/register/:email/:token',
  redirect_oauth: '/auth/redirect/login/',
  admin: '/admin',
}