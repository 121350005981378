import {useContext} from 'react'
import {refresh} from '../public/refresh'
import {auth_error} from './auth_handler'
import {generic_error} from "./errors";
import {error_logout} from "../public/logout";
import state from '../../state'
import {do_nothing_async} from '../../utils/do_nothing_function'

export const auth_call = call => {
  return async props => {
    const { set_user } = props;
    try {
      return await call(props);
    } catch (e) {
      if (auth_error(e)) {
        if (await refresh()) {
          try {
            return await call(props);
          } catch (e) {
            if (auth_error(e))
              error_logout(set_user);
            return generic_error(e);
          }
        }
        else
          error_logout(set_user);
      }
      return generic_error(e);
    }
  }
};

export const silent_auth_call = call => {
  return async props => {
    const { set_user } = props;
    try {
      return await call(props);
    } catch (e) {
      console.log(e);
      if (auth_error(e)) {
        if (await refresh()) {
          try {
            return await call(props);
          } catch (e) {
            console.log(e);
            if (auth_error(e))
              error_logout(set_user);
          }
        }
        else
          error_logout(set_user);
      }
      return false;
    }
  }
};

export const useAuthCall = call => {
  const { user, set_user } = useContext(state.user);

  if (user===null)
    return do_nothing_async;

  return async props => {
    try {
      return await call(props);
    } catch (e) {
      if (auth_error(e)) {
        if (await refresh()) {
          try {
            return await call(props);
          } catch (e) {
            if (auth_error(e))
              error_logout(set_user);
            return generic_error(e);
          }
        }
        else
          error_logout(set_user);
      }
      return generic_error(e);
    }
  }
};

export const useSilentAuthCall = call => {
  const { user, set_user } = useContext(state.user);

  if (user===null)
    return do_nothing_async;

  return async props => {
    try {
      return await call(props);
    } catch (e) {
      console.log(e);
      if (auth_error(e)) {
        if (await refresh()) {
          try {
            return await call(props);
          } catch (e) {
            console.log(e);
            if (auth_error(e))
              error_logout(set_user);
          }
        }
        else
          error_logout(set_user);
      }
      return false;
    }
  }
};