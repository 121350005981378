import React, {useContext} from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Add from '@material-ui/icons/Add';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import logo from '../../assets/images/truck.svg';
import Tooltip from '@material-ui/core/Tooltip';
import Search from "./Search";
import { Link } from 'react-router-dom';
import route from '../../routes';
import Arrow from '../arrow/Arrow';
import state from '../../state'
import Button from '@material-ui/core/Button';
import SignIn from '../navbars/appbar_menu/tools/SignIn'
import Logo from '../Logo'
import {useHistory} from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
        // position: 'absolute',
        // width: '100%'
        height:'64px',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default ({sell_pointer, ...other}) => {
    const history = useHistory();
    const classes = useStyles();
    const width: number = useContext(state.size).size.width;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = event => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <Tooltip title="Post available transport service">
                    <div>
                        <IconButton aria-label="post available transport" color="inherit">
                            <Add/>
                        </IconButton>
                        <div style={{position:'relative'}}>
                            <Link to={route.signup}>
                                <div style={{width: '36px', height:'36px', position:'absolute', top:'-42px', right:'6px'}}/>
                            </Link>
                        </div>
                    </div>
                </Tooltip>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.grow} {...other}>
            <AppBar position="absolute">
                <Toolbar>
                    <div style={{width: width > 1200 ? (width-1200)/2 : 0}}/>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                    >
                        <MenuIcon onClick={()=>{history.push(route.home)}}/>
                    </IconButton>
                    <Logo/>
                    <Search/>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <Tooltip title="POST NEW Transport Service">
                            <div>
                                <IconButton aria-label="post available transport" color="inherit">
                                    <Add/>
                                </IconButton>
                                <div style={{position:'relative'}}>
                                    <Link to="/signup">
                                        <div style={{width: '36px', height:'36px', position:'absolute', top:'-42px', right:'6px'}}/>
                                    </Link>
                                </div>
                                {sell_pointer
                                  ?<div style={{position:'relative'}}>
                                    <Arrow style={{position:'absolute', fontSize:12, width: 150, top: '-40px', left:'-170px', color:'white', cursor:'text'}}>
                                      sell transport services
                                    </Arrow>
                                  </div>
                                  :null
                                }
                            </div>
                        </Tooltip>
                        {/*<IconButton aria-label="show 4 new mails" color="inherit">*/}
                        {/*    <Badge badgeContent={4} color="secondary">*/}
                        {/*        <MailIcon />*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/}
                        {/*<IconButton aria-label="show 17 new notifications" color="inherit">*/}
                        {/*    <Badge badgeContent={17} color="secondary">*/}
                        {/*        <NotificationsIcon />*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/}
                        {/*<IconButton*/}
                        {/*    edge="end"*/}
                        {/*    aria-label="account of current user"*/}
                        {/*    aria-controls={menuId}*/}
                        {/*    aria-haspopup="true"*/}
                        {/*    onClick={handleProfileMenuOpen}*/}
                        {/*    color="inherit"*/}
                        {/*>*/}
                        {/*    <AccountCircle />*/}
                        {/*</IconButton>*/}
                        <SignIn/>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                    <div style={{width: width > 1200 ? (width-1200)/2 : 0}}/>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
}