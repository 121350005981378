import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  reSend: {
    color: '#7da5cc',
    cursor: 'pointer',
    '&:hover': {
      color: '#03a9f4',
    },
  },
  tokenExpired: {
    color: '#888888',
  },
  counterText: {
    color: '#777777',
  },
  counter: {
    color: '#03a9f4',
  },
  goStep2: {
    border: '1px solid rgba(0, 0, 0, 0.46)',
    padding: 2,
    fontSize: 14,
    borderLeft: 'none',
    cursor: 'pointer',
    transition: '.4s',
    '&:hover, &:focus': {
      backgroundColor: '#000',
      color: '#fff'
    }
  },
  tokenInput: {
    width:'100%',
    border: '1px solid rgba(0, 0, 0, 0.46)',
    padding: 2,
    fontSize: 14,
  },
  agree: {
    webkitBoxDirection: 'normal',
    padding: 0,
    border: 0,
    font: 'inherit',
    verticalAlign: 'baseline',
    fontSize: 14,
    lineHeight: '18px',
    color: '#999',
    margin: 0,
    marginBottom: 5,
  },
});