import React from 'react'
import Grid from '@material-ui/core/Grid'

export default ({style={}, ...props}) => {
  return (
    <Grid container justify='center'>
      <div
        style={{width:'100%', maxWidth:1200, padding:10, ...style}}
        {...props}
      />
    </Grid>
  )
}